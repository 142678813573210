import { CheckboxField } from '../components/CheckboxField'
import { CitySelectField } from '../components/CitySelectField'
import { ConditionalGroupField } from '../components/ConditionalGroupField'
import { CountrySelectField } from '../components/CountrySelectField'
import { DateField } from '../components/DateField'
import { DoctorSelectField } from '../components/DoctorSelectField'
import { GroupField } from '../components/GroupField'
import { PhoneField } from '../components/PhoneField'
import { SelectField } from '../components/SelectField'
import { SnilsField } from '../components/SnilsField'
import { TextField } from '../components/TextField'
import { TimeField } from '../components/TimeField'
import { IFormField } from '../types'
import { ReactNode } from 'react'

export function renderField<T extends IFormField>(field: T): ReactNode {
  switch (field.__type) {
    case 'TextField':
      return <TextField key={field.name} {...field} />
    case 'DateField':
      return <DateField key={field.name} {...field} />
    case 'TimeField':
      return <TimeField key={field.name} {...field} />
    case 'SelectField':
      return <SelectField key={field.name} {...field} />
    case 'DoctorSelectField':
      return <DoctorSelectField key={field.name} {...field} />
    case 'PhoneField':
      return <PhoneField key={field.name} {...field} />
    case 'SnilsField':
      return <SnilsField key={field.name} {...field} />
    case 'GroupField':
      return (
        <GroupField key={field.name} {...field} renderField={renderField} />
      )
    case 'ConditionalGroupField':
      return (
        <ConditionalGroupField
          key={field.name}
          {...field}
          renderField={renderField}
        />
      )
    case 'CitySelectField':
      return <CitySelectField key={field.name} {...field} />
    case 'CountrySelectField':
      return <CountrySelectField key={field.name} {...field} />
    case 'CheckboxField':
      return <CheckboxField key={field.name} {...field} />
    default:
      return null
  }
}
