import React from 'react'
import { Box, Typography } from '@mui/material'
import { LoginForm } from './LoginForm'

export const LoginFormSection: React.FC = () => {
    return (
        <Box
            className="login__paper--section login__paper--form"
            sx={{ height: '100%' }}
        >
            <Typography
                className="login__paper--form--title"
                variant="h2"
                sx={{
                    fontFamily: 'Montserrat',
                    fontWeight: '700',
                    fontSize: '64px',
                }}
            >
                Экомед
            </Typography>
            <Box className="login__paper--form-section">
                <LoginForm />
            </Box>
        </Box>
    )
}
