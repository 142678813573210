import { useGetEmployeesQuery } from '../../redux/services'
import { SelectResponse } from '../../redux/types'
import { EcoSelect } from '../ui'
import { Box, Typography } from '@mui/material'
import { Radio, RadioChangeEvent } from 'antd'
import { getEmployeeFullName } from 'lib/getEmployeeFullName'
import React from 'react'

type Props = {
  setFilters: React.Dispatch<
    React.SetStateAction<{ assignedTo: string; type: string }>
  >
  filters: { assignedTo: string; type: string }
}

export const CalenderFilter: React.FC<Props> = (props) => {
  const { setFilters, filters } = props
  const { data: employeesData } = useGetEmployeesQuery()

  const handleChange = (e: RadioChangeEvent) => {
    setFilters({ ...filters, type: e.target.value })
  }

  return (
    <Box className="calender-filters">
      <Box className={'calender-filters__header'}>
        <Typography variant="h6">
          Календарь фильтров (пользователь, тип события)
        </Typography>
      </Box>
      <Box className="calender-filters__params">
        <Box className="users">
          <Typography variant={'h6'} className="label">
            Пользователь:
          </Typography>
          <EcoSelect
            error={false}
            key="userEvent"
            freeSolo={true}
            onChange={(event, newValue) => {
              setFilters({
                ...filters,
                assignedTo: newValue?.value || '',
              })
            }}
            name="userEvent"
            options={
              employeesData
                ? (employeesData.map((employee) => ({
                    _id: employee?._id || '',
                    label: getEmployeeFullName(employee),
                    value: employee?._id || '',
                  })) as SelectResponse[])
                : []
            }
            sx={{ width: '100%' }}
            placeholder="Выберите пользователя"
          />
        </Box>
        <Box className="event-type">
          <Typography className="label" variant={'h6'}>
            Тип события:
          </Typography>
          <Radio.Group
            onChange={handleChange}
            defaultValue="appointment"
            buttonStyle="solid"
            size="large"
          >
            <Radio.Button value="appointment">прием</Radio.Button>
            <Radio.Button value="procedure">процедура</Radio.Button>
          </Radio.Group>
        </Box>
      </Box>
    </Box>
  )
}
