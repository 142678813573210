import { setDocumentContext } from '../../lib/context'
import { IFormStep } from '../../types'
import { DocumentForm } from '../DocumentForm'
import styles from './styles.module.scss'
import { Box, Button, styled, Typography } from '@mui/material'
import clsx from 'clsx'
import { CustomButton } from 'components'
import merge from 'lodash/merge'
import React, { FC, useMemo } from 'react'
import { FieldValues, SubmitHandler } from 'react-hook-form'

interface DocumentFormWithStepsProps<T extends FieldValues = any> {
  data?: any
  onSubmit: SubmitHandler<T>
  steps: IFormStep[]
  title?: string
  ctx?: any
}

export const DocumentFormWithSteps: FC<DocumentFormWithStepsProps> = ({
  data,
  onSubmit,
  steps,
  title,
  ctx,
}) => {
  const defaultValues = useMemo(() => {
    const values = merge({}, data)
    setDocumentContext(values, ctx)

    return values
  }, [data, ctx])

  console.log('defaultValues', defaultValues, ctx, data)

  const [formData, setFormData] = React.useState<any>(defaultValues)
  const [currentStep, setCurrentStep] = React.useState(0)

  const step = steps[currentStep]

  const handleBack = () => {
    if (currentStep) setCurrentStep(currentStep - 1)
  }

  const handleSubmit: SubmitHandler<any> = (data: any) => {
    const newData = merge({}, formData, data)
    setFormData(newData)

    if (currentStep !== steps.length - 1) {
      setCurrentStep((prevStep) => prevStep + 1)
    } else {
      onSubmit(newData)
    }
  }

  const stepDefaultValues = step.ns ? formData[step.ns] : formData

  return (
    <Box className={clsx(styles.container)}>
      {title && (
        <Box className={clsx(styles['page-title'])}>
          <Typography variant="h6">{title}</Typography>
        </Box>
      )}
      {step.title && (
        <Box className={clsx(styles['step-title'])}>
          <Typography variant="h6">{step.title}</Typography>
        </Box>
      )}
      <DocumentForm
        key={currentStep}
        onSubmit={handleSubmit}
        validationSchema={step.validationSchema}
        ns={step.ns}
        fields={step.fields}
        defaultValues={stepDefaultValues}
      >
        <Box className={styles['actions']}>
          {/* <StyledCustomButton
                        fullWidth={true}
                        type={'submit'}
                        variant="outlined"
                        className="actions__button"
                        onClick={() => {
                            console.log('values', getValues())
                        }}
                    >
                        Регистрация
                    </StyledCustomButton>
                    <button onClick={onNext}>Следующий шаг</button> */}
          <Box className={styles['notfound']}>
            <Box component="div">
              <CustomButton
                type={'button'}
                className={styles['actions__button']}
                onClick={handleBack}
                disabled={!currentStep}
              >
                Предыдущий шаг
              </CustomButton>
            </Box>
            <Box component="div">
              <CustomButton
                type={'submit'}
                className={styles['actions__button']}
              >
                {currentStep === steps.length - 1
                  ? 'Завершить'
                  : 'Следующий шаг'}
              </CustomButton>
            </Box>
          </Box>
        </Box>
      </DocumentForm>
    </Box>
  )
}
