import { SelectResponse } from '../types'
import { baseApi } from './api'

export const rolesApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getRoles: build.query<SelectResponse[], void>({
      query: () => ({ url: 'roles' }),
    }),
  }),
})

export const { useGetRolesQuery } = rolesApi
