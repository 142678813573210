import React from 'react'
import { Alert, Portal, Snackbar } from '@mui/material'

type Props = {
    errorMessage: string
    open: boolean
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
    severity: 'error' | 'success'
    handleAfterClose?: () => void
}

export const AlertDialog: React.FC<Props> = (props) => {
    const { errorMessage, setOpen, open, severity, handleAfterClose } = props

    const handleClose = (
        event?: React.SyntheticEvent | Event,
        reason?: string
    ) => {
        if (reason === 'clickaway') {
            return
        }
        setOpen(false)
        handleAfterClose && severity === 'success' && handleAfterClose()
    }

    return (
        <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
            <Alert
                onClose={handleClose}
                severity={severity}
                sx={{ width: '100%' }}
            >
                {errorMessage}
            </Alert>
        </Snackbar>
    )
}
