import { Session } from '../context/session.context'

export function createValues(session: Session): void {
    const { userType, token, userId } = session
    localStorage.setItem('userId', userId)
    localStorage.setItem('token', token)
    localStorage.setItem('userType', userType)
}

export function retrieveValues(): Session {
    const userType = localStorage.getItem('userType') || ''
    const token = localStorage.getItem('token') || ''
    const userId = localStorage.getItem('userId') || ''

    return {
        isAuthenticated: Boolean(token),
        userType,
        token,
        userId,
    }
}

export function deleteValues(): void {
    localStorage.removeItem('token')
    localStorage.removeItem('userType')
    localStorage.removeItem('userId')
}
