import { api } from '../../api'
import { BackButton, EcoSelect, ECOTable } from '../../components'
import { useDebounce, useDownload } from '../../hooks'
import {
  useSearchPatientsQuery,
  useGetDocumentsQuery,
  useDeleteDocumentMutation,
} from '../../redux/services'
import { SelectResponse } from '../../redux/types'
import deleteIcon from '../../svgs/deleteIcon.svg'
import downloadIcon from '../../svgs/downloadIcon.svg'
import editIcon from '../../svgs/editIcon.svg'
import './documents.styles.scss'
import { Box, Typography } from '@mui/material'
import { Space } from 'antd'
import { ColumnsType } from 'antd/es/table'
import dayjs from 'dayjs'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

interface DataType {
  _id: string
  docName: string
  fileName: string
  createDate: string
  createdBy: string
  actions: string
  url: string
}

// const data: DataType[] = [
//     {
//         fileName: 'Сведение о пациенте',
//         createDate: '09.06.2023',
//         _id: '64a68b37fd625c8dfe47ba0b',
//         createdBy: 'Иванов Иван Иванович',
//         actions: '',
//         docName: 'patientInformation',
//     },
//     {
//         fileName:
//             'Согласие пациента на обработку персональных данных в ЛОК на Северной, 66',
//         createDate: '09.06.2023',
//         _id: '64a68b37fd625c8dfe47ba0b',
//         createdBy: 'Иванов Иван Иванович',
//         actions: '',
//         docName: 'personalDataConsent',
//     },
//     {
//         fileName:
//             'Информированное добровольное согласие на медицинское вмешательство',
//         createDate: '09.06.2023',
//         _id: '64a68b37fd625c8dfe47ba0b',
//         createdBy: 'Иванов Иван Иванович',
//         actions: '',
//         docName: 'patientMedicalConsent',
//     },
// ]

const columns: ColumnsType<DataType> = [
  {
    title: 'Наименование файлов',
    dataIndex: 'title',
    render: (text: string) => <a>{text}</a>,
  },
  {
    title: 'Дата создания',
    dataIndex: 'createdAt',
  },

  {
    title: 'Кто создал',
    dataIndex: 'creator[0].firstName',
  },

  {
    title: 'Функционал',
    dataIndex: 'actions',
    render: (_, record) => (
      <Space>
        <Box className="action">
          <img src={downloadIcon} alt="deleteIcon" />
        </Box>
        <Box className="action">
          <img src={editIcon} alt="deleteIcon" />
        </Box>
        <Box className="action">
          <img src={deleteIcon} alt="deleteIcon" />
        </Box>
      </Space>
    ),
  },
]

export const DocumentsPage: React.FC = () => {
  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([])
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(20)

  const navigate = useNavigate()

  const [searchText, setSearchText] = React.useState('')
  const [patientId, setPatientId] = React.useState('')
  const debounced = useDebounce(searchText)
  const [error, setError] = React.useState(false)

  const { data: patientsData } = useSearchPatientsQuery(debounced, {
    skip: !debounced,
  })

  //todo: заменить нормальными значениями
  const getDocumentsQuery = useGetDocumentsQuery({
    page,
    limit: pageSize,
    patientId,
  })

  const [deleteDocument] = useDeleteDocumentMutation()

  const documents = getDocumentsQuery.data?.documents || []
  const total = getDocumentsQuery.data?.total || 0

  const { downloadFile } = useDownload()

  const handleDownloadFile = async (fileUrl: string, fileName: string) => {
    // if (!patientId) {
    //     setError(true)
    //     return
    // }
    await downloadFile(fileUrl, fileName)
  }

  const handleDeleteFile = async (fileName: string) => {
    await deleteDocument(fileName).unwrap()
  }

  const handleEditDocument = (event: React.MouseEvent<HTMLImageElement>) => {
    const documentId = event.currentTarget.dataset.id

    if (documentId) navigate(`/documents/${documentId}`)
  }

  const columns: ColumnsType<DataType> = [
    {
      title: 'Наименование файлов',
      dataIndex: 'title',
      render: (text: string) => <a>{text}</a>,
    },
    {
      title: 'Дата создания',
      dataIndex: 'createdAt',
      render: (value) => {
        return dayjs(new Date(value)).format('DD.MM.YYYY')
      },
    },

    {
      title: 'Кто создал',
      dataIndex: 'creator',
      render: (text) => (
        <span>{`${text.firstName} ${text.secondName} ${text.surName}`}</span>
      ),
    },

    {
      title: 'Функционал',
      dataIndex: 'actions',
      render: (_, record) => (
        <>
          <Space>
            <Box className="action">
              <img
                onClick={() =>
                  handleDownloadFile(
                    record.url,
                    // @ts-ignore
                    `${record.title} (${record.patient.lastName} ${record.patient.firstName} ${record.creator.surName})`
                  )
                }
                src={downloadIcon}
                alt="downloadIcon"
              />
            </Box>
            <Box className="action">
              <img
                src={editIcon}
                alt="editIcon"
                data-id={record._id}
                onClick={handleEditDocument}
              />
            </Box>
            <Box className="action">
              <img
                onClick={() => {
                  console.log(record)
                  handleDeleteFile(record._id)
                }}
                src={deleteIcon}
                alt="deleteIcon"
              />
            </Box>
          </Space>
        </>
      ),
    },
  ]

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        'selectedRows: ',
        selectedRows
      )
    },
  }

  return (
    <Box className="content">
      <BackButton />
      <Box className="documents">
        <Box className="documents__container">
          <Typography variant="h6" sx={{ textTransform: 'uppercase' }}>
            Общий список документов
          </Typography>

          <Box className="table" sx={{ width: '90%' }}>
            <Box
              sx={{
                margin: '12px',
                width: '100%',
              }}
            >
              <EcoSelect
                freeSolo={true}
                filterOptions={(x) => x}
                helperText={error && !patientId ? 'выбери пациента' : undefined}
                name="patient"
                placeholder="Номер карточки пациента"
                options={
                  patientsData
                    ? (patientsData.map((patient) => ({
                        _id: patient._id,
                        label: `${patient.fio} - ${patient.cardNumber}`,
                        value: patient._id,
                      })) as SelectResponse[])
                    : []
                }
                onChange={(event, newInputValue) => {
                  setPatientId((newInputValue as SelectResponse)?._id || '')
                }}
                error={error && !patientId}
                onInputChange={(event, newInputValue) => {
                  setSearchText(newInputValue)
                }}
                sx={{ maxWidth: '450px' }}
              />
            </Box>
            <ECOTable
              pagination={{
                total,
                pageSize,
                onChange: (page, pageSize) => {
                  setPage(page)
                  setPageSize(pageSize)
                },
              }}
              data={[...documents].map((doc, index) => ({
                ...doc,
                key: index as React.Key,
              }))}
              columns={columns}
              rowSelection={rowSelection}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
