import { ICitySelectField, FieldProps } from '../../types'
import { EcoSelect } from 'components'
import { useDebounce } from 'hooks'
import get from 'lodash/get'
import React, { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useSearchCitiesQuery } from 'redux/services/city'
import { SelectResponse } from 'redux/types'

export const CitySelectField: FC<FieldProps<ICitySelectField>> = (props) => {
  const { control, formState, setValue } = useFormContext()

  const [inputValueCity, setInputValueCity] = React.useState('')
  const debouncedCity = useDebounce(inputValueCity)

  const SearchCitiesQuery = useSearchCitiesQuery(debouncedCity, {
    skip: !debouncedCity,
  })

  const cityData = !debouncedCity ? null : SearchCitiesQuery.data

  console.log('cityData', cityData)

  const options = cityData
    ? cityData.map((city) => ({
        label: city.title,
        value: city,
      }))
    : []

  const error = get(formState.errors, props.name)

  return (
    <Controller
      control={control}
      name={props.name}
      render={({ field }) => (
        <EcoSelect
          name={field.name}
          placeholder={props.placeholder!}
          freeSolo={true}
          value={
            !field.value
              ? null
              : { label: field.value.title, value: field.value }
          }
          filterOptions={(x) => x}
          onBlur={() => {
            console.log('onBlur')
            setInputValueCity('')
          }}
          /*helperText={
formik.touched.city
		? formik.errors.city
		: undefined
}
onBlur={formik.handleBlur}*/
          options={options}
          /*onChange={(event, newValue) => {
formik.setFieldValue(
		'city',
		(newValue as any)?.value
)
}}
error={
!!formik.touched.city &&
!!formik.errors.city
}*/
          error={Boolean(error)}
          helperText={error?.message as string}
          onChange={(event, newValue) => {
            /*formik.setFieldValue(
'city',
(newValue as any)?.value
)*/
            setValue(field.name, newValue?.value || null, {
              shouldValidate: true,
            })
          }}
          onInputChange={(event, newInputValue) => {
            setInputValueCity(newInputValue)
          }}
          sx={{}}
        />
      )}
    />
  )
}
