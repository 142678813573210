import { User } from '../types'
import { baseApi } from './api'

export const userApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getCurrentUser: build.query<User, string>({
      query: (userId) => ({ url: `users/${userId}` }),
      providesTags: (result, error, arg) => {
        if (result) return [{ type: 'User', id: result._id }]
        else return []
      },
    }),
    getEmployees: build.query<User[], void>({
      query: () => ({ url: `users/type/employees` }),
    }),
    createNewUser: build.mutation<User, User>({
      query: (data) => ({ url: 'users', data, method: 'POST' }),
      invalidatesTags: (result, error, arg, meta) => [
        { type: 'User', id: 'List' },
      ],
    }),

    updateUser: build.mutation<User, Partial<User> & { _id: string }>({
      query: (data) => {
        const { _id, ...body } = data

        console.log('body', body)

        return { url: `users/${_id}`, data: body, method: 'PUT' }
      },
      invalidatesTags: (result, error, arg, meta) => {
        console.log('invalidates', result, arg)
        return [{ type: 'User', id: arg._id }]
      },
    }),
  }),
})

export const {
  useUpdateUserMutation,
  useCreateNewUserMutation,
  useGetCurrentUserQuery,
  useGetEmployeesQuery,
} = userApi

export const test = 0
