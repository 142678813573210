import { renderField } from '../../lib/renderField'
import { IFormField } from '../../types'
import styles from './styles.module.scss'
import { Box } from '@mui/material'
import { useYupValidationResolver } from 'lib/useYupValidationResolver'
import React, { FC } from 'react'
import {
  FieldValues,
  SubmitHandler,
  useForm,
  FormProvider,
} from 'react-hook-form'

interface DocumentFormProps<T extends FieldValues = any> {
  defaultValues?: any
  validationSchema?: any
  onSubmit: SubmitHandler<T>
  children?: React.ReactNode
  fields: IFormField[]
  ns?: string
}

export const DocumentForm: FC<DocumentFormProps> = ({
  defaultValues,
  validationSchema,
  onSubmit,
  children,
  fields,
}) => {
  const resolver = useYupValidationResolver(validationSchema)

  const form = useForm({
    resolver,
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues,
  })

  return (
    <FormProvider {...form}>
      <Box
        className={styles.card}
        component="form"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        {fields.map((field) => (
          <Box className={styles['form-field']}>{renderField(field)}</Box>
        ))}
        {children}
      </Box>
    </FormProvider>
  )
}
