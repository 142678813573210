import { useSession } from '../../context/session.context'
import { setTitle } from '../../pages'
import {
    useCreateEventMutation,
    useEditEventMutation,
} from '../../redux/services/events'
import { EcoEvent, EcoEventCreateProps, EventType } from '../../redux/types'
import { FormikModal } from '../modal'
import { CalenderFilter } from './CalenderFilter'
import './calender.styles.scss'
import bootstrap5Plugin from '@fullcalendar/bootstrap5'
import {
    CalendarApi,
    DateSelectArg,
    EventAddArg,
    EventClickArg,
} from '@fullcalendar/core'
import { EventImpl } from '@fullcalendar/core/internal'
import ruLocale from '@fullcalendar/core/locales/ru'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import listPlugin from '@fullcalendar/list'
import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'
import 'bootstrap-icons/font/bootstrap-icons.css'
import days from 'dayjs'
import moment from 'moment'
import React from 'react'

type Props = {
    events: EcoEvent[]
    setFilters: React.Dispatch<
        React.SetStateAction<{ assignedTo: string; type: string }>
    >
    filters: { assignedTo: string; type: string }
}

const format = 'HH:mm'

export const EcoCalender: React.FC<Props> = (props) => {
    const { events, setFilters, filters } = props
    const [session] = useSession()
    const { userType } = session
    const [open, setOpen] = React.useState(false)
    const [isEdit, setIsEdit] = React.useState(false)
    const [selectedEvent, setSelectedEvent] = React.useState<
        EventImpl | undefined
    >(undefined)
    const [startDate, setStartDate] = React.useState<days.Dayjs | null>(null)

    const [editValues, setEditValues] = React.useState<
        EcoEventCreateProps | undefined
    >()

    const [createEvent, { isLoading, isSuccess, error, data }] =
        useCreateEventMutation()
    const [editEvent] = useEditEventMutation()

    const [calenderApi, setCalenderApi] = React.useState<CalendarApi | null>(
        null
    )

    const handleEventAdd = async (arg: EventAddArg) => {
        setOpen(true)
    }

    const handleEventClick = (selected: EventClickArg) => {
        setStartDate(days(selected.event.startStr))
        setSelectedEvent(selected.event)
        setIsEdit(true)

        setEditValues({
            assignedTo: selected.event.extendedProps.assignedTo,
            comment: selected.event.extendedProps.comment,
            patient: selected.event.extendedProps.patient,
            type: selected.event.extendedProps.type,
            startStr: days(selected.event.extendedProps.startStr),
            endStr: days(selected.event.extendedProps.endStr),
        })
        setOpen(true)
    }

    const handleDateClick = (selected: DateSelectArg) => {
        const now = moment()
        const selectedMoment = moment(selected.start)

        if (selectedMoment.isBefore(now, 'date')) {
            return
        }
        setOpen(true)
        setStartDate(days(selected.startStr))
        setCalenderApi(selected.view.calendar)
        setEditValues(undefined)
        setIsEdit(false)
    }
    const handleCancel = () => {
        setOpen(false)
        setCalenderApi(null)
        setStartDate(null)
    }

    const setAppointment = async (values: {
        startStr: days.Dayjs
        endStr: days.Dayjs
        comment: string
        assignedTo: string
        patient: string
        type: EventType
    }) => {
        const newEvent = {
            title: setTitle({
                ...values,
                startStr: values.startStr.toISOString(),
                endStr: values.endStr.toISOString(),
            }),
            start: values.startStr,
            startStr: values.startStr.toISOString(),
            color:
                values.type !== EventType.unavailability
                    ? '#E2F5FA'
                    : '#E74A56',
            end: values.endStr,
            endStr: values.endStr.toISOString(),
            textColor:
                values.type !== EventType.unavailability ? '#000000' : '#fff',
            type: values.type,
            comment: values.comment,
            patient: values.patient,
            assignedTo: values.assignedTo,
            _id: (values as any)._id,
        }

        const newCreatedEvent = {
            ...newEvent,
            start: newEvent.start.toISOString().split('T')[0],
            end: newEvent.start.toISOString().split('T')[0],
        }

        if (!isEdit) {
            await createEvent(newCreatedEvent)
        } else {
            await editEvent({
                ...newCreatedEvent,
                eventId: selectedEvent?.extendedProps._id,
            })
        }

        setOpen(false)
        setOpen(false)
        setCalenderApi(null)
        setStartDate(null)
    }

    return (
        <>
            {startDate && (
                <FormikModal
                    selectedEvent={selectedEvent}
                    handleCancel={handleCancel}
                    setAppointment={setAppointment}
                    startDate={startDate}
                    open={open}
                    setOpen={setOpen}
                    editValues={editValues}
                />
            )}

            <CalenderFilter setFilters={setFilters} filters={filters} />

            <FullCalendar
                eventStartEditable={false}
                eventDurationEditable={false}
                events={events}
                droppable={false}
                select={handleDateClick}
                eventClick={handleEventClick}
                eventAdd={handleEventAdd}
                themeSystem={'bootstrap5'}
                locales={[ruLocale]}
                editable={true}
                selectable={true}
                selectMirror={true}
                dayMaxEvents={true}
                locale="ru"
                initialView="dayGridMonth"
                weekends={true}
                plugins={[dayGridPlugin, interactionPlugin, bootstrap5Plugin]}
                headerToolbar={{
                    left: 'prev,next today',
                    center: 'title',
                    right: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth',
                }}
            />
        </>
    )
}
