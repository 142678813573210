import { BaseQueryFn } from '@reduxjs/toolkit/query'
import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react'
import { api } from 'api'

/*
const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_SERVER,
    prepareHeaders: (headers, { getState }) => {
        const token = localStorage.getItem('token')

        if (token) {
            headers.set('authorization', `Bearer ${token}`)
        }
        return headers
    },
})

const baseQueryWithRetry = retry(baseQuery, { maxRetries: 6 })

export const api = createApi({
    baseQuery: baseQueryWithRetry,
    endpoints: () => ({}),
    tagTypes: ['User', 'ECONotification', 'Patient'],
})
*/

export const axiosBaseQuery =
  (): BaseQueryFn =>
  async ({ url, method, data, params }) => {
    try {
      const result = await api({ url, method, data, params })
      return { data: result.data }
    } catch (axiosError) {
      let err: any = axiosError

      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      }
    }
  }

export const baseApi = createApi({
  reducerPath: 'api',
  baseQuery: axiosBaseQuery(),
  endpoints: () => ({}),
  tagTypes: ['User', 'ECONotification', 'Patient'],
})
