import styles from './styles.module.scss'
import { Box } from '@mui/material'
import clsx from 'clsx'
import { BackButton, AlertDialog } from 'components'
import { DocumentFormWithSteps, IDocument } from 'features/documents'
import { isSuccessResult } from 'lib/isSuccessResult'
import React, { useEffect, useState } from 'react'
import { useSearchParams, useParams, useNavigate } from 'react-router-dom'
import {
  useAddDocumentMutation,
  useGetDocumentsContextQuery,
} from 'redux/services'

export const DocumentCreatePage: React.FC = () => {
  const [doc, setDoc] = useState<IDocument | null>(null)
  const [modal, setModal] = useState<React.ReactNode>(null)
  const navigate = useNavigate()

  const { template } = useParams()

  const [searchParams] = useSearchParams()
  const params = Object.fromEntries(searchParams.entries())

  //const template = params.template
  const patientId = params.patientId

  const getDocumentsContextQuery = useGetDocumentsContextQuery(patientId)
  const documentsContext = getDocumentsContextQuery.data

  useEffect(() => {
    import(`documents/${template}`).then((data) => {
      setDoc(data.default)
    })
  }, [template])

  const [addDocument] = useAddDocumentMutation()

  const onSubmit = (data: any) => {
    addDocument({
      template,
      data,
      title: doc?.title,
      patientId,
    }).then((result) => {
      if (isSuccessResult(result)) {
        setModal(
          <AlertDialog
            onClose={() => {
              setModal(null)
              navigate('/documents')
            }}
            severity={'success'}
            message={'Новый документ создан'}
          />
        )
      } else {
        setModal(
          <AlertDialog
            onClose={() => {
              setModal(null)
              navigate('/documents')
            }}
            severity={'error'}
            message={'Что-то пошло не так'}
          />
        )
      }
    })
  }

  return (
    <>
      {modal}
      <Box className={clsx('content', styles.documentCreatePage)}>
        <BackButton />
        <Box className={clsx(styles['document-create'])}>
          <div>
            {doc && documentsContext && (
              <DocumentFormWithSteps
                onSubmit={onSubmit}
                ctx={documentsContext}
                {...doc}
              />
            )}
          </div>
        </Box>
      </Box>
    </>
  )
}
