import { baseApi } from './api'
import { TagDescription } from '@reduxjs/toolkit/query'

const apiWithDocuments = baseApi.enhanceEndpoints({
  addTagTypes: ['Document'],
})

export const documentsApi = apiWithDocuments.injectEndpoints({
  endpoints: (build) => ({
    getDocument: build.query({
      query: (documentId) => ({ url: `/documents/${documentId}` }),
      providesTags: (result) => {
        const tags: TagDescription<'Document'>[] = [{ type: 'Document' }]

        if (!result) return tags

        return tags.concat({
          type: 'Document',
          id: result._id as string,
        })
      },
    }),
    getDocumentsContext: build.query({
      query: (patientId) => ({ url: `/documents/getContext/${patientId}` }),
      providesTags: (result, errors, arg) => {
        const tags: TagDescription<'User' | 'Patient'>[] = []

        if (!result) return []

        if (result.doctor?._id)
          tags.push({ type: 'User', id: result.doctor?._id })

        tags.push({ type: 'Patient', id: arg })

        return tags
      },
    }),
    getDocuments: build.query({
      query: (params) => ({ url: '/documents/getAll', params }),
      providesTags: (result) => {
        const tags: TagDescription<'Document'>[] = [
          { type: 'Document', id: 'LIST' },
        ]

        if (!result) return tags

        return tags.concat(
          result.documents.map((document: any) => ({
            type: 'Document',
            id: document._id as string,
          }))
        )
      },
    }),
    addDocument: build.mutation({
      query: (data) => ({
        url: '/documents/create',
        method: 'POST',
        data,
      }),
      invalidatesTags: [{ type: 'Document', id: 'LIST' }],
    }),
    updateDocument: build.mutation({
      query: (data) => ({
        url: '/documents/update',
        method: 'POST',
        data,
      }),
      invalidatesTags: (data, error, arg) => [
        { type: 'Document', id: arg._id },
      ],
    }),
    deleteDocument: build.mutation({
      query: (id) => ({
        url: `/documents/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Document', id: 'LIST' }],
    }),
  }),
})

export const {
  useGetDocumentsQuery,
  useDeleteDocumentMutation,
  useAddDocumentMutation,
  useUpdateDocumentMutation,
  useGetDocumentQuery,
  useGetDocumentsContextQuery,
} = documentsApi
