import { createOptions } from '../lib/createOptions'

interface Option {
  label: string
  value: string
}

export const cipherMkb10Options: Option[] = [
  { value: 'G80.0', label: 'G80.0 Спастический церебральный паралич' },
  { value: 'G80.1', label: 'G80.1 Спастическая диплегия' },
  { value: 'G80.2', label: 'G80.2 Детская гемиплегия' },
  { value: 'G80.3', label: 'G80.3 Дискинетический церебральный паралич' },
  { value: 'G80.9', label: 'G80.9 Детский церебральный паралич неуточненный' },
  { value: 'G81.0', label: 'G81.0 Вялая гемиплегия' },
  { value: 'G81.1', label: 'G81.1 Спастическая параплегия' },
  { value: 'G81.9', label: 'G81.9 Гемиплегия неуточненная' },
  { value: 'G82.0', label: 'G82.0 Вялая параплегия' },
  { value: 'G82.1', label: 'G82.1 Спастическая параплегия' },
  { value: 'G82.2', label: 'G82.2 Параплегия неуточненная' },
  { value: 'G82.3', label: 'G82.3 Вялая тетраплегия' },
  { value: 'G82.4', label: 'G82.4 Спастическая тетраплегия' },
  { value: 'G82.5', label: 'G82.5 Тетраплегия неуточненная' },
  { value: 'G83.0', label: 'G83.0 Диплегия верхних конечностей' },
  { value: 'G83.1', label: 'G83.1 Моноплегия нижней конечности' },
  { value: 'G83.2', label: 'G83.2 Моноплегия верхней конечности' },
  { value: 'G83.3', label: 'G83.3 Моноплегия неуточненная' },
  { value: 'G83.4', label: 'G83.4 Синдром конского хвоста' },
  {
    value: 'G83.8',
    label:
      'G83.8 Другие уточненные паралитические синдромы паралич тодда (постэпилептический)',
  },
  {
    value: 'G12.0',
    label:
      'G12.0 Детская спинальная мышечная атрофия 1 типа (Верднига - Гоффмана)',
  },
  { value: 'G12.1', label: 'G12.1 Другие наследственные СМА' },
  {
    value: 'G35.7',
    label:
      'G35.7 Другие уточненные демиелинизирующие болезни центральной нервной системы',
  },
  {
    value: 'G70.8',
    label: 'G70.8 Другие нарушения нервно-мышечного синапса (миастении)',
  },
  { value: 'G71.8', label: 'G71.8 Другие первичные поражения мышц (миопатии)' },
  {
    value: 'G93.2',
    label: 'G93.2 Доброкачественная внутричерепная гипертензия',
  },
  {
    value: 'G93.3',
    label: 'G93.3 Синдром утомляемости после перенесенной вирусной болезни',
  },
  { value: 'G93.4', label: 'G93.4 Энцефалопатия неуточненная' },
  {
    value: 'G93.8',
    label: 'G93.8 Другие уточненные поражения головного мозга',
  },
  { value: 'G93.9', label: 'G93.9 Поражение головного мозга неуточненное' },
  {
    value: 'G90.8',
    label:
      'G90.8 Другие расстройства вегетативной [автономной] нервной системы',
  },
  {
    value: 'G90.9',
    label:
      'G90.9 Расстройство вегетативной [автономной] нервной системы неуточненное',
  },
  {
    value: 'G62.2',
    label: 'G62.2 Полинейропатия, вызванная другими токсическими веществами',
  },
  { value: 'G62.9', label: 'G62.9 Полинейропатия неуточненная' },
  {
    value: 'G60.0',
    label: 'G60.0 Наследственная моторная и сенсорная невропатия',
  },
  {
    value: 'G47.0',
    label: 'G47.0 Нарушения засыпания и поддержания сна [бессонница]',
  },
  {
    value: 'G47.1',
    label: 'G47.1 Нарушения в виде повышенной сонливости [гиперсомния]',
  },
  { value: 'G47.2', label: 'G47.2 Нарушения цикличности сна и бодрствования' },
  { value: 'G47.8', label: 'G47.8 Другие нарушения сна' },
  { value: 'G47.9', label: 'G47.9 Нарушение сна неуточненное' },
  {
    value: 'G44.1',
    label:
      'G44.1 Сосудистая головная боль, не классифицированная в других рубриках',
  },
  { value: 'G44.2', label: 'G44.2 Головная боль напряженного типа' },
  {
    value: 'G44.4',
    label:
      'G44.4 Головная боль, вызванная применением лекарственных средств, не классифицированная в других рубриках',
  },
  { value: 'G44.8', label: 'G44.8 Другой уточненный синдром головной боли' },
  { value: 'G43.0', label: 'G43.0 Мигрень без ауры [простая мигрень]' },
  { value: 'G43.1', label: 'G43.1 Мигрень с аурой [классическая мигрень]' },
  { value: 'G43.9', label: 'G43.9 Мигрень неуточненная' },
  { value: 'G40.8', label: 'G40.8 Другие уточненные формы эпилепсии' },
  { value: 'G40.9', label: 'G40.9 Эпилепсия неуточненная' },
  {
    value: 'G40.3',
    label:
      'G40.3 Генерализованная идиопатическая эпилепсия и эпилептические синдромы',
  },
  {
    value: 'G40.4',
    label:
      'G40.4 Другие виды генерализованной эпилепсии и эпилептических синдромов',
  },
  {
    value: 'G40.0',
    label:
      'G40.0 Локализованная (фокальная) (парциальная) идиопатическая эпилепсия и эпилептические синдромы с судорожными припадками с фокальным началом ',
  },
  {
    value: 'G35.1',
    label: 'G35.1 Рассеянный склероз с преимущественно рецидивирующим течением',
  },
  {
    value: 'G35.2',
    label: 'G35.2 Рассеянный склероз с первично-прогрессирующим течением',
  },
  {
    value: 'G35.3',
    label: 'G35.3 Рассеянный склероз с вторично-прогрессирующим течением',
  },
  { value: 'G35.9', label: 'G35.9 Рассеянный склероз неуточненный ' },
  {
    value: 'G31.8',
    label: 'G31.8 Другие уточненные дегенеративные болезни нервной системы',
  },
  {
    value: 'G24.0',
    label: 'G24.0 Дистония, вызванная лекарственными средствами',
  },
  { value: 'G24.1', label: 'G24.1 Идиопатическая семейная дистония' },
  { value: 'G24.3', label: 'G24.3 Спастическая кривошея' },
  { value: 'G24.5', label: 'G24.5 Блефароспазм' },
  { value: 'G24.8', label: 'G24.8 Прочие дистонии' },
  { value: 'G24.9', label: 'G24.9 Дистония неуточненная' },
  {
    value: 'G37.9',
    label:
      'G37.9 Демиелинизирующие болезни центральной нервной системы неуточненные',
  },
  {
    value: 'G96.8',
    label:
      'G96.8 Другие уточненные поражения головного мозга при болезнях, классифицированны',
  },
  {
    value: 'I69.0',
    label: 'I69.0 Последствия субарахноидального кровоизлияния',
  },
  { value: 'I69.1', label: 'I69.1 Последствия внутричерепного кровоизлияния' },
  {
    value: 'I69.2',
    label:
      'I69.2 Последствия другого нетравматического внутричерепного кровоизлияния',
  },
  { value: 'I69.3', label: 'I69.3 Последствия инфаркта мозга' },
  {
    value: 'I69.4',
    label:
      'I69.4 Последствия инсульта, не уточненные как кровоизлияние или инфаркт мозга',
  },
  {
    value: 'I69.8',
    label:
      'I69.8 Последствия других и неуточненных цереброваскулярных болезней',
  },
  { value: 'T90.5', label: 'T90.5 Последствие внутричерепной травмы' },
  { value: 'T90.8', label: 'T90.8 Последствие других уточненных травм головы' },
  { value: 'T90.9', label: 'T90.9 Последствие неуточненной травмы головы' },
  { value: 'T91.3', label: 'T91.3 Последствия травмы спинного мозга' },
  {
    value: 'T92.4',
    label: 'T92.4 Последствия травмы нерва верхней конечности',
  },
  { value: 'T93.4', label: 'T93.4 Последствия травмы нерва нижней конечности' },
  { value: 'M08.0', label: 'M08.0 Юношеский ревматоидный артрит' },
  { value: 'M24.5', label: 'M24.5 Контрактура сустава' },
  { value: 'M41.0', label: 'M41.0 Инфантильный идиопатический сколиоз' },
  { value: 'M41.1', label: 'M41.1 Юношеский идиопатический сколиоз' },
  { value: 'M41.2', label: 'M41.2 Другие идиопатические сколиозы' },
  { value: 'M41.3', label: 'M41.3 Торакогенный сколиоз' },
  { value: 'M41.4', label: 'M41.4 Нервно-мышечный сколиоз' },
  { value: 'M41.5', label: 'M41.5 Прочие вторичные сколиозы' },
  { value: 'M41.8', label: 'M41.8 Другие формы сколиоза' },
  { value: 'M41.9', label: 'M41.9 Сколиоз неуточненный' },
  {
    value: 'T91.0',
    label:
      'T91.0 Последствия поверхностной травмы и открытого ранения шеи и туловища',
  },
  { value: 'T91.1', label: 'T91.1 Последствие перелома позвоночника' },
  {
    value: 'T91.2',
    label: 'T91.2 Последствие других переломов грудной клетки и таза',
  },
  { value: 'T91.3', label: 'T91.3 Последствие травмы спинного мозга' },
  {
    value: 'T92.0',
    label: 'T92.0 Последствие открытого ранения верхней конечности',
  },
  {
    value: 'T92.1',
    label:
      'T92.1 Последствие перелома верхней конечности, исключая запястье и кисть',
  },
  {
    value: 'T92.2',
    label: 'T92.2 Последствие перелома на уровне запястья и кисти',
  },
  {
    value: 'T92.3',
    label:
      'T92.3 Последствие вывиха, растяжения и деформации верхней конечности',
  },
  {
    value: 'T92.4',
    label: 'T92.4 Последствие травмы нерва верхней конечности',
  },
  {
    value: 'T92.5',
    label: 'T92.5 Последствие травмы мышцы и сухожилия верхней конечности',
  },
  {
    value: 'T92.8',
    label: 'T92.8 Последствие других уточненных травм верхней конечности',
  },
  {
    value: 'T92.9',
    label: 'T92.9 Последствие неуточненной травмы верхней конечности',
  },
  {
    value: 'T93.0',
    label: 'T93.0 Последствие открытого ранения нижней конечности',
  },
  { value: 'T93.1', label: 'T93.1 Последствие перелома бедра' },
  {
    value: 'T93.2',
    label: 'T93.2 Последствие других переломов нижних конечностей',
  },
  {
    value: 'T93.3',
    label:
      'T93.3 Последствие вывиха, растяжения и деформации нижней конечности',
  },
  { value: 'T93.4', label: 'T93.4 Последствие травмы нерва нижней конечности' },
  {
    value: 'T93.5',
    label: 'T93.5 Последствие травмы мышцы и сухожилия нижней конечности',
  },
  {
    value: 'T93.6',
    label:
      'T93.6 Последствие размозжения и травматической ампутации нижней конечности',
  },
  {
    value: 'T93.8',
    label: 'T93.8 Последствие других уточненных травм нижней конечности',
  },
  {
    value: 'T93.9',
    label: 'T93.9 Последствие неуточненной травмы нижней конечности',
  },
  {
    value: 'T94.0',
    label: 'T94.0 Последствия травм, захватывающих несколько областей тела',
  },
  { value: 'H90.3', label: 'H90.3 Нейросенсорная потеря слуха двусторонняя' },
  { value: 'Q03.9', label: 'Q03.9 Врожденная гидроцефалия неуточненная' },
  {
    value: 'Q05',
    label: 'Q05 Spina bifida [неполное закрытие позвоночного канала]',
  },
  {
    value: 'Q05.0',
    label: 'Q05.0 Spina bifida в шейном отделе с гидроцефалией',
  },
  {
    value: 'Q05.1',
    label: 'Q05.1 Spina bifida в грудном отделе с гидроцефалией',
  },
  {
    value: 'Q05.2',
    label: 'Q05.2 Spina bifida в поясничном отделе с гидроцефалией',
  },
  {
    value: 'Q05.3',
    label: 'Q05.3 Spina bifida в сакральном отделе с гидроцефалией',
  },
  { value: 'Q05.4', label: 'Q05.4 Spina bifida с гидроцефалией неуточненная' },
  {
    value: 'Q05.5',
    label: 'Q05.5 Spina bifida в шейном отделе без гидроцефалии',
  },
  {
    value: 'Q05.6',
    label: 'Q05.6 Spina bifida в грудном отделе без гидроцефалии',
  },
  {
    value: 'Q05.7',
    label: 'Q05.7 Spina bifida в поясничном отделе без гидроцефалии',
  },
  { value: 'Q05.8', label: 'Q05.8 Spina bifida в крестцовом отделе' },
  { value: 'Q05.9', label: 'Q05.9 Spina bifida неуточненная' },
  { value: 'Q74.3', label: 'Q74.3 Врожденный множественный артрогрипоз' },
  {
    value: 'Q74.8',
    label: 'Q74.8 Другие уточненные врожденные аномалии конечности(ей)',
  },
  {
    value: 'Q74.9',
    label: 'Q74.9 Врожденная аномалия конечности(ей) неуточненная',
  },
  { value: 'Q75.0', label: 'Q75.0 Краниосиностоз' },
  { value: 'Q75.1', label: 'Q75.1 Краниофациальный дизостоз' },
  { value: 'Q85.0', label: 'Q85.0 Нейрофиброматоз (незлокачественный)' },
  { value: 'Q85.1', label: 'Q85.1 Туберозный склероз' },
  { value: 'Q85.9', label: 'Q85.9 Факоматоз неуточненный' },
  { value: 'M42.0', label: 'M42.0 Юношеский остеохондроз позвоночника' },
  { value: 'M42.1', label: 'M42.1 Остеохондроз позвоночника у взрослых' },
  { value: 'M42.9', label: 'M42.9 Остеохондроз позвоночника неуточненный' },
  { value: 'M54.2', label: 'M54.2 Цервикалгия' },
  { value: 'M54.8', label: 'M54.8 Другая дорсалгия' },
  { value: 'M54.6', label: 'M54.6 Боль в грудном отделе позвоночника' },
  { value: 'M54.5', label: 'M54.5 Боль внизу спины' },
  { value: 'M54.3', label: 'M54.3 Ишиас' },
  { value: 'M54.1', label: 'M54.1 Радикулопатия' },
  { value: 'M53.9', label: 'M53.9 Дорсопатия неуточненная' },
  { value: 'M53.8', label: 'M53.8 Другие уточненные дорсопатии' },
  {
    value: 'M53.3',
    label:
      'M53.3 Крестцово-копчиковые нарушения, не классифицированные в других рубриках',
  },
  { value: 'M53.2', label: 'M53.2 Спинальная нестабильность' },
  { value: 'M54.4', label: 'M54.4 Люмбаго с ишиасом' },
]
