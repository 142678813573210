import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { Box, Paper } from '@mui/material'

type Props = {
    onDrop: (acceptedFiles: File[]) => void
}

export const EcoDropzone: React.FC<Props> = (props) => {
    const { onDrop } = props

    const { getInputProps, getRootProps, isDragActive, acceptedFiles } =
        useDropzone({
            onDrop,
            multiple: false,
            accept: {
                'image/*': ['.png', '.jpg', '.jpeg'],
            },
        })

    const { ref, ...rootProps } = getRootProps()
    const trimFileName = (fileName: string) => {
        let trimmedName = fileName
        if (fileName.length > 16) {
            trimmedName =
                '...' +
                fileName
                    .split('')
                    .reverse()
                    .join('')
                    .slice(0, 15)
                    .split('')
                    .reverse()
                    .join('')
        }

        return trimmedName
    }

    return (
        <div
            {...rootProps}
            style={{
                border: '1px dashed #D5DEE6',
                borderRadius: '6px',
                background: '#F9FAFB',
                height: '120px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                letterSpacing: '0.01em',
                textAlign: 'center',
            }}
        >
            <Box sx={{ width: '122px', height: '44px' }}>
                <input {...getInputProps()} />
                {Boolean(acceptedFiles.length) && (
                    <p style={{ fontSize: '14px', color: '#1E71EA' }}>
                        {trimFileName(acceptedFiles[0].name)}
                    </p>
                )}
                {!Boolean(acceptedFiles.length) && (
                    <p style={{ fontSize: '14px' }}>
                        Перетащите фото или{' '}
                        <span
                            style={{
                                color: '#1E71EA',
                                cursor: 'pointer',
                                overflow: 'hidden',
                                textOverflow: 'revert',
                                whiteSpace: 'nowrap',
                                maxWidth: '60px',
                            }}
                        >
                            {Boolean(acceptedFiles.length)
                                ? acceptedFiles[0].name
                                : 'загрузите'}
                        </span>
                    </p>
                )}
            </Box>
        </div>
    )
}
