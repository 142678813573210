import './patient.styles.scss'
import { appointmentValidationSchema } from './validation'
import { Box, Button, styled, Typography } from '@mui/material'
import { CustomTextField, EcoSelect, AlertDialog } from 'components'
import { getDateValue } from 'lib/dateObject'
import { useYupValidationResolver } from 'lib/useYupValidationResolver'
import get from 'lodash/get'
import moment from 'moment'
import React, { useMemo } from 'react'
import { useForm, SubmitHandler, Controller } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useGetEmployeesQuery } from 'redux/services'
import { useCreateEventMutation } from 'redux/services/events'
import { EventType, Patient, SelectResponse } from 'redux/types'

const StyledCustomButton = styled(Button)({
  color: '#1E71EA',
  background: '#F9FAFB',
  border: 'none',
  textTransform: 'capitalize',
  fontWeight: '500',
  padding: '13px, 16px, 13px, 16px',
  height: '50px',
  width: '160px',
  size: '16px',
  '&:hover': {
    border: 'none',
    color: '#2E7BEB',
  },
})

interface AppointmentsPatientWidgetProps {
  patient: Patient
}

export const AppointmentsPatientWidget: React.FC<
  AppointmentsPatientWidgetProps
> = ({ patient }) => {
  const [modal, setModal] = React.useState<React.ReactNode>(null)

  const navigate = useNavigate()

  const { data: employeesData } = useGetEmployeesQuery()

  const [createEvent] = useCreateEventMutation()

  const resolver = useYupValidationResolver(appointmentValidationSchema)

  const { handleSubmit, control, register, formState, setValue, getValues } =
    useForm({
      resolver,
      mode: 'onSubmit',
      reValidateMode: 'onChange',
    })

  const onSubmit: SubmitHandler<any> = (values) => {
    createEvent({
      startStr: values.plannedVisitDate,
      endStr: values.plannedVisitTimeEnd,
      type: EventType.appointment,
      assignedTo: values.doctorFio,
      patient: patient._id,
      start: moment(values.plannedVisitDate).format('YYYY-MM-DD'),
      end: moment(values.plannedVisitTimeEnd).format('YYYY-MM-DD'),
      //sickNess: values.sickNess,
    })
      .then(() => {
        setModal(
          <AlertDialog
            onClose={() => {
              setModal(null)
              navigate('/calender')
            }}
            severity={'success'}
            message={'Новый приём создан'}
          />
        )
      })
      .catch(() => {
        setModal(
          <AlertDialog
            onClose={() => setModal(null)}
            severity={'error'}
            message={'Ошибка! приём не создан'}
          />
        )
      })
  }

  //FIXME: fix after close
  const handleAfterClose = () => {
    //formik.setValues(initialValues)
  }

  return (
    <>
      {modal}
      <Box
        onSubmit={handleSubmit(onSubmit)}
        className="patientForm"
        component={'form'}
      >
        <Box className="form-field">
          <Typography variant="h6">{patient.fio}</Typography>
        </Box>
        <Box>
          <Controller
            control={control}
            name={'plannedVisitDate'}
            render={({ field }) => (
              <CustomTextField
                /*onChange={formik.handleChange}
												onBlur={formik.handleBlur}*/
                //name="dob"
                type={'datetime-local'}
                label="Дата планируемого визита с"
                placeholder="Введите Дата визита"
                /*error={
														!!formik.touched.dob && !!formik.errors.dob
												}
												helperText={
														formik.touched.dob
																? formik.errors.dob
																: undefined
												}
												value={formik.values.dob}*/
                /*{...register('dateOfBirth')}*/
                name={field.name}
                onChange={field.onChange}
                onBlur={field.onBlur}
                defaultValue={getDateValue(field.value)}
                error={Boolean(get(formState.errors, field.name))}
                helperText={
                  get(formState.errors, field.name)?.message as string
                }
              />
            )}
          />
        </Box>
        <Box>
          <Controller
            control={control}
            name="plannedVisitTimeEnd"
            render={({ field }) => (
              <CustomTextField
                /*onChange={formik.handleChange}
												onBlur={formik.handleBlur}*/
                //name="dob"
                type={'datetime-local'}
                label="Дата планируемого визита до"
                placeholder="Введите время визита до"
                /*error={
														!!formik.touched.dob && !!formik.errors.dob
												}
												helperText={
														formik.touched.dob
																? formik.errors.dob
																: undefined
												}
												value={formik.values.dob}*/
                /*{...register('dateOfBirth')}*/
                name={field.name}
                onChange={field.onChange}
                onBlur={field.onBlur}
                defaultValue={getDateValue(field.value)}
                error={Boolean(get(formState.errors, field.name))}
                helperText={
                  get(formState.errors, field.name)?.message as string
                }
              />
            )}
          />
        </Box>
        <Box>
          <Controller
            control={control}
            name="doctorFio"
            render={({ field }) => (
              <EcoSelect
                name={field.name}
                freeSolo={true}
                value={field.value}
                filterOptions={(x) => x}
                error={Boolean(get(formState.errors, field.name))}
                helperText={
                  get(formState.errors, field.name)?.message as string
                }
                onChange={(event, newValue) => {
                  setValue(field.name, newValue?.value, {
                    shouldValidate: true,
                  })
                }}
                options={
                  employeesData
                    ? (employeesData.map((employee) => ({
                        _id: employee._id,
                        label: `${employee.surName} ${employee.secondName} ${employee.firstName}`,
                        value: employee._id,
                      })) as SelectResponse[])
                    : []
                }
                sx={{ width: '100%' }}
                placeholder="Введите ФИО врача"
              />
            )}
          />
        </Box>
        <Box className="actions">
          <StyledCustomButton
            type={'submit'}
            variant="outlined"
            className="actions__button"
          >
            Записаться на приём
          </StyledCustomButton>
        </Box>
      </Box>
    </>
  )
}
