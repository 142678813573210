import { api } from '../api'
import React from 'react'

export const useDebounce = (value: string, delay = 300) => {
  const [debounced, setDebounced] = React.useState(value)

  React.useEffect(() => {
    const handler = setTimeout(() => setDebounced(value), delay)
    return () => clearTimeout(handler)
  }, [value, delay])

  return debounced
}

export const useDownload = () => {
  const downloadFile = async (fileUrl: string, fileName: string) => {
    const response = await api({
      url: fileUrl,
      method: 'GET',
      responseType: 'blob',
      baseURL: `${process.env.REACT_APP_MEDIA_URL}`,
    })

    const url = window.URL.createObjectURL(response.data)

    const link = document.createElement('a')
    link.href = url
    link.download = fileName
    document.body.appendChild(link)
    link.click()
    link?.parentNode?.removeChild(link)
  }

  return { downloadFile }
}
