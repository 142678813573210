import { ISelectField, FieldProps } from '../../types'
import { EcoSelect } from 'components'
import get from 'lodash/get'
import React, { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

interface SelectFieldProps extends FieldProps<ISelectField> {
  renderValue?: (value: any) => any
}

export const SelectField: FC<SelectFieldProps> = ({
  name,
  placeholder,
  options,
  renderValue,
}) => {
  const { control, formState, setValue } = useFormContext()

  const error = get(formState.errors, name)

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <EcoSelect
          //onBlur={formik.handleBlur}
          error={Boolean(get(formState.errors, field.name))}
          sx={{}}
          options={options}
          name={field.name}
          placeholder={placeholder!}
          //value={field.value}
          value={
            field.value
              ? renderValue
                ? renderValue(field.value)
                : field.value
              : null
          }
          helperText={error?.message as string}
          onChange={(event, newValue) => {
            /*formik.setFieldValue(
																					'identityDocument',
																					(newValue as any)?.value
																			)*/
            setValue(field.name, newValue?.value || null, {
              shouldValidate: true,
            })
          }}
        />
      )}
    />
  )
}
