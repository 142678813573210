import './notfound.styles.scss'
import { Box, Typography } from '@mui/material'
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

export const NotFoundPage: React.FC = () => {
    const navigate = useNavigate()

    const goBack = () => {
        navigate(-1)
    }

    return (
        <Box className={'notfoundPage'}>
            <Box className={'notfoundPage__content'}>
                <Typography variant="h4">Ошибка 404</Typography>
                <Typography variant={'h6'}>Страница не найдена</Typography>
                <Box className={'notfoundPage__content--link'}>
                    <Link to={'#'} className={'link'} onClick={goBack}>
                        <span>
                            <span className={'icon'}>&larr;</span>
                            назад
                        </span>
                    </Link>
                </Box>
            </Box>
        </Box>
    )
}
