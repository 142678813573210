import { ITimeField } from '../../types'
import { CustomTextField } from 'components'
import get from 'lodash/get'
import React, { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

export const TimeField: FC<ITimeField> = ({ name, label }) => {
  const { control, formState } = useFormContext()

  const error = get(formState.errors, name)

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <CustomTextField
          /*onChange={formik.handleChange}
											onBlur={formik.handleBlur}*/
          type={'time'}
          //name="dob"
          label={label!}
          placeholder=""
          /*error={
													!!formik.touched.dob && !!formik.errors.dob
											}
											helperText={
													formik.touched.dob
															? formik.errors.dob
															: undefined
											}
											value={formik.values.dob}*/
          /*{...register('dateOfBirth')}*/
          name={field.name}
          onChange={field.onChange}
          onBlur={field.onBlur}
          defaultValue={field.value}
          error={Boolean(error)}
          helperText={error?.message as string}
        />
      )}
    />
  )
}
