import { ITextField } from '../../types'
import { CustomTextField } from 'components'
import get from 'lodash/get'
import React, { FC } from 'react'
import { useFormContext } from 'react-hook-form'

export const TextField: FC<ITextField> = (props) => {
  const { register, formState } = useFormContext()

  const error = get(formState.errors, props.name)

  return (
    <>
      <CustomTextField
        type={props.type}
        /*onBlur={formik.handleBlur}
															helperText={
																	formik.touched.surName
																			? formik.errors.surName
																			: undefined
															}
															error={
																	!!formik.touched.surName &&
																	!!formik.errors.surName
															}
															onChange={formik.handleChange}*/
        {...register(props.name)}
        error={Boolean(error)}
        helperText={error?.message as string}
        label={props.label!}
        placeholder={props.placeholder!}
        multiline={props.multiline}
        //value={formik.values.surName}
      />
    </>
  )
}
