import { Appbar } from './components/shared'
import { useSession } from './context/session.context'
import {
  CalenderPage,
  LoginPage,
  ProfilePage,
  UserRegistration,
  AppointmentPage,
  DocumentsPage,
  PatientsPage,
  ProceduresPage,
  PatientPage,
  AppointmentsPatientPage,
  DocumentCreatePage,
  DocumentEditPage,
} from './pages'
import { HomePage } from './pages/home'
import { NotFoundPage } from './pages/notfound'
import PatientRegistrationPage from './pages/patientRegistration/PatientRegistrationPage'
import '@fontsource/montserrat/300.css'
import '@fontsource/montserrat/400.css'
import '@fontsource/montserrat/500.css'
import '@fontsource/montserrat/700.css'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import { CssBaseline, createTheme, ThemeProvider } from '@mui/material'
import { ConfigProvider } from 'antd'
import ruRU from 'antd/locale/ru_RU'
import { Documentation } from 'pages/documentation'
import { DocumentationTemplateSelect } from 'pages/documentationTemplateSelect'
import { PaidServices } from 'pages/paidServices'
import { PaidServicesTemplateSelect } from 'pages/paidServicesTemplateSelect'
import React from 'react'
import { Routes, Route } from 'react-router-dom'

function App() {
  const theme = createTheme({})
  const [session] = useSession()
  const { isAuthenticated } = session

  return (
    <ThemeProvider theme={theme}>
      <ConfigProvider locale={ruRU}>
        <CssBaseline />
        {isAuthenticated && <Appbar />}
        <Routes>
          <Route path={'/login'} element={<LoginPage />} />
          <Route path={'/register'} element={<UserRegistration />} />
          <Route path={'/profile'} element={<ProfilePage />} />
          <Route path={'/'} element={<HomePage />} />
          <Route path={'/calender'} element={<CalenderPage />} />
          <Route path={'/documentation'} element={<Documentation />} />
          <Route
            path={'/documentation/:patientId'}
            element={<DocumentationTemplateSelect />}
          />
          <Route path={'/paidServices'} element={<PaidServices />} />
          <Route
            path={'/paidServices/:patientId'}
            element={<PaidServicesTemplateSelect />}
          />
          <Route path={'/appointments'} element={<AppointmentPage />} />
          <Route
            path={'/appointments/:patientId'}
            element={<AppointmentsPatientPage />}
          />
          <Route path={'/documents'} element={<DocumentsPage />} />
          <Route
            path={'/documents/new/:template'}
            element={<DocumentCreatePage />}
          />
          <Route
            path={'/documents/:documentId'}
            element={<DocumentEditPage />}
          />
          <Route path={'/patients'} element={<PatientsPage />} />
          <Route path={'/patients/:id'} element={<PatientPage />} />
          <Route path={'/procedures'} element={<ProceduresPage />} />
          <Route
            path={'/patients/register/new'}
            element={<PatientRegistrationPage />}
          />

          <Route path={'*'} element={<NotFoundPage />} />
        </Routes>
      </ConfigProvider>
    </ThemeProvider>
  )
}

export default App
