import React from 'react'
import { Box, Button, Paper, styled, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

const Item = styled(Paper)(({ theme }) => ({
    background: 'linear-gradient(134.17deg, #F0F1F6 4.98%, #F8F8FB 94.88%)',
    ...theme.typography.body2,
    padding: '36px',
    margin: '22px',
    textAlign: 'center',
    color: '#000',
    fontFamily: 'Montserrat',
    width: '280px',
    minWidth: '280px',
    height: '338px',
    borderRadius: '40px',
    boxShadow:
        '-20px -20px 25px rgba(255, 255, 255, 0.7), 20px 20px 25px rgba(166, 180, 200, 0.7)',
}))

type Props = {
    cardTitle: string
    icon: string
    cardNumber: number
    cardLink: string
}

export const ActionCard: React.FC<Props> = (props) => {
    const { icon, cardTitle, cardNumber, cardLink } = props
    const navigate = useNavigate()
    return (
        <Item
            sx={{
                color: '#fff',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
            }}
            elevation={2}
        >
            <Box sx={{ color: '#000' }}>
                <Box
                    sx={{
                        marginBottom: '20px',
                        display: 'flex',
                    }}
                >
                    <img style={{ height: '42px' }} src={icon} alt="" />
                </Box>
                <Box
                    sx={{
                        marginBottom: '20px',
                        display: 'flex',
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: '18px',
                            fontWeight: '700',
                            lineHeight: '20px',
                        }}
                    >
                        #{cardNumber}
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex' }}>
                    <Typography
                        sx={{
                            textTransform: 'uppercase',
                            fontSize: '24px',
                            fontFamily: 'Montserrat',
                            fontWeight: '700',
                            textAlign: 'left',
                        }}
                    >
                        {cardTitle}
                    </Typography>
                </Box>
            </Box>

            <Box sx={{ marginTop: '6px', alignSelf: 'left' }}>
                <Button
                    onClick={() => navigate(cardLink)}
                    variant="contained"
                    style={{
                        fontSize: '18px',
                        width: '124px',
                        height: '34px',
                        color: '#39323F',
                        backgroundColor: '#F0F1F6',
                        borderRadius: '20px',
                        textTransform: 'capitalize',
                        boxShadow:
                            '3px 3px 10px rgba(189, 200, 215, 0.15), inset -2px -2px 3px #FFFFFF, inset 2px 2px 3px rgba(166, 180, 200, 0.7)',
                    }}
                >
                    Перейти
                </Button>
            </Box>
        </Item>
    )
}
