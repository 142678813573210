import {
  BackButton,
  CustomNumericField,
  CustomTextField,
  EcoSelect,
} from '../../components'
import { AlertDialog } from '../../components/shared'
import { useSession } from '../../context/session.context'
import { logout } from '../../helpers/logout'
import {
  useGetCurrentUserQuery,
  useGetDepartmentsQuery,
  useGetPositionsQuery,
  useGetSpecializationsQuery,
  useUpdateUserMutation,
} from '../../redux/services'
import { SelectResponse, User } from '../../redux/types'
import { profileValidationSchema } from '../../validationSchemas'
import checkIcon from './../../svgs/check.svg'
import logoutIcon from './../../svgs/logoutIcon.svg'
import './profile.styles.scss'
import { Avatar, Box, Button, styled, Typography } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useFormik } from 'formik'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const StyledCancelButton = styled(Button)({
  color: '#1E71EA',
  background: '#F9FAFB',
  border: 'none',
  textTransform: 'capitalize',
  fontWeight: '500',
  padding: '13px, 16px, 13px, 16px',
  height: '50px',
  width: '160px',
  size: '16px',
  '&:hover': {
    border: 'none',
    color: '#2E7BEB',
  },
})

const StyledSaveButton = styled(Button)({
  color: '#FFFFFF',
  background: '#1E71EA',
  border: 'none',
  textTransform: 'capitalize',
  fontWeight: '500',
  padding: '13px, 16px, 13px, 16px',
  height: '50px',
  width: '160px',
  size: '16px',
  '&:hover': {
    border: 'none',
    color: '#f7f7f7',
    background: '#3D85ED',
  },
})

type UserProfile = Pick<
  User,
  | 'firstName'
  | 'secondName'
  | 'surName'
  | 'phone'
  | 'email'
  | 'specialization'
  | 'dob'
  | 'department'
  | 'position'
  | 'profilePhoto'
>

const initialValues: UserProfile = {
  position: null,
  department: null,
  specialization: null,
  firstName: '',
  secondName: '',
  surName: '',
  dob: '',
  phone: '',
  email: '',
  profilePhoto: '',
}
export const ProfilePage = () => {
  const matches = useMediaQuery('(max-width:962px)')
  const { data: positionData } = useGetPositionsQuery()
  const { data: departmentData } = useGetDepartmentsQuery()
  const { data: specializationsData } = useGetSpecializationsQuery()
  const [open, setOpen] = React.useState(false)
  const navigate = useNavigate()
  const [session, setSession] = useSession()
  const { userType } = session

  const [updateUser, updateUserResult] = useUpdateUserMutation()

  const userId = localStorage.getItem('userId') || ''

  const getCurrentUserQuery = useGetCurrentUserQuery(userId)

  const updateProfile = async (values: UserProfile) => {
    setOpen(true)
    updateUser({ ...values, _id: userId })
  }

  const handleLogout = () => {
    setSession(undefined)
    logout()
    navigate('/login')
  }

  const formik = useFormik({
    initialValues,
    onSubmit: updateProfile,
    validationSchema: profileValidationSchema,
  })

  React.useMemo(() => {
    if (getCurrentUserQuery.data) {
      formik.setFieldValue('firstName', getCurrentUserQuery.data.firstName)
      formik.setFieldValue('secondName', getCurrentUserQuery.data.secondName)
      formik.setFieldValue('surName', getCurrentUserQuery.data.surName)
      formik.setFieldValue('dob', getCurrentUserQuery.data.dob.split('T')[0])
      formik.setFieldValue('email', getCurrentUserQuery.data.email)
      formik.setFieldValue('phone', getCurrentUserQuery.data.phone)
      formik.setFieldValue(
        'profilePhoto',
        getCurrentUserQuery.data.profilePhoto || ''
      )
      formik.setFieldValue(
        'department',
        getCurrentUserQuery.data?.department?._id || ''
      )
      formik.setFieldValue(
        'position',
        getCurrentUserQuery.data?.position?._id || ''
      )
      formik.setFieldValue(
        'specialization',
        getCurrentUserQuery.data?.specialization?._id || ''
      )
    }
  }, [getCurrentUserQuery.data])

  return (
    <Box className="content">
      <BackButton />
      <AlertDialog
        severity={updateUserResult.isSuccess ? 'success' : 'error'}
        setOpen={setOpen}
        open={open}
        errorMessage={
          updateUserResult.isSuccess
            ? 'Пользователь был отредактирован'
            : 'Пользователь не отредактирован'
        }
      />

      <Box className="profile">
        <Box className="profile__container">
          <Box className="profile__container--details">
            <Box className="profile-details">
              <Box className="header">
                <Typography
                  sx={{
                    fontWeight: 500,
                    lineHeight: '32px',
                    visibility: 'hidden',
                  }}
                >
                  f
                </Typography>
              </Box>
              <Box className="info">
                <Box className="bio">
                  <Box
                    sx={{
                      borderRadius: '50%',
                      borderTop: '3px solid #E74A56',
                      borderLeft: '3px solid #E74A56',
                      borderRight: '3px solid transparent',
                      borderBottom: '3px solid #E74A56',
                      transform: 'rotate(45deg)',
                    }}
                  >
                    <Avatar
                      sx={{
                        width: 70,
                        height: 70,
                        transform: 'rotate(-45deg)',
                      }}
                      alt="Avatar"
                      src={`${process.env.REACT_APP_MEDIA_URL}/${getCurrentUserQuery.data?.profilePhoto}`}
                    />
                  </Box>

                  <Typography variant="h4" className="name">
                    {getCurrentUserQuery.data?.firstName}{' '}
                    {getCurrentUserQuery.data?.secondName}{' '}
                    {getCurrentUserQuery.data?.surName}
                  </Typography>
                  <Box className="dob">
                    <Typography variant="h6">
                      Дата рождения:{' '}
                      {getCurrentUserQuery.data?.dob.split('T')[0]}
                    </Typography>
                  </Box>
                </Box>
                {/*<Box className="patients">
                  <Typography className="number" variant="h6">
                    1 256
                  </Typography>
                  <Typography className="text" variant="h6">
                    Принято пациентов
                  </Typography>
                </Box>*/}
                {/*<Box className="work-time">
                  <Typography className="work-time__text" variant="h6">
                    <span>
                      <img src={checkIcon} alt="check" />
                    </span>
                    Время работы: 15:30
                  </Typography>
                </Box>*/}
                <Box className="work-place">
                  <Typography variant="h6" className="header">
                    Данные о месте работы:
                  </Typography>
                  <Box className="work-place__data">
                    <Typography variant="h6">
                      {getCurrentUserQuery.data?.department?.label}
                    </Typography>
                    <Typography variant="h6">
                      {getCurrentUserQuery.data?.specialization?.label}
                    </Typography>
                    <Typography variant="h6">
                      {getCurrentUserQuery.data?.position?.label}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <button onClick={handleLogout} className="logout">
                <span>
                  <img src={logoutIcon} alt="logout" />
                </span>
                Выйти
              </button>
            </Box>

            <Box className="work-details">
              <Box className="header">
                <Typography sx={{ fontWeight: 500, lineHeight: '32px' }}>
                  Мой профиль
                </Typography>
              </Box>

              <Box
                className="info"
                component="form"
                onSubmit={formik.handleSubmit}
              >
                <Box className="personal_data--fields">
                  <Typography className="fields-header" variant="h6">
                    Личные данные
                  </Typography>

                  <Box
                    className="fields__container"
                    sx={{
                      flexDirection: matches ? 'column' : 'row',
                    }}
                  >
                    <Box className="fields__container--field">
                      <CustomTextField
                        error={
                          !!formik.touched.firstName &&
                          !!formik.errors.firstName
                        }
                        value={formik.values.firstName}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        helperText={
                          formik.touched.firstName
                            ? formik.errors.firstName
                            : undefined
                        }
                        name="firstName"
                        placeholder="Введите Имя"
                        label="Имя"
                      />
                    </Box>
                    <Box className="fields__container--field">
                      <CustomTextField
                        error={
                          !!formik.touched.surName && !!formik.errors.surName
                        }
                        value={formik.values.surName}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        helperText={
                          formik.touched.surName
                            ? formik.errors.surName
                            : undefined
                        }
                        name="surName"
                        label="Фамилия"
                        placeholder="Введите Фамилию"
                      />
                    </Box>
                  </Box>
                  <Box
                    className="fields__container"
                    sx={{
                      flexDirection: matches ? 'column' : 'row',
                    }}
                  >
                    <Box className="fields__container--field">
                      <CustomTextField
                        error={
                          !!formik.touched.secondName &&
                          !!formik.errors.secondName
                        }
                        value={formik.values.secondName}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        helperText={
                          formik.touched.secondName
                            ? formik.errors.secondName
                            : undefined
                        }
                        name="secondName"
                        label="Отчество"
                        placeholder="Введите Отчество"
                      />
                    </Box>
                    <Box className="fields__container--field">
                      <CustomTextField
                        error={!!formik.touched.dob && !!formik.errors.dob}
                        value={formik.values.dob}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        helperText={
                          formik.touched.dob ? formik.errors.dob : undefined
                        }
                        name="dob"
                        type="date"
                        label="Дата рождения"
                        placeholder="00.00.0000"
                      />
                    </Box>
                  </Box>
                  <Box
                    className="fields__container"
                    sx={{
                      flexDirection: matches ? 'column' : 'row',
                    }}
                  >
                    <Box className="fields__container--field">
                      <CustomNumericField
                        error={!!formik.touched.phone && !!formik.errors.phone}
                        value={formik.values.phone}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        helperText={
                          formik.touched.phone ? formik.errors.phone : undefined
                        }
                        name="phone"
                        placeholder="Телефон"
                        label="Телефон"
                      />
                    </Box>
                    <Box className="fields__container--field">
                      <CustomTextField
                        error={!!formik.touched.email && !!formik.errors.email}
                        value={formik.values.email}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        helperText={
                          formik.touched.email ? formik.errors.email : undefined
                        }
                        name="email"
                        label="Email"
                        placeholder="Введите email"
                      />
                    </Box>
                  </Box>
                </Box>
                <Box className="work_data--fields">
                  <Typography className="fields-header" variant="h6">
                    Дополнительные данные
                  </Typography>

                  <Box className="additional-information">
                    <Typography className="label">Должность</Typography>
                    {getCurrentUserQuery.data?.position && (
                      <EcoSelect
                        disabled={userType === 'superAdmin'}
                        key="position"
                        defaultValue={getCurrentUserQuery.data?.position}
                        freeSolo={true}
                        helperText={
                          formik.touched.position
                            ? formik.errors.position
                            : undefined
                        }
                        error={
                          !!formik.touched.position && !!formik.errors.position
                        }
                        onChange={(event, newValue) => {
                          formik.setFieldValue(
                            'position',
                            (newValue as any)?._id
                          )
                        }}
                        onBlur={formik.handleBlur}
                        name="position"
                        options={positionData as SelectResponse[]}
                        sx={{}}
                        placeholder="Выберите должность"
                      />
                    )}
                  </Box>
                  <Box className="additional-information">
                    <Typography className="label">Отделение</Typography>
                    {getCurrentUserQuery.data?.department && (
                      <EcoSelect
                        disabled={userType === 'superAdmin'}
                        key={'department'}
                        freeSolo={true}
                        defaultValue={
                          getCurrentUserQuery.data?.department || null
                        }
                        helperText={
                          formik.touched.department
                            ? formik.errors.department
                            : undefined
                        }
                        error={
                          !!formik.touched.department &&
                          !!formik.errors.department
                        }
                        onChange={(event, newValue) => {
                          formik.setFieldValue(
                            'department',
                            (newValue as any)?._id
                          )
                        }}
                        onBlur={formik.handleBlur}
                        name="department"
                        options={departmentData as SelectResponse[]}
                        sx={{}}
                        placeholder="Выберите отделение"
                      />
                    )}
                  </Box>
                  <Box className="additional-information">
                    <Typography className="label">Специализация</Typography>
                    {getCurrentUserQuery.data?.specialization && (
                      <EcoSelect
                        disabled={userType === 'superAdmin'}
                        key={'specialization'}
                        defaultValue={
                          getCurrentUserQuery.data?.specialization || null
                        }
                        freeSolo={true}
                        helperText={
                          formik.touched.specialization
                            ? formik.errors.specialization
                            : undefined
                        }
                        error={
                          !!formik.touched.specialization &&
                          !!formik.errors.specialization
                        }
                        onChange={(event, newValue) => {
                          formik.setFieldValue(
                            'specialization',
                            (newValue as any)?._id
                          )
                        }}
                        onBlur={formik.handleBlur}
                        name="specialization"
                        options={specializationsData as SelectResponse[]}
                        sx={{}}
                        placeholder="Выберите cпециализацию"
                      />
                    )}
                  </Box>
                </Box>
                <Box className="personal_data--buttons">
                  <StyledCancelButton
                    type={'reset'}
                    variant="outlined"
                    className="buttons__cancel"
                  >
                    Отменить
                  </StyledCancelButton>
                  <StyledSaveButton
                    type={'submit'}
                    variant="contained"
                    className="buttons__register"
                  >
                    Сохранить
                  </StyledSaveButton>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
