import { SelectResponse } from '../types'
import { baseApi } from './api'

export const positionsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getPositions: build.query<SelectResponse[], void>({
      query: () => ({ url: 'positions' }),
    }),
  }),
})

export const { useGetPositionsQuery } = positionsApi
