import { Button, styled } from '@mui/material'
import React from 'react'

type Props = {
    children: React.ReactNode
    fullWidth?: boolean
    type?: 'submit' | 'button'
    onClick?: () => void
}

const StyledCustomButton = styled(Button)({
    background: '#E6EDF2',
    border: 'none',
    textTransform: 'none',
    fontWeight: '500',
    padding: '13px, 16px, 13px, 16px',
    height: '48px',
    fontSize: '16px',
    fontFamily: 'Montserrat',
    color: '#7A828A',
    borderRadius: '6px',
    '&:hover': {
        border: 'none',
        color: '#7A828A',
        background: '#E6EDF2',
    },
})

export const CustomPrimaryButton: React.FC<Props> = (props) => {
    const {
        children,
        fullWidth = true,
        onClick: handleClick,
        type = 'button',
    } = props
    return (
        <StyledCustomButton
            onClick={handleClick}
            fullWidth={fullWidth}
            type={type}
        >
            {children}
        </StyledCustomButton>
    )
}
