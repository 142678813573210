import { Patient } from '../types'
import { baseApi } from './api'

export const patientsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    searchPatients: build.query<Patient[], string>({
      query: (searchText) => ({
        url: `patients/search?searchText=${searchText}`,
      }),
    }),
    getPatients: build.query<Patient[], void>({
      query: () => ({
        url: 'patients',
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ _id }) => ({
                type: 'Patient' as const,
                id: _id,
              })),
              { type: 'Patient', id: 'LIST' },
            ]
          : [{ type: 'Patient', id: 'LIST' }],
    }),
    getPatient: build.query<Patient, string>({
      query: (patientId) => ({
        url: `patients/${patientId}`,
      }),
    }),

    registerPatient: build.mutation<any, any>({
      query: (data) => ({ url: 'patients', data, method: 'POST' }),
      invalidatesTags: (result, error, arg, meta) => [
        { type: 'Patient', id: 'LIST' },
      ],
    }),

    deletePatient: build.mutation<void, string>({
      query: (patientId) => {
        return {
          url: `patients/${patientId}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: (result, error, patientId, meta) => [
        { type: 'Patient', id: patientId },
      ],
    }),
  }),
})

export const {
  useSearchPatientsQuery,
  useGetPatientsQuery,
  useGetPatientQuery,
  useRegisterPatientMutation,
  useDeletePatientMutation,
} = patientsApi
