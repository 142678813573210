import dayjs from 'dayjs'

export const getDateValue = (value: Date) =>
  value && dayjs(value).format('YYYY-MM-DD')

export const toDateObject = (date: Date): DateObject => {
  const [day, month, year] = dayjs(date).format('DD.MM.YYYY').split('.')

  return {
    __type: 'DateObject',
    day,
    month,
    year,
  }
}

export const fromDateObject = (date: DateObject) => {
  return dayjs(`${date.day}.${date.month}.${date.year}`, 'DD.MM.YYYY').toDate()
}

export interface DateObject {
  __type: 'DateObject'
  day: string
  month: string
  year: string
}

export const setDateObject = (obj: any) => {
  if (obj && typeof obj === 'object') {
    for (const key in obj) {
      const prop = obj[key]

      if (prop instanceof Date) {
        // @ts-ignore
        obj[key] = toDateObject(prop)
      } else if (Array.isArray(prop)) {
        for (let i = 0; i < prop.length; i++) {
          const item = prop[i]

          if (item instanceof Date) {
            prop[i] = toDateObject(item)
          } else {
            setDateObject(item)
          }
        }
      } else {
        setDateObject(prop)
      }
    }
  }
}

export const unsetDateObject = (obj: any) => {
  if (obj && typeof obj === 'object') {
    for (const key in obj) {
      const prop = obj[key]

      if (prop && prop.__type === 'DateObject') {
        // @ts-ignore
        obj[key] = fromDateObject(prop)
      } else if (Array.isArray(prop)) {
        for (let i = 0; i < prop.length; i++) {
          const item = prop[i]

          if (item && item.__type === 'DateObject') {
            prop[i] = fromDateObject(item)
          } else {
            unsetDateObject(item)
          }
        }
      } else {
        unsetDateObject(prop)
      }
    }
  }
}
