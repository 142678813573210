import React, { ChangeEventHandler, FocusEventHandler } from 'react'
import { TextField } from '@mui/material'

type Props = {
    id: string
    name: string
    value: string
    type?: string
    required?: boolean
    error: boolean
    onChange?: ChangeEventHandler
    onBlur?: FocusEventHandler
    helperText?: string
}

export const LoginTextField: React.FC<Props> = (props) => {
    const {
        type = 'text',
        name,
        id,
        error,
        value,
        onChange,
        onBlur,
        helperText,
    } = props

    return (
        <TextField
            helperText={helperText}
            onBlur={onBlur}
            onChange={onChange}
            value={value}
            error={error}
            size="small"
            sx={{
                border: 'none',
                borderRadius: '5px',
                color: '#000',
                '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                        borderRadius: '5px',
                    },
                    '&.Mui-focused fieldset': {
                        border: '1px solid #E8E8E8',
                    },
                },
            }}
            type={type}
            className="form__textField"
            id={id}
            name={name}
            fullWidth
        />
    )
}
