import { passwordRestoreSchema } from '../../validationSchemas'
import { LoginTextField } from '../ui'
import { Box, Button, FormLabel, Typography } from '@mui/material'
import { Formik, FormikHelpers, FormikValues } from 'formik'
import React from 'react'
import { Link } from 'react-router-dom'

type Props = {
  handlePasswordReset: (values: {
    passwordRestoreEmail: string
  }) => Promise<void>
  setForgotPassword: React.Dispatch<React.SetStateAction<boolean>>
}

const initialValues = {
  passwordRestoreEmail: '',
}

export const PasswordRestoreFormik: React.FC<Props> = (props) => {
  const { handlePasswordReset, setForgotPassword } = props
  const goToLogin = () => {
    setForgotPassword(false)
  }

  return (
    <Formik
      enableReinitialize
      onSubmit={handlePasswordReset}
      initialValues={initialValues}
      validationSchema={passwordRestoreSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
      }) => (
        <>
          <Typography
            sx={{ fontWeight: 700, fontSize: '30px' }}
            className="form__header"
            variant="h4"
          >
            Востановление пароля
          </Typography>
          <form onSubmit={handleSubmit} className="form__login-form">
            <Box>
              <FormLabel
                className="form__label"
                form="form__label"
                htmlFor="email"
              >
                Введите email
              </FormLabel>
              <LoginTextField
                onChange={handleChange}
                error={
                  !!touched.passwordRestoreEmail &&
                  !!errors.passwordRestoreEmail
                }
                value={values.passwordRestoreEmail}
                onBlur={handleBlur}
                id="passwordRestoreEmail"
                name="passwordRestoreEmail"
                type="email"
                helperText={
                  touched.passwordRestoreEmail
                    ? errors.passwordRestoreEmail
                    : undefined
                }
              />
            </Box>

            <Box
              sx={{
                marginTop: '18px',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Box className="login-actions">
                <label className="remember" htmlFor="remember">
                  На почту придет новый пароль.
                </label>
              </Box>
              <Box className="password-restore__link">
                <Link onClick={goToLogin} style={{ color: '#2C5282' }} to="#">
                  Войти
                </Link>
              </Box>
            </Box>
            <Box
              sx={{
                marginTop: '28px',
              }}
            >
              <Button
                type="submit"
                sx={{
                  background: '#04C45C',
                  padding: '8px',
                  '&:hover': {
                    background: '#04C45C',
                  },
                }}
                fullWidth
                variant="contained"
              >
                Отправить
              </Button>
            </Box>
          </form>
        </>
      )}
    </Formik>
  )
}
