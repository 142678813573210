import { baseApi } from './api'

export const citiesApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    searchCities: build.query<
      { title: string; titleEn?: string }[],
      string | undefined
    >({
      query: (searchText) => {
        let url
        if (searchText) {
          url = `cities?searchText=${searchText}`
        } else {
          url = 'cities'
        }
        return { url }
      },
    }),
  }),
})

export const { useSearchCitiesQuery } = citiesApi
