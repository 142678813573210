import { createOptions } from '../lib/createOptions'

export * from './cipherMkb10'

export const terrainOptions = createOptions(['городская', 'сельская'])

export const paymentTypeOptions = createOptions([
  'обязательное медицинское страхование',
  'средства бюджета',
  'платные медицинские услуг',
  'другое',
])

export const identityDocumentOptions = createOptions([
  'Паспорт',
  'Национальный ID',
  'Cвидетельство о рождении',
  'военный билет',
])

export const genderOptions = createOptions(['Мужской', 'Женский'])

export const socialStatusOptions = createOptions([
  'дошкольник',
  'организован',
  'неорганизован',
  'учащийся',
  'работает',
  'не работает',
  'БОМЖ',
  'пенсионер',
  'военнослужащий',
  'член семьи военнослужащего',
])

export const benefitCategoryOptions = createOptions([
  'инвалид ВОВ',
  'участник ВОВ',
  'воин-интернационалист',
  'лицо, подвергшееся радиационному облучению',
  'в т.ч. в Чернобыле',
  'инв. I гр.',
  'инв. II гр.',
  'инв. III гр.',
  'ребенок-инвалид',
  'инвалид с детства',
  'прочие',
])

export const deliveredWhileIntoxicatedOptions = createOptions([
  'Алкогольного',
  'Наркотического',
])

export const hospitalizedForSameDiseaseOptions = createOptions([
  'первично',
  'повторно',
  'по экстренным показаниям',
  'в плановом порядке',
])

export const deliveredFromAfterOptions = createOptions([
  'в первые 6 часов',
  'в теч. 7—24 часов',
  'позднее 24-х часов',
])

export const injuryOptions = createOptions([
  'производственная',
  'промышленная',
  'транспортная',
  'в т. ч. ДТП',
  'с/хоз',
  'прочие',
  'непроизводственная: бытовая',
  'уличная',
  'транспортная',
  'в т. ч. ДТП',
  'школьная',
  'спортивная',
  'противоправная травма',
  'прочие',
])

export const outcomeOfHospitalizationOptions = createOptions([
  'выписан',
  'в т. ч. в дневной стационар',
  'в круглосуточный стационар',
  'переведен в другой стационар',
])

export const resultOfHospitalizationOptions = createOptions([
  'выздоровление',
  'улучшение',
  'без перемен',
  'ухудшение',
  'здоров',
  'умер',
])
