import { Alert, Portal, Snackbar } from '@mui/material'
import React, { useState } from 'react'

type Props = {
  message: string
  severity: 'error' | 'success'
  onClose?: () => void
}

export const AlertDialog: React.FC<Props> = (props) => {
  const { message, severity, onClose } = props

  const [open, setOpen] = useState(true)

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
    onClose && severity === 'success' && onClose()
  }

  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    >
      <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  )
}
