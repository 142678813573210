import { AlertDialog } from '../../components/shared'
// import Step1 from './Step1/Step1'
// import Step2 from './Step2/Step2'
// import Step3 from './Step3/Step3'
import styles from './styles.module.scss'
import { Box, Typography } from '@mui/material'
import clsx from 'clsx'
import { BackButton, CustomButton } from 'components'
//import { Steps } from 'antd'
import patientInformation from 'documents/patientInformation.docx'
import patientMedicalConsent from 'documents/patientMedicalConsent.docx'
import personalDataConsent from 'documents/personalDataConsent.docx'
import { DocumentForm, applyRules } from 'features/documents'
import { isSuccessResult } from 'lib/isSuccessResult'
import merge from 'lodash/merge'
import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRegisterPatientMutation } from 'redux/services'

//import { Patient } from 'redux/types'

//const { Step } = Steps

const PatientRegistrationPage: React.FC = () => {
  const [currentStep, setCurrentStep] = React.useState(0)
  const [open, setOpen] = React.useState(false)
  const navigate = useNavigate()

  const defaultValues = useMemo(
    () =>
      merge(
        {},
        {
          patientInformation: patientInformation.data,
          patientMedicalConsent: patientMedicalConsent.data,
          personalDataConsent: personalDataConsent.data,
        }
      ),
    []
  )

  const [formData, setFormData] = React.useState<any>(defaultValues)

  const steps = [patientInformation, patientMedicalConsent, personalDataConsent]
  const doc = steps[currentStep]

  const namespaces = [
    'patientInformation',
    'patientMedicalConsent',
    'personalDataConsent',
  ]
  const ns = namespaces[currentStep]

  const handleAfterClose = () => {
    navigate('/patients')
  }

  const [registerPatient, { data: patientData, error, isSuccess }] =
    useRegisterPatientMutation()

  /*const registerNewPatient = (
        values: Omit<
            Patient,
            | 'fio'
            | 'doctorFio'
            | 'sickNess'
            | 'plannedVisitDate'
            | 'plannedVisitTime'
            | 'cardNumber'
            | '_id'
        >
    ) => {
        registerPatient(values).then(() => setOpen(true))
    }*/

  const handleNext = (data: any) => {
    applyRules(data, doc.steps[0].rules)

    const nsData: Record<string, any> = {}
    nsData[ns] = data

    switch (ns) {
      case 'patientInformation': {
        const {
          firstName,
          lastName,
          surName,
          dateOfBirth,
          location,
          stayLocation,
          identityDocument,
          residesAtSamePlace,
          phone,
        } = data

        nsData['patientMedicalConsent'] = {
          firstName,
          lastName,
          surName,
          dateOfBirth,
          location,
          stayLocation,
          identityDocument,
          residesAtSamePlace,
          phone,
        }

        nsData['personalDataConsent'] = {
          identityDocument,
        }

        break
      }
      case 'patientMedicalConsent': {
        const {
          firstName,
          lastName,
          surName,
          dateOfBirth,
          location,
          stayLocation,
          residesAtSamePlace,
          phone,
          representative,
          hasRepresentative,
        } = data

        nsData['personalDataConsent'] = {
          firstName,
          lastName,
          surName,
          dateOfBirth,
          location,
          stayLocation,
          residesAtSamePlace,
          phone,
          representative,
          hasRepresentative,
        }

        break
      }
    }

    const newData = merge({}, formData, nsData)
    setFormData(newData)

    if (currentStep !== steps.length - 1) {
      setCurrentStep((prevStep) => prevStep + 1)
    } else {
      const {
        firstName,
        lastName,
        surName,
        snilsNumber,
        paymentType,
        healthInsurance,
        dateOfBirth,
        phone,
      } = newData.patientInformation

      const { location, stayLocation, residesAtSamePlace } =
        newData.patientMedicalConsent

      const { identityDocument, representative, hasRepresentative } =
        newData.personalDataConsent

      registerPatient({
        patient: {
          firstName,
          lastName,
          surName,
          snilsNumber,
          paymentType,
          healthInsurance,
          location,
          stayLocation,
          residesAtSamePlace,
          dateOfBirth,
          identityDocument,
          phone,
          representative,
          hasRepresentative,
        },
        ...newData,
      }).then((result) => {
        //console.log('result', result)
        if (isSuccessResult(result))
          navigate(`/appointments/${result.data.patient._id}`)
      })
    }
  }

  const handleBack = () => {
    if (currentStep) setCurrentStep(currentStep - 1)
  }

  //const Step = steps[currentStep]

  console.log('formData', formData)

  return (
    <Box className={clsx('content', styles.registrationPage)}>
      <BackButton />
      <AlertDialog
        handleAfterClose={handleAfterClose}
        severity={isSuccess ? 'success' : 'error'}
        setOpen={setOpen}
        open={open}
        errorMessage={
          isSuccess ? 'Новый пациент создан' : 'Новый пациент не создан'
        }
      />
      <Box className={styles.patientRegistration}>
        {/* <Steps current={currentStep}> */}
        {/* {steps.map((step, index) => (
                    <Step key={index} title={step.title} />
                ))} */}
        {/* </Steps> */}
        <Box className={styles['patientRegistration__container']}>
          <Box className={clsx(styles['page-title'])}>
            <Typography variant="h6">Регистрация пациента</Typography>
          </Box>
          {doc.title && (
            <Box className={clsx(styles['step-title'])}>
              <Typography variant="h6">{doc.title}</Typography>
            </Box>
          )}
          {/*<Step onNext={handleNext} formData={formData} onBack={handleBack} />*/}
          <DocumentForm
            key={currentStep}
            onSubmit={handleNext}
            fields={doc.steps[0].fields}
            defaultValues={formData[ns]}
            validationSchema={doc.steps[0].validationSchema}
          >
            <Box className={styles['actions']}>
              {/* <StyledCustomButton
                        fullWidth={true}
                        type={'submit'}
                        variant="outlined"
                        className="actions__button"
                        onClick={() => {
                            console.log('values', getValues())
                        }}
                    >
                        Регистрация
                    </StyledCustomButton>
                    <button onClick={onNext}>Следующий шаг</button> */}
              <Box className={styles['notfound']}>
                <Box component="div">
                  <CustomButton
                    type={'button'}
                    className={styles['actions__button']}
                    onClick={handleBack}
                    disabled={!currentStep}
                  >
                    Предыдущий шаг
                  </CustomButton>
                </Box>
                <Box component="div">
                  <CustomButton
                    type={'submit'}
                    className={styles['actions__button']}
                  >
                    {currentStep === steps.length - 1
                      ? 'Завершить'
                      : 'Следующий шаг'}
                  </CustomButton>
                </Box>
              </Box>
            </Box>
          </DocumentForm>
        </Box>
      </Box>
    </Box>
  )
}

export default PatientRegistrationPage
