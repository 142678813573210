import { BackButton, ECOTable } from '../../components'
import {
  useDeletePatientMutation,
  useGetPatientsQuery,
} from '../../redux/services'
import deleteIcon from '../../svgs/deleteIcon.svg'
import './patients.styles.scss'
import { SearchOutlined } from '@ant-design/icons'
import { ExclamationCircleFilled } from '@ant-design/icons'
import { Box, Typography } from '@mui/material'
import { Button, Input, Modal, Space } from 'antd'
import type { InputRef } from 'antd'
import { ColumnsType, ColumnType } from 'antd/es/table'
//import infoIcon from '../../svgs/inforIcon.svg'
import dayjs from 'dayjs'
import React from 'react'
import Highlighter from 'react-highlight-words'

interface DataType {
  _id?: string
  key: string
  cardNumber: string
  fio: string
  sickNess: string
  doctor: string
  phone: string
  lastVisitDate: string
  nextVisitDate?: string
  actions: string
}

type DataIndex = keyof DataType

export const PatientsPage: React.FC = () => {
  const { data } = useGetPatientsQuery()

  const [deletePatient] = useDeletePatientMutation()
  const [selectedRowKeys, setSelectedRowKeys] = React.useState<string[]>([])
  const searchInput = React.useRef<InputRef>(null)
  const [searchText, setSearchText] = React.useState('')
  const [searchedColumn, setSearchedColumn] = React.useState('')

  const { confirm } = Modal

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: { closeDropdown: boolean }) => void,
    dataIndex: DataIndex
  ) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = (clearFilters: () => void) => {
    clearFilters()
    setSearchText('')
  }

  const showDeleteConfirm = (id: string) => {
    confirm({
      title: 'Вы уверены, что удалили этого пациента?',
      icon: <ExclamationCircleFilled />,
      content: 'Все данные пациента будут удалены',
      okText: 'Да',
      okType: 'danger',
      cancelText: 'Отменить',
      onOk() {
        deletePatient(id)
      },
      onCancel() {
        return
      },
    })
  }

  const getColumnSearchProps = (
    dataIndex: DataIndex
  ): ColumnType<DataType> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={'Поиск по карточке пациента'}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Поиск
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Сбросить
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false })
              setSearchText((selectedKeys as string[])[0])
              setSearchedColumn(dataIndex)
            }}
          >
            фильтровать
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close()
            }}
          >
            Закрыть
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
    ),

    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })

  const columns: ColumnsType<DataType> = [
    {
      title: '№ пациента',
      dataIndex: 'cardNumber',
      render: (text: string) => <a>{text}</a>,
      filterSearch: true,
      onFilter: (value, record) => record.cardNumber.includes(value as string),
      ...getColumnSearchProps('cardNumber'),
    },
    {
      title: 'ФИО пациента',
      dataIndex: 'fio',
    },

    /*{
      title: 'Лечащий врач',
      dataIndex: 'doctor',
      render: (_, record) => <span>Иванов Иван Иванович</span>,
    },*/

    {
      title: 'Номер телефон',
      dataIndex: 'phone',
    },
    {
      title: 'Дата регистрации',
      dataIndex: 'createdAt',
      render: (_, record) => (
        // @ts-ignore
        <span>{dayjs(record.createdAt).format('DD.MM.YYYY')}</span>
      ),
    },
    {
      title: 'Функционал',
      dataIndex: 'actions',
      render: (_, record) => (
        <Space>
          {/*<Box className="action">
            <img src={infoIcon} alt="deleteIcon" />
          </Box>*/}
          <Box
            className="action"
            onClick={() => showDeleteConfirm(record._id as string)}
          >
            <img src={deleteIcon} alt="deleteIcon" />
          </Box>
        </Space>
      ),
    },
  ]

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        'selectedRows: ',
        selectedRows
      )
    },
  }

  return (
    <Box className="content">
      <BackButton />
      <Box className="patients">
        <Box className="patients__container">
          <Typography variant="h6" sx={{ textTransform: 'uppercase' }}>
            Общий список пациентов
          </Typography>

          <Box className="table" sx={{ width: '90%' }}>
            {data && (
              <ECOTable
                data={[
                  ...data.map((record) => ({
                    ...record,
                    key: record._id as React.Key,
                  })),
                ]}
                columns={columns}
                rowSelection={rowSelection}
              />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
