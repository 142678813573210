import { BackButton, CustomTextField, EcoSelect } from '../../components'
import { AlertDialog } from '../../components/shared'
import { useGetEmployeesQuery, useGetPatientQuery } from '../../redux/services'
import { useCreateEventMutation } from '../../redux/services/events'
import { EventType, Patient, SelectResponse, User } from '../../redux/types'
import appointmentSheet from '../../svgs/appointmentSheet.svg'
import doctorVisitIcon from '../../svgs/doctorVisitIcon.svg'
import treatmentCardPhysiotherapyRoomIcon from '../../svgs/treatmentCardPhysiotherapyRoom.svg'
import { PatientCardValidationSchema } from '../../validationSchemas'
import editIcon from './../../svgs/editIcon.svg'
import exerciseTherapistIcon from './../../svgs/exerciseTherapistIcon.svg'
import menuIcon from './../../svgs/menuIcon.svg'
import neuropsychologiesConclusionIcon from './../../svgs/neuropsychologisConclutiontIcon.svg'
import orthopedicIcon from './../../svgs/othopendicIcon.svg'
import patientCardPhysiotherapyDept from './../../svgs/patientCardPhysiotherapyDept.svg'
import psychologistConsultationIcon from './../../svgs/psycologistConsultationIcon.svg'
import referralIcon from './../../svgs/refferalIcon.svg'
import specialistIcon from './../../svgs/specialistIcon.svg'
import stageIcon from './../../svgs/stageIcon.svg'
import staticDropCard from './../../svgs/staticDropCard.svg'
import therapistConsultationIcon from './../../svgs/therapistConsultationIcon.svg'
import './patient.styles.scss'
import { Box, Button, styled, Typography } from '@mui/material'
import { List } from 'antd'
import { useFormik } from 'formik'
import moment from 'moment'
import React from 'react'
import { useParams } from 'react-router-dom'

const data = [
  {
    title: 'Заполнить мед карту',
    icon: editIcon,
  },
  {
    title: 'Заполнить первичный осмотр',
    icon: menuIcon,
  },
  {
    title: 'Заполнить лист назначений',
    icon: appointmentSheet,
  },
  {
    title: 'Заполнить этапный эпикриз',
    icon: stageIcon,
  },
  {
    title: 'Заполнить осмотр лечащим врачом',
    icon: doctorVisitIcon,
  },
  {
    title: 'Заполнить первичный осмотр врача специалиста',
    icon: specialistIcon,
  },
  {
    title: 'Заполнить статическую карту выбывшего',
    icon: staticDropCard,
  },
  {
    title: 'Заполнить осмотр врача физиотерапевта',
    icon: therapistConsultationIcon,
  },
  {
    title: 'Заполнить осмотра по ЛФК',
    icon: exerciseTherapistIcon,
  },
  {
    title: 'Заполнить карту больного физиотерапевтическом отделении',
    icon: patientCardPhysiotherapyDept,
  },
  {
    title: 'Заполнить карту лечения в кабинете лечебной физкультуры',
    icon: treatmentCardPhysiotherapyRoomIcon,
  },
  {
    title: 'Заполнить консультацию ортопеда',
    icon: orthopedicIcon,
  },
  {
    title: 'Заполнить консультацию психолога',
    icon: psychologistConsultationIcon,
  },
  {
    title: 'Заполнить заключение нейропсихолога',
    icon: neuropsychologiesConclusionIcon,
  },
  {
    title: 'Заполнить консультацию логопеда',
    icon: therapistConsultationIcon,
  },
  {
    title: 'Заполнить направление',
    icon: referralIcon,
  },
]

const initialValues = {
  age: 0,
  fio: '',
  sickNess: '',
  lastVisitDate: '',
  plannedVisitDate: '',
  plannedVisitTimeEnd: '',
  doctorFio: '',
}

const StyledCustomButton = styled(Button)({
  color: '#1E71EA',
  background: '#F9FAFB',
  border: 'none',
  textTransform: 'capitalize',
  fontWeight: '500',
  padding: '13px, 16px, 13px, 16px',
  height: '50px',
  width: '160px',
  size: '16px',
  '&:hover': {
    border: 'none',
    color: '#2E7BEB',
  },
})

export const PatientPage: React.FC = () => {
  const [open, setOpen] = React.useState(false)

  const { id } = useParams()

  const { data: patientData } = useGetPatientQuery(id as string, {
    skip: !id,
  })

  const { data: employeesData } = useGetEmployeesQuery()

  const [createEvent, { isLoading, isSuccess, error }] =
    useCreateEventMutation()

  function getAGeInYears(dob: string) {
    const birthDate = moment(dob)
    const today = moment()

    return today.diff(birthDate, 'year')
  }

  React.useEffect(() => {
    if (patientData) {
      const patientAge = getAGeInYears(patientData.dob)

      formik.setFieldValue('fio', patientData.fio)
      formik.setFieldValue('age', patientAge)
      formik.setFieldValue(
        'lastVisitDate',
        patientData.lastVisitDate?.split('T')[0]
      )
    }
  }, [patientData])

  const updatePatientInfo = (
    values: Pick<
      Patient,
      'sickNess' | 'doctorFio' | 'lastVisitDate' | 'plannedVisitDate'
    > & { age: number; plannedVisitTimeEnd: string }
  ) => {
    createEvent({
      startStr: values.plannedVisitDate,
      endStr: values.plannedVisitTimeEnd,
      type: EventType.appointment,
      assignedTo: values.doctorFio,
      patient: id,
      start: values.plannedVisitDate?.split('T')[0],
      end: values.plannedVisitTimeEnd?.split('T')[0],
      sickNess: values.sickNess,
    })

    setOpen(true)
  }

  const formik = useFormik({
    initialValues,
    validationSchema: PatientCardValidationSchema,
    onSubmit: updatePatientInfo,
  })

  //FIXME: fix after close
  const handleAfterClose = () => {
    //formik.setValues(initialValues)
  }

  return (
    <Box className="content">
      <BackButton />
      <AlertDialog
        handleAfterClose={handleAfterClose}
        severity={isSuccess ? 'success' : 'error'}
        setOpen={setOpen}
        open={open}
        errorMessage={
          isSuccess ? 'Новый приём создан' : 'Ошибка! приём не создан'
        }
      />
      <Box className="patient">
        <Box className={'patient__actions'}>
          <List
            itemLayout="horizontal"
            dataSource={data}
            renderItem={(item, index) => (
              <List.Item
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  marginBottom: '5px',
                }}
              >
                <img
                  style={{
                    marginRight: '12px',
                  }}
                  src={item.icon}
                  alt="icon"
                />
                <span className="listItemText">{item.title}</span>
              </List.Item>
            )}
          />
        </Box>
        <Box className="patient__container">
          <Box className="patient__container--title">
            <Typography variant="h6">Карточка пациента</Typography>
          </Box>
          <Box
            onSubmit={formik.handleSubmit}
            className="patient__container--card"
            component={'form'}
          >
            <Box>
              <CustomTextField
                name="fio"
                label="ФИО пациента"
                placeholder="Введите ФИО пациента"
                onBlur={formik.handleBlur}
                error={!!formik.touched.fio && !!formik.errors.fio}
                helperText={formik.touched.fio ? formik.errors.fio : undefined}
                value={formik.values.fio}
              />
            </Box>
            <Box>
              <CustomTextField
                type="number"
                name="age"
                label="Возраст"
                placeholder="Возраст"
                error={false}
                helperText={undefined}
                value={String(formik.values.age)}
              />
            </Box>
            <Box>
              <CustomTextField
                onChange={formik.handleChange}
                name="sickNess"
                label="Основное заболевание"
                placeholder="заболевание"
                value={formik.values.sickNess}
                onBlur={formik.handleBlur}
                error={!!formik.touched.sickNess && !!formik.errors.sickNess}
                helperText={
                  formik.touched.sickNess ? formik.errors.sickNess : undefined
                }
              />
            </Box>
            <Box>
              <CustomTextField
                type={'date'}
                name="lastVisitDate"
                label="Дата последнего визита"
                placeholder="Введите введите дату последнего  визита"
                error={
                  !!formik.touched.lastVisitDate &&
                  !!formik.errors.lastVisitDate
                }
                helperText={
                  formik.touched.lastVisitDate
                    ? formik.errors.lastVisitDate
                    : undefined
                }
                value={formik.values.lastVisitDate}
              />
            </Box>
            <Box>
              <CustomTextField
                onChange={formik.handleChange}
                type={'datetime-local'}
                name="plannedVisitDate"
                label="Дата планируемого визита с"
                placeholder="Введите Дата визита"
                error={
                  !!formik.touched.plannedVisitDate &&
                  !!formik.errors.plannedVisitDate
                }
                helperText={
                  formik.touched.plannedVisitDate
                    ? formik.errors.plannedVisitDate
                    : undefined
                }
                value={formik.values.plannedVisitDate}
              />
            </Box>

            <Box>
              <CustomTextField
                onChange={formik.handleChange}
                type="datetime-local"
                name="plannedVisitTimeEnd"
                label="Дата планируемого визита до"
                placeholder="Введите время визита до"
                error={
                  !!formik.touched.plannedVisitTimeEnd &&
                  !!formik.errors.plannedVisitTimeEnd
                }
                helperText={
                  formik.touched.plannedVisitTimeEnd
                    ? formik.errors.plannedVisitTimeEnd
                    : undefined
                }
                value={formik.values.plannedVisitTimeEnd}
              />
            </Box>
            <Box>
              <EcoSelect
                onBlur={formik.handleBlur}
                helperText={
                  formik.touched.doctorFio ? formik.errors.doctorFio : undefined
                }
                error={!!formik.touched.doctorFio && !!formik.errors.doctorFio}
                key="doctorFio"
                freeSolo={true}
                onChange={(event, newValue) => {
                  formik.setFieldValue('doctorFio', (newValue as any)?._id)
                }}
                name="doctorFio"
                options={
                  employeesData
                    ? (employeesData.map((employee) => ({
                        _id: employee._id,
                        label: `${employee.surName} ${employee.secondName} ${employee.firstName}`,
                        value: employee._id,
                      })) as SelectResponse[])
                    : []
                }
                sx={{ width: '100%' }}
                placeholder="Введите ФИО врача"
              />
            </Box>
            <Box className="actions">
              <StyledCustomButton
                type={'submit'}
                variant="outlined"
                className="actions__button"
              >
                Записаться на приём
              </StyledCustomButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
