import { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import days from 'dayjs'

export type SelectResponse = {
  _id: string
  label: string
  value: string
}

export type User = {
  _id?: string
  firstName: string
  secondName: string
  surName: string
  profilePhoto?: string
  email: string
  workTimeStart?: Date
  workTimeEnd?: Date
  workingDays?: number[]
  specialization: SelectResponse | null
  systemRole: SelectResponse | null
  department: SelectResponse | null
  position: SelectResponse | null
  phone: string
  dob: string
}

export type Patient = {
  _id: string
  firstName: string
  secondName: string
  surName: string
  dob: string
  fio: string
  sickNess: string
  lastVisitDate: string
  plannedVisitDate: string
  plannedVisitTime: string
  doctorFio: string
  cardNumber: string
  gender: string
  identityDocument: string
  serialNumber: string
  identityDocumentNumber: string
  identityDocumentIssueDate: string
  identityDocumentIssuingAuthority: string
  snilsNumber: string
  citizenship: string
  registrationPlace: string
  oblast: string
  city: string
  street: string
  building: string
  housing: string
  apartment: string
  email: string
  phone: string
  locality: string
  education: string
  employment: string
  position: string
  maritalStatus: string
  comment?: string
  workPlace?: string
  radiationExposure: string
  insurance?: string
  insuranceDate?: string
  insuranceInfo?: string
  medicalDocumentation?: string
  paymentMethod?: string
}

export type RTKError = FetchBaseQueryError

export enum EventType {
  appointment = 'appointment',
  procedure = 'procedure',
  unavailability = 'unavailability',
}

export type EcoEvent = {
  title?: string
  start?: string
  startStr: string
  end?: string
  endStr: string
  color?: string
  textColor?: string
  type: EventType
  _id?: string
  patient?: string
  assignedTo?: string
  comment?: string
  createdBy?: string
  sickNess?: string
}

export type EcoEventCreateProps = {
  startStr: days.Dayjs
  endStr: days.Dayjs
  type: EventType
  patient: string
  assignedTo: string
  comment: string
}

export type ECONotification = {
  from: string
  to: string
  title: string
  createdAt: string
}
