import { styled, TextField, TextFieldProps } from '@mui/material'
import React from 'react'
import { IMaskInput } from 'react-imask'

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
  value?: string
}

const StyledCustomTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputLabel-filled': {
    color: '#7A828A !important',
    fontSize: '12px',
    fontWeight: '500',
    letterSpacing: '-0.005em',
  },

  '& .MuiFilledInput-root': {
    border: '1px solid #E6EDF2',
    borderRadius: '8px',
    backgroundColor: '#fff',
    '&::after': {
      borderBottom: 'none',
    },
    '&::before': {
      borderBottom: 'none',
    },

    '&.Mui-focused': {
      backgroundColor: '#fff',
      '&::before': {
        content: 'none',
        visibility: 'hidden',
      },
    },
  },
}))

type Props = {
  label: string
  placeholder: string
  type?: string
  name: string
  error: boolean
  value?: string | Date
  defaultValue?: string | Date
  helperText: string | undefined
  onBlur?: (event: React.FocusEvent<HTMLElement>) => void
  onChange?: TextFieldProps['onChange']
  ref?: React.Ref<any>
  disabled?: boolean
  multiline?: boolean
}
type RefType = HTMLDivElement
export const CustomTextField = React.forwardRef<RefType, Props>(
  (props, ref) => {
    const {
      label,
      placeholder,
      name,
      onBlur: handleBlur,
      type = 'text',
      onChange: handleChange,
      value,
      helperText,
      error,
      disabled = false,
      defaultValue,
      multiline,
    } = props
    return (
      <StyledCustomTextField
        disabled={disabled}
        ref={ref}
        error={error}
        helperText={helperText}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        name={name}
        type={type}
        size="small"
        multiline={multiline}
        focused
        variant="filled"
        fullWidth
        label={label}
        placeholder={placeholder}
        defaultValue={defaultValue}
      />
    )
  }
)

const TextMaskCustom = React.forwardRef<HTMLElement, CustomProps>(
  function TextMaskCustom(props, ref) {
    const { onChange, name, ...other } = props

    return (
      <IMaskInput
        name={name}
        {...other}
        mask="+7 (###) ###-##-##"
        definitions={{
          // @ts-ignore
          '#': /[0-9]/,
        }}
        // @ts-ignore
        inputRef={ref}
        onAccept={(value: any) => {
          return onChange({ target: { name: props.name, value } })
        }}
        overwrite
      />
    )
  }
)
const TextMaskCustomSnils = React.forwardRef<HTMLElement, CustomProps>(
  function TextMaskCustom(props, ref) {
    const { onChange, name, ...other } = props

    return (
      <IMaskInput
        name={name}
        {...other}
        mask="###-###-###-##"
        definitions={{
          // @ts-ignore
          '#': /[0-9]/,
        }}
        // @ts-ignore
        inputRef={ref}
        onAccept={(value: any) => {
          return onChange({ target: { name: props.name, value } })
        }}
        overwrite
      />
    )
  }
)

export const CustomNumericField: React.FC<Props> = (props) => {
  const {
    label,
    placeholder,
    type = 'text',
    value,
    error,
    helperText,
    name,
    onChange: handleChange,
  } = props
  return (
    <StyledCustomTextField
      onChange={handleChange}
      name={name}
      helperText={helperText}
      error={error}
      value={value}
      type={type}
      size="small"
      focused
      variant="filled"
      fullWidth
      label={label}
      placeholder={placeholder}
      InputProps={{
        inputComponent: TextMaskCustom as any,
      }}
    />
  )
}

export const CustomSnilsField: React.FC<Props> = (props) => {
  const {
    label,
    placeholder,
    type = 'text',
    value,
    error,
    helperText,
    name,
    onChange: handleChange,
  } = props
  return (
    <StyledCustomTextField
      onChange={handleChange}
      name={name}
      helperText={helperText}
      error={error}
      value={value}
      type={type}
      size="small"
      focused
      variant="filled"
      fullWidth
      label={label}
      placeholder={placeholder}
      InputProps={{
        inputComponent: TextMaskCustomSnils as any,
        value: value,
      }}
    />
  )
}
