import { BackButton, EcoCalender } from '../../components'
import { useGetEventsQuery } from '../../redux/services/events'
import { EcoEvent, EventType } from '../../redux/types'
import './calender.styles.scss'
import { Box, Typography } from '@mui/material'
import moment from 'moment'
import React, { useMemo } from 'react'

export const setTitle = (passedEvent: EcoEvent) => {
    if (passedEvent.type === EventType.appointment) {
        return `Приём ${moment(passedEvent.startStr).format(
            'HH:mm'
        )} - ${moment(passedEvent.endStr).format('HH:mm')}`
    } else if (passedEvent.type === EventType.procedure) {
        return `Процедура ${moment(passedEvent.startStr).format(
            'HH:mm'
        )} - ${moment(passedEvent.endStr).format('HH:mm')}`
    } else {
        return `Недоступен ${moment(passedEvent.startStr).format(
            'HH:mm'
        )} - ${moment(passedEvent.endStr).format('HH:mm')}`
    }
}

export const CalenderPage: React.FC = () => {
    const [filters, setFilters] = React.useState({ assignedTo: '', type: '' })
    const { data: eventsData } = useGetEventsQuery(filters)

    const initialEvents = useMemo<EcoEvent[]>(() => {
        if (!eventsData) return []

        return eventsData.map((currentEvent) => ({
            title: setTitle(currentEvent),
            start: currentEvent.start,
            startStr: currentEvent.startStr,
            end: currentEvent.end,
            endStr: currentEvent.endStr,
            color:
                currentEvent.type !== EventType.unavailability
                    ? '#E2F5FA'
                    : '#E74A56',
            textColor:
                currentEvent.type !== EventType.unavailability
                    ? '#000000'
                    : '#fff',
            comment: currentEvent.comment,
            patient: currentEvent.patient,
            assignedTo: currentEvent.assignedTo,
            type: currentEvent.type,
            _id: currentEvent._id,
            createdBy: currentEvent.createdBy,
        }))
    }, [eventsData])

    return (
        <Box className="content">
            <BackButton />
            <Box className="calender">
                <Box className="calender__container" sx={{ flex: '1' }}>
                    <Box className="calender__container--title">
                        <Typography variant="h6">Календарь приёмов</Typography>
                    </Box>
                    {initialEvents && (
                        <EcoCalender
                            filters={filters}
                            events={initialEvents}
                            setFilters={setFilters}
                        />
                    )}
                </Box>
            </Box>
        </Box>
    )
}
