import * as React from 'react'

import { Menu, MenuItem, styled } from '@mui/material'
import { logout } from '../../helpers/logout'
import { useSession } from '../../context/session.context'
import { useNavigate } from 'react-router-dom'

type MenuProps = {
    open: boolean
    anchorEl: HTMLElement | null
    setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>
}

const StyledMenu = styled(Menu)(({ theme }) => ({
    fontFamily: 'Roboto',
    marginTop: '6px',
    '& ul': {
        borderRadius: '10px',
        width: '172px',
        height: '102px',
        boxShadow: '0px 5px 10px rgba(133, 140, 147, 0.12)',
        backgroundColor: '#fff',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        '& li': {
            color: '#535354',
            fontWeight: '400',
            fontSize: '16px',
            letterSpacing: '0.06em',

            '&:last-child': {
                marginTop: '4px',
            },
        },
    },
}))

export const ProfileMenu: React.FC<MenuProps> = (props) => {
    const { open, anchorEl, setAnchorEl } = props
    const [_, setSession] = useSession()
    const navigate = useNavigate()

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleLogout = () => {
        setSession(undefined)
        handleClose()
        navigate('/login')
    }

    const viewProfile = () => {
        handleClose()
        navigate('/profile')
    }

    return (
        <StyledMenu
            className="app-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
        >
            <MenuItem onClick={viewProfile}>Профиль</MenuItem>
            <MenuItem onClick={handleLogout}>Выйти</MenuItem>
        </StyledMenu>
    )
}
