import './table.styles.scss'
import { Table, TablePaginationConfig } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import React from 'react'

interface DataType {
  _id?: string
  cardNumber: string
  fio: string
  sickNess: string
  doctor: string
  phone: string
  lastVisitDate: string
  nextVisitDate?: string
  actions: string
}

type Props = {
  data: any[]
  pagination?: false | TablePaginationConfig
  columns: ColumnsType<any>
  rowSelection: {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => void
  }
}

export const ECOTable: React.FC<Props> = (props) => {
  const { data, columns, rowSelection } = props
  console.log(data)
  return (
    <div>
      <Table
        pagination={props.pagination}
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        columns={columns}
        dataSource={data}
      />
    </div>
  )
}
