import { ECONotification, User } from '../types'
import { baseApi } from './api'

export const notificationApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getCurrentUserNotifications: build.query<ECONotification[], void>({
      query: () => ({ url: '/notifications' }),
      providesTags: (result, error, arg) => {
        if (result) return [{ type: 'ECONotification', id: 'Notification' }]
        else return []
      },
    }),
    updateUserNotifications: build.mutation<
      ECONotification,
      ECONotification & { _id: string }
    >({
      query: (data) => {
        const { _id, ...body } = data

        return { url: `notifications/${_id}`, data: body, method: 'PUT' }
      },
      invalidatesTags: (result, error, arg, meta) => {
        return [{ type: 'ECONotification', id: 'Notification' }]
      },
    }),
    markAsRead: build.mutation<void, void>({
      query: () => {
        return { url: `notifications/updateMany/read`, method: 'PUT' }
      },
      invalidatesTags: (result, error, arg, meta) => {
        return [{ type: 'ECONotification', id: 'Notification' }]
      },
    }),
  }),
})

export const { useGetCurrentUserNotificationsQuery, useMarkAsReadMutation } =
  notificationApi
