import React from 'react'
import { Box } from '@mui/material'
import './loginPaper.style.scss'

import { LoginPaper } from './../../components/login/LoginPaper'

export const LoginPage: React.FC = () => {
    return (
        <Box className="content login">
            <LoginPaper />
        </Box>
    )
}
