import axios from 'axios'

export const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_SERVER}`,
  headers: {
    'Content-type': 'application/json; charset=UTF-8',
  },
})

export const apiMedia = axios.create({
  baseURL: `${process.env.REACT_APP_API_SERVER}`,
  headers: {
    'Content-type': 'multipart/form-data',
  },
})

export const api2 = axios.create({
  baseURL: `${process.env.REACT_APP_API_SERVER}`,
  headers: {
    'Content-type': 'application/json; charset=UTF-8',
  },
})

api.interceptors.request.use((req) => {
  if (localStorage.getItem('token')) {
    req.headers.authorization = `Bearer ${localStorage.getItem('token')}`
  }
  return req
})

api.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error) {
      if (error?.response?.status === 401) {
        localStorage.removeItem('token')
        localStorage.removeItem('userId')
        localStorage.removeItem('userType')

        window.location.href = '/'
      }
    }
  }
)

apiMedia.interceptors.request.use((req) => {
  if (localStorage.getItem('token')) {
    req.headers.authorization = `Bearer ${localStorage.getItem('token')}`
  }
  return req
})
