import { apiMedia } from '../../api'
import {
  BackButton,
  CustomNumericField,
  CustomTextField,
  EcoDropzone,
  EcoSelect,
  OptionType,
} from '../../components'
import { AlertDialog } from '../../components/shared'
import {
  useCreateNewUserMutation,
  useGetPositionsQuery,
  useGetDepartmentsQuery,
  useGetSpecializationsQuery,
  useGetRolesQuery,
} from '../../redux/services'
import { SelectResponse, User } from '../../redux/types'
import { registrationValidationSchema } from '../../validationSchemas'
import './register.styles.scss'
import { Box, Button, styled, Typography } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Formik } from 'formik'
import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

const StyledCancelButton = styled(Button)({
  color: '#1E71EA',
  background: '#F9FAFB',
  border: 'none',
  textTransform: 'capitalize',
  fontWeight: '500',
  padding: '13px, 16px, 13px, 16px',
  height: '50px',
  width: '160px',
  size: '16px',
  '&:hover': {
    border: 'none',
    color: '#2E7BEB',
  },
})

const StyledRegistrationButton = styled(Button)({
  color: '#FFFFFF',
  background: '#1E71EA',
  border: 'none',
  textTransform: 'capitalize',
  fontWeight: '500',
  padding: '13px, 16px, 13px, 16px',
  height: '50px',
  width: '160px',
  size: '16px',
  '&:hover': {
    border: 'none',
    color: '#f7f7f7',
    background: '#3D85ED',
  },
})

const initialValues = {
  systemRole: null,
  position: null,
  department: null,
  specialization: null,
  firstName: '',
  secondName: '',
  surName: '',
  dob: '',
  phone: '',
  email: '',
}
export const UserRegistration: React.FC = () => {
  const matches = useMediaQuery('(max-width:634px)')
  const [open, setOpen] = React.useState(false)
  const [profileImg, setProfileImg] = React.useState<File | null>(null)
  const navigate = useNavigate()
  const { data: positionData } = useGetPositionsQuery()
  const { data: departmentData } = useGetDepartmentsQuery()
  const { data: roleData } = useGetRolesQuery()
  const { data: specializationsData } = useGetSpecializationsQuery()

  const [createUser, { isLoading, error, isSuccess, reset, isError, data }] =
    useCreateNewUserMutation()

  const handleRegistration = async (values: User) => {
    let profilePhoto = ''

    if (profileImg) {
      const formData = new FormData()
      formData.append('profile', profileImg, 'profile')
      const res = await apiMedia.post('/users/uploadProfile', formData)
      const { filename } = res.data
      profilePhoto = filename
    }
    await createUser({ ...values, profilePhoto })
    setOpen(true)
  }

  const handleAfterClose = () => {
    navigate('/')
  }

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setProfileImg(acceptedFiles[0])
  }, [])

  return (
    <Box className="content">
      <BackButton />
      <AlertDialog
        handleAfterClose={handleAfterClose}
        severity={isSuccess ? 'success' : 'error'}
        setOpen={setOpen}
        open={open}
        errorMessage={
          isSuccess
            ? 'Новый пользователь создан'
            : 'Новый пользователь не создан'
        }
      />
      <Box className="registration">
        <Box className="registration__container">
          <Typography
            sx={{
              textAlign: 'center',
              color: '#0D0E13',
              fontWeight: '500',
              fontSize: '20px',
              lineHeight: '32px',
            }}
          >
            Регистрация пользователя
          </Typography>
          <Formik
            enableReinitialize
            onSubmit={handleRegistration}
            initialValues={initialValues}
            validationSchema={registrationValidationSchema}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
            }) => (
              <Box
                component="form"
                className="registration__container--form"
                onSubmit={handleSubmit}
              >
                <Typography className="title">Роль в системе</Typography>

                <Box>
                  <EcoSelect
                    helperText={
                      touched.systemRole ? errors.systemRole : undefined
                    }
                    error={!!touched.systemRole && !!errors.systemRole}
                    onBlur={handleBlur}
                    name="systemRole"
                    placeholder="Выберите роль в системе"
                    options={roleData as SelectResponse[]}
                    onChange={(event, newValue) => {
                      setFieldValue('systemRole', (newValue as any)?._id)
                    }}
                  />
                </Box>
                <Typography className="title">Личные данные</Typography>
                <Box className="personal-data">
                  <Typography className="title">Фотография</Typography>
                  <EcoDropzone onDrop={onDrop} />
                  <Box sx={{ marginTop: '24px' }} className="fields">
                    <Box
                      sx={{
                        flexDirection: matches ? 'column' : 'row',
                      }}
                      className="fields__container"
                    >
                      <Box className="fields__container--field">
                        <CustomTextField
                          error={!!touched.firstName && !!errors.firstName}
                          value={values.firstName}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          helperText={
                            touched.firstName ? errors.firstName : undefined
                          }
                          name="firstName"
                          placeholder="Введите Имя"
                          label="Имя"
                        />
                      </Box>
                      <Box className="fields__container--field">
                        <CustomTextField
                          error={!!touched.surName && !!errors.surName}
                          value={values.surName}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          helperText={
                            touched.surName ? errors.surName : undefined
                          }
                          name="surName"
                          label="Фамилия"
                          placeholder="Введите Фамилию"
                        />
                      </Box>
                    </Box>
                    <Box
                      className="fields__container"
                      sx={{
                        flexDirection: matches ? 'column' : 'row',
                      }}
                    >
                      <Box className="fields__container--field">
                        <CustomTextField
                          error={!!touched.secondName && !!errors.secondName}
                          value={values.secondName}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          helperText={
                            touched.secondName ? errors.secondName : undefined
                          }
                          name="secondName"
                          label="Отчество"
                          placeholder="Введите Отчество"
                        />
                      </Box>
                      <Box className="fields__container--field">
                        <CustomTextField
                          error={!!touched.dob && !!errors.dob}
                          value={values.dob}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          helperText={touched.dob ? errors.dob : undefined}
                          name="dob"
                          type="date"
                          label="Дата рождения"
                          placeholder="00.00.0000"
                        />
                      </Box>
                    </Box>
                    <Box
                      className="fields__container"
                      sx={{
                        flexDirection: matches ? 'column' : 'row',
                      }}
                    >
                      <Box className="fields__container--field">
                        <CustomNumericField
                          error={!!touched.phone && !!errors.phone}
                          value={values.phone}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          helperText={touched.phone ? errors.phone : undefined}
                          name="phone"
                          label="Телефон"
                          placeholder="Введите телефон"
                        />
                      </Box>
                      <Box className="fields__container--field">
                        <CustomTextField
                          error={!!touched.email && !!errors.email}
                          value={values.email}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          helperText={touched.email ? errors.email : undefined}
                          name="email"
                          type="email"
                          label="Email"
                          placeholder="Введите email"
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Typography className="title">Дополнительные данные</Typography>
                <Box className="additional-information">
                  <Typography className="label">Должность</Typography>
                  <EcoSelect
                    helperText={touched.position ? errors.position : undefined}
                    error={!!touched.position && !!errors.position}
                    onChange={(event, newValue) => {
                      setFieldValue('position', (newValue as any)?._id)
                    }}
                    onBlur={handleBlur}
                    name="position"
                    options={positionData as SelectResponse[]}
                    sx={{}}
                    placeholder="Выберите должность"
                  />
                </Box>
                <Box className="additional-information">
                  <Typography className="label">Отделение</Typography>
                  <EcoSelect
                    helperText={
                      touched.department ? errors.department : undefined
                    }
                    error={!!touched.department && !!errors.department}
                    onChange={(event, newValue) => {
                      setFieldValue('department', (newValue as any)?._id)
                    }}
                    onBlur={handleBlur}
                    name="department"
                    options={departmentData as SelectResponse[]}
                    sx={{}}
                    placeholder="Выберите отделение"
                  />
                </Box>
                <Box className="additional-information">
                  <Typography className="label">Специализация</Typography>
                  <EcoSelect
                    helperText={
                      touched.specialization ? errors.specialization : undefined
                    }
                    error={!!touched.specialization && !!errors.specialization}
                    onChange={(event, newValue) => {
                      setFieldValue('specialization', (newValue as any)?._id)
                    }}
                    onBlur={handleBlur}
                    name="specialization"
                    options={specializationsData as SelectResponse[]}
                    sx={{}}
                    placeholder="Выберите cпециализацию"
                  />
                </Box>
                <Box className="buttons">
                  <StyledCancelButton
                    type={'reset'}
                    variant="outlined"
                    className="buttons__cancel"
                  >
                    Отменить
                  </StyledCancelButton>
                  <StyledRegistrationButton
                    type={'submit'}
                    variant="contained"
                    className="buttons__register"
                  >
                    Регистрация
                  </StyledRegistrationButton>
                </Box>
              </Box>
            )}
          </Formik>
        </Box>
      </Box>
    </Box>
  )
}
