import * as yup from 'yup'

export const loginSchema = yup.object({
  email: yup.string().email('Неверный формат Email').required('Введите Email'),
  password: yup.string().required('Введите пароль'),
})
export const passwordRestoreSchema = yup.object({
  passwordRestoreEmail: yup
    .string()
    .email('Неверный формат Email')
    .required('Введите Email'),
})

export const registrationValidationSchema = yup.object({
  systemRole: yup.string().required('Введите роль'),
  // position: yup.string().required('Выберите должность'),
  // department: yup.string().required('Выберите отделение'),
  position: yup.string().nullable(),
  department: yup.string().nullable(),
  // specialization: yup.string().required('Выберите специализацию'),
  specialization: yup.string().nullable(),
  firstName: yup.string().required('Выберите имя'),
  secondName: yup.string().required('Выберите отчество'),
  surName: yup.string().required('Выберите фамилию'),
  dob: yup
    .date()
    .required('Выберите дату рождению')
    .max(new Date(), 'Дата введена неправильно'),
  phone: yup.string().required('Введите телефон'),
  email: yup.string().email('Неверный формат Email').required('Введите Email'),
})

export const profileValidationSchema = yup.object({
  position: yup.string().required('Выберите должность'),
  department: yup.string().required('Выберите отделение'),
  specialization: yup.string().required('Выберите специализацию'),
  firstName: yup.string().required('Выберите имя'),
  secondName: yup.string().required('Выберите отчество'),
  surName: yup.string().required('Выберите фамилию'),
  dob: yup
    .date()
    .required('Выберите дату рождению')
    .max(new Date(), 'Дата введена неправильно'),
  phone: yup.string().required('Введите телефон'),
  email: yup.string().email('Неверный формат Email').required('Введите Email'),
})

export const appointmentValidationSchema = yup.object({
  searchText: yup.string().required('Введите ФИО или номер карточки пациента'),
})

export const proceduresValidationSchema = yup.object({
  searchText: yup.string().required('Введите серию и номер документа пациента'),
})

export const appointmentTimeValidationSchema = yup.object().shape({
  type: yup.string().required(),
  comment: yup.string(),
  patient: yup.string().required('Пожалуйста, выберите пациет'),
  assignedTo: yup.string().required('Пожалуйста, выберите врач'),
  startStr: yup.date().min(new Date(), 'Пожалуйста, выберите будущую дату'),
  endStr: yup
    .date()
    .test(
      'time_test',
      'время окончания должно быть больше времени начала',
      function (value, context) {
        const { startStr } = this.parent
        if (!value) {
          return false
        }
        return value?.getTime() > startStr.getTime()
      }
    ),
})

export const PatientCardValidationSchema = yup.object().shape({
  age: yup
    .number()
    .min(0, 'Неверный возраст')
    .max(200, 'Неверный возраст')
    .required('Введите возрасть'),
  fio: yup.string().required('Введите ФИО'),
  sickNess: yup.string().required('Введите заболевание'),
  lastVisitDate: yup.string().required('Введите дату'),
  plannedVisitDate: yup
    .date()
    .min(new Date(), 'Пожалуйста, выберите будущую дату')
    .required('Введите дату'),
  plannedVisitTimeEnd: yup
    .date()
    .test(
      'time_test',
      'время окончания должно быть больше времени начала',
      function (value, context) {
        const { plannedVisitDate } = this.parent
        if (!value) {
          return false
        }
        return value?.getTime() > plannedVisitDate.getTime()
      }
    )
    .required('Введите дату'),
  doctorFio: yup.string().required('Введите ФИО врача'),
})

export const patientInformationValidationSchema = yup.object().shape({
  surName: yup.string(),
  firstName: yup.string().required('Пожалуйста, введите имя'),
  lastName: yup.string().required('Пожалуйста, введите фамилию'),
  dateOfBirth: yup.date(),
  cityzenship: yup.string(),
  identityDocument: yup.object({
    name: yup.string(),
    serialNumber: yup.string(),
    number: yup.string(),
  }),
  snilsNumber: yup.string(),
  email: yup.string().email('Неверный формат Email'),
  phone: yup.string(),
  location: yup.object().shape({
    district: yup.string(),
    city: yup.object().shape({
      _id: yup.string(),
    }),
    locality: yup.string(),
    street: yup.string(),
    housing: yup.string(),
    building: yup.string(),
    apartment: yup.string(),
  }),
  stayLocation: yup
    .object()
    .nullable()
    .when('residesAtSamePlace', ([residesAtSamePlace], schema, options) =>
      residesAtSamePlace
        ? schema
        : schema.shape({
            district: yup.string(),
            city: yup.object().shape({
              _id: yup.string(),
            }),
            locality: yup.string(),
            street: yup.string(),
            housing: yup.string(),
            building: yup.string(),
            apartment: yup.string(),
          })
    ),
  terrain: yup.string(),
  familyStatus: yup.string(),
  education: yup.string(),
  employment: yup.string(),
  placeOfWork: yup.string(),
  position: yup.string(),
  disabilityGroup: yup.string(),
  irradiated: yup.string(),
  healthInsurance: yup.object({
    number: yup.string(),
    date: yup.date(),
  }),
  paymentType: yup.string(),
  medicalDocFamiliarization: yup.string(),
})

export const patientMedicalConsentValidationSchema = yup.object().shape({
  surName: yup.string(),
  firstName: yup.string().required('Пожалуйста, введите имя'),
  lastName: yup.string().required('Пожалуйста, введите фамилию'),
  dateOfBirth: yup.date(),
  phone: yup.string(),
  dateOfRegistration: yup.date(),
  location: yup.object().shape({
    district: yup.string(),
    city: yup.object().shape({
      _id: yup.string(),
    }),
    locality: yup.string(),
    street: yup.string(),
    housing: yup.string(),
    building: yup.string(),
    apartment: yup.string(),
  }),
  stayLocation: yup
    .object()
    .nullable()
    .when('residesAtSamePlace', ([residesAtSamePlace], schema, options) =>
      residesAtSamePlace
        ? schema
        : schema.shape({
            district: yup.string(),
            city: yup.object().shape({
              _id: yup.string(),
            }),
            locality: yup.string(),
            street: yup.string(),
            housing: yup.string(),
            building: yup.string(),
            apartment: yup.string(),
          })
    ),
  representative: yup
    .object()
    .when('hasRepresentative', ([hasRepresentative], schema, options) =>
      !hasRepresentative
        ? schema
        : schema.shape({
            surName: yup.string(),
            firstName: yup.string(),
            lastName: yup.string(),
            dateOfBirth: yup.date(),
            identityDocument: yup.object({
              name: yup.string(),
              serialNumber: yup.string(),
              number: yup.string(),
            }),
            location: yup.object().shape({
              district: yup.string(),
              city: yup.object().shape({
                _id: yup.string(),
              }),
              locality: yup.string(),
              street: yup.string(),
              housing: yup.string(),
              building: yup.string(),
              apartment: yup.string(),
            }),
            stayLocation: yup
              .object()
              .nullable()
              .when(
                'representative.residesAtSamePlace',
                ([residesAtSamePlace], schema, options) =>
                  residesAtSamePlace
                    ? schema
                    : schema.shape({
                        district: yup.string(),
                        city: yup.object().shape({
                          _id: yup.string(),
                        }),
                        locality: yup.string(),
                        street: yup.string(),
                        housing: yup.string(),
                        building: yup.string(),
                        apartment: yup.string(),
                      })
              ),
          })
    ),
  medicalOrganization: yup.string(),
  medicalWorker: yup.object().shape({
    surName: yup.string(),
    firstName: yup.string(),
    lastName: yup.string(),
  }),
})

export const personalDataConsentValidationSchema = yup.object().shape({
  surName: yup.string(),
  firstName: yup.string().required('Пожалуйста, введите имя'),
  lastName: yup.string().required('Пожалуйста, введите фамилию'),
  dateOfBirth: yup.date(),
  identityDocument: yup.object({
    name: yup.string(),
    serialNumber: yup.string(),
    number: yup.string(),
    issuer: yup.string(),
    dateOfIssue: yup.date(),
  }),
  phone: yup.string(),
  dateOfConsent: yup.date(),
  location: yup.object().shape({
    district: yup.string(),
    city: yup.object().shape({
      _id: yup.string(),
    }),
    locality: yup.string(),
    street: yup.string(),
    housing: yup.string(),
    building: yup.string(),
    apartment: yup.string(),
  }),
  stayLocation: yup
    .object()
    .nullable()
    .when('residesAtSamePlace', ([residesAtSamePlace], schema, options) =>
      residesAtSamePlace
        ? schema
        : schema.shape({
            district: yup.string(),
            city: yup.object().shape({
              _id: yup.string(),
            }),
            locality: yup.string(),
            street: yup.string(),
            housing: yup.string(),
            building: yup.string(),
            apartment: yup.string(),
          })
    ),
})

export const PatientValidationSchema2 = yup.object().shape({
  surName: yup.string().required('Пожалуйста, введите отчество'),
  firstName: yup.string().required('Пожалуйста, введите имя'),
  lastName: yup.string().required('Пожалуйста, введите фамилию'),
  dateOfBirth: yup.date().required('Пожалуйста, введите дату'),
  // cityzenship: yup.string().required('Укажите гражданство'),
  // identityDocument: yup.object({
  //     name: yup.string().required('Поле обязательно'),
  //     serialNumber: yup.string().required('Укажите серийный номер'),
  //     number: yup.string().required('Укажите номер номер'),
  // }),
  // snilsNumber: yup.string().required('Поля обязательны'),
  // email: yup
  //     .string()
  //     .email('Неверный формат Email')
  //     .required('E-mail обязательна'),
  // phone: yup.string(),
  // location: yup.object({
  //     district: yup.string().required('Введите район'),
  //     city: yup.string().required('Введите город'),
  //     locality: yup.string().required('Введите населенный пункт'),
  //     street: yup.string().required('Введите улицу'),
  //     housing: yup.string().required('Введите дом'),
  //     building: yup.string().required('Введите корпус'),
  //     apartment: yup.string().required('Введите номер квартиры'),
  // }),
  // stayLocation: yup.object({
  //     district: yup.string().required('Введите район'),
  //     city: yup.string().required('Введите город'),
  //     locality: yup.string().required('Введите населенный пункт'),
  //     street: yup.string().required('Введите улицу'),
  //     housing: yup.string().required('Введите дом'),
  //     building: yup.string().required('Введите корпус'),
  //     apartment: yup.string().required('Введите номер квартиры'),
  // }),
  // terrain: yup.string().required('Введите местность'),
  // familyStatus: yup.string().required('Введите семейное положение'),
  // education: yup.string().required('Введите образование'),
  // employment: yup.string().required('Введите занятость'),
  // placeOfWork: yup.string().required('Введите место работы'),
  // position: yup.string().required('Введите должность'),
  // disabilityGroup: yup.string().required('Поле обязательно'),
  // irradiated: yup.string().required('Поле обязательно'),
  // healthInsurance: yup.object({
  //     number: yup.string().required('Поле обязательно'),
  //     date: yup.date().required('Поле обязательно'),
  // }),
  // paymentType: yup.string().required('Выберите основной вид оплаты'),
  // medicalDocFamiliarization: yup.string().required('Поле обязательно'),
})

/*export const PatientValidationSchema = yup.object().shape({
    surName: yup.string().required('Пожалуйста, введите фамилию'),
    firstName: yup.string().required('Пожалуйста, введите имя'),
    secondName: yup.string().required('Пожалуйста, введите отчество'),
    //gender: yup.string().required('Поля обязательны'),
    identityDocument: yup.string().required('не выбран'),
    serialNumber: yup.string().required('указать серийный номер'),
    identityDocumentIssueDate: yup.string().required('Поля обязательны'),
    identityDocumentIssuingAuthority: yup
        .string()
        .required('Введите орган выдачи'),
    identityDocumentNumber: yup
        .string()
        .required('Введите идентификационный номер'),
    snilsNumber: yup.string().required('Поля обязательны'),
    citizenship: yup.string().required('Укажите гражданство'),
    registrationPlace: yup.string().required('Введите регистрацию'),
    oblast: yup.string().required('Введите район'),
    city: yup.string().required('Введите город'),
    street: yup.string().required('Пожалуйста, введите улица'),
    building: yup.string().required('Пожалуйста, введите дом'),
    housing: yup.string().required('Пожалуйста, введите корпус'),
    apartment: yup.string().required('Пожалуйста, введите кватира'),
    email: yup
        .string()
        .email('Неверный формат Email')
        .required('E-mail обязательна'),
    phone: yup.string(),
    dob: yup.string().required('Пожалуйста, введите дату'),
    locality: yup.string().required('введите населённый пункт'),
    education: yup.string().required('введите образование'),
    employment: yup.string().required('введите занятость'),
    position: yup.string().required(''),
    maritalStatus: yup.string().required('выбрать статус'),
    radiationExposure: yup.string().required('Поля обязательны'),
    medicalDocumentation: yup.string().required('Поля обязательны'),
    paymentMethod: yup.string().required('Поля обязательны'),
    terrain: yup.string().required('Поля обязательны'),
    insuranceInfo: yup.string(),
    insurance: yup.string(),
    comment: yup.string(),
    workPlace: yup.string(),
    disabilityGroup: yup.string(),
})*/
