import styles from './styles.module.scss'
import { Box } from '@mui/material'
import clsx from 'clsx'
import { BackButton, AlertDialog } from 'components'
import { DocumentFormWithSteps, IDocument } from 'features/documents'
import { isSuccessResult } from 'lib/isSuccessResult'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useUpdateDocumentMutation, useGetDocumentQuery } from 'redux/services'

export const DocumentEditPage: React.FC = () => {
  const [doc, setDoc] = useState<IDocument | null>(null)
  const [modal, setModal] = useState<React.ReactNode>(null)

  const { documentId } = useParams()

  const getDocumentQuery = useGetDocumentQuery(documentId)

  const document = getDocumentQuery.data

  useEffect(() => {
    if (!document || !document.template) return

    import(`documents/${document.template}`).then((data) => {
      setDoc({ ...data.default, data: document.data })
    })
  }, [document])

  const [updateDocument] = useUpdateDocumentMutation()

  const onSubmit = (data: any) => {
    updateDocument({
      ...document,
      data,
    }).then((result) => {
      if (isSuccessResult(result)) {
        setModal(
          <AlertDialog
            onClose={() => setModal(null)}
            severity={'success'}
            message={'Документ изменен'}
          />
        )
      } else {
        setModal(
          <AlertDialog
            onClose={() => setModal(null)}
            severity={'error'}
            message={'Что-то пошло не так'}
          />
        )
      }
    })
  }

  return (
    <>
      {modal}
      <Box className={clsx('content', styles.documentCreatePage)}>
        <BackButton />
        <Box className={clsx(styles['document-create'])}>
          <div>
            {doc && <DocumentFormWithSteps onSubmit={onSubmit} {...doc} />}
          </div>
        </Box>
      </Box>
    </>
  )
}
