import { BackButton, CustomPrimaryButton } from '../../components'
import { SearchField } from '../../components/ui/SearchField'
import { useSearchPatientsQuery } from '../../redux/services'
import { appointmentValidationSchema } from '../../validationSchemas'
import '../appointments/appointment.styles.scss'
import errorIcon from './../../svgs/error.svg'
import { Box, Typography } from '@mui/material'
import { useFormik } from 'formik'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

const initialValues = {
  searchText: '',
}

export const Documentation: React.FC = () => {
  const [error, setError] = React.useState(false)
  const [searchText, setSearchText] = useState('')
  const navigate = useNavigate()

  const { data } = useSearchPatientsQuery(searchText, {
    skip: !searchText,
  })

  const [errorMessage, setErrorMessage] = React.useState('')

  React.useEffect(() => {
    if (Array.isArray(data) && data.length === 0) {
      if (data?.length === 0) {
        setError(true)
        setErrorMessage('Пациент не найден в системе')
      }
    }
  }, [data])

  const handleSearchPatient = (values: { searchText: string }) => {
    setErrorMessage('')
    setError(false)
    setSearchText(values.searchText)
  }

  const formik = useFormik({
    initialValues,
    onSubmit: handleSearchPatient,
    validationSchema: appointmentValidationSchema,
  })

  const goToRegisterPage = () => {
    navigate('/patients/register/new')
  }

  const goToPatientsCard = (id: string) => {
    navigate(`/documentation/${id}`)
  }

  return (
    <Box className="content">
      <BackButton />
      <Box className="appointment">
        <Box className="appointment__container">
          <Box
            className="appointment__container--content"
            sx={{
              maxHeight:
                error ||
                (!!formik.touched.searchText && !!formik.errors.searchText)
                  ? '296px'
                  : '264px',
            }}
          >
            <Typography variant="h6">
              Формирование медицинской документации
            </Typography>
            <Box
              onSubmit={formik.handleSubmit}
              component="form"
              className="card"
              sx={{
                height:
                  error ||
                  (!!formik.touched.searchText && !!formik.errors.searchText)
                    ? '248px'
                    : '216px',
              }}
            >
              <Box className="card__header">
                <Typography variant="h6">
                  Введите ФИО или номер карточки пациента
                </Typography>
              </Box>
              <Box>
                <SearchField
                  placeholder={'ФИО или карточка пациента'}
                  name={'searchText'}
                  error={error || !!formik.errors.searchText}
                  value={formik.values.searchText}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </Box>
              {(error || Boolean(formik.errors.searchText)) && (
                <Box className="errorText">
                  <img src={errorIcon} alt="error" />
                  <Typography variant="h6">
                    {formik.errors.searchText || errorMessage}
                  </Typography>
                </Box>
              )}
              {error ? (
                <Box className="notfound">
                  <Box component="div">
                    <CustomPrimaryButton type={'submit'}>
                      Найти
                    </CustomPrimaryButton>
                  </Box>
                  <Box component="div">
                    <CustomPrimaryButton
                      onClick={goToRegisterPage}
                      type={'button'}
                    >
                      Зарегистрировать пациента
                    </CustomPrimaryButton>
                  </Box>
                </Box>
              ) : (
                <Box>
                  <CustomPrimaryButton type={'submit'}>
                    Найти
                  </CustomPrimaryButton>
                </Box>
              )}
            </Box>
          </Box>

          {data && data.length > 0 && (
            <Box
              className="appointment__container--result"
              sx={{ marginTop: '18px' }}
            >
              <Typography variant="h6">Результаты поиска</Typography>
              <Box className="card list">
                {data.map((patient) => (
                  <Box
                    className="item"
                    onClick={() => goToPatientsCard(patient._id)}
                  >
                    <Typography variant="h6">
                      {patient.fio} ({patient.cardNumber})
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  )
}
