import { ActionCard } from '../../components/cards/ActionCard'
import { useSession } from '../../context/session.context'
import calenderCard from '../../svgs/calenderCardIcon.svg'
import cardsCommonIcon from '../../svgs/cardsCommonIcon.svg'
import './home.styles.scss'
import { Box, Grid } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const cardsData = [
  {
    cardTitle: 'Календарь',
    icon: calenderCard,
    cardLink: '/calender',
  },
  {
    cardTitle: 'Платные услуги',
    icon: cardsCommonIcon,
    cardLink: '/paidServices',
  },
  /*{
    cardTitle: 'Записать на процедуру',
    icon: cardsCommonIcon,
    cardLink: '/procedures',
  },*/
  {
    cardTitle: 'Медицинская документация',
    icon: cardsCommonIcon,
    cardLink: '/documentation',
  },
  {
    cardTitle: 'Записать на приём',
    icon: cardsCommonIcon,
    cardLink: '/appointments',
  },
  {
    cardTitle: 'Общий список пациентов',
    icon: cardsCommonIcon,
    cardLink: '/patients',
  },
  {
    cardTitle: 'Общий список документов',
    icon: cardsCommonIcon,
    cardLink: '/documents',
  },
  {
    cardTitle: 'Регистрация персонала',
    icon: cardsCommonIcon,
    cardLink: '/register',
  },
]

export const HomePage: React.FC = () => {
  const [session] = useSession()
  const navigate = useNavigate()

  const { isAuthenticated } = session
  React.useEffect(() => {
    if (!isAuthenticated) {
      return navigate('/login')
    }
  }, [])

  return (
    <Box className="content">
      <Box className="home">
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
          }}
          className="home__cards"
        >
          <Box className={'home__cardsList'}>
            {cardsData.map(({ cardTitle, icon, cardLink }, index) => (
              <ActionCard
                cardLink={cardLink}
                cardTitle={cardTitle}
                icon={icon}
                cardNumber={index + 1}
              />
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
