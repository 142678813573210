import { IRule, IConditionalRule } from '../types'
import get from 'lodash/get'
import set from 'lodash/set'

export const applyRules = (data: any, rules?: IRule[]) => {
  if (!rules) return

  for (const rule of rules) {
    switch (rule.__type) {
      case 'ConditionalRule':
        applyConditionalRule(data, rule)
    }
  }
}

const applyConditionalRule = (data: any, rule: IConditionalRule) => {
  const whenField = get(data, rule.when)
  const asField = get(data, rule.then.as)

  const applyRule = () => {
    set(data, rule.then.field, asField)
  }

  // @ts-ignore
  if (rule.isFalsy) {
    if (!whenField) {
      return applyRule()
    }
  } else {
    // @ts-ignore
    if (rule.is === whenField) {
      return applyRule()
    }
  }
}
