import React from 'react'
import { Paper } from '@mui/material'
import './login.styles.scss'
import { LoginFormSection } from './LoginFormSection'

export const LoginPaper: React.FC = () => {
    return (
        <Paper variant="outlined" square className="login__paper">
            <LoginFormSection />
        </Paper>
    )
}
