import {
  identityDocumentOptions,
  paymentTypeOptions,
  terrainOptions,
} from './common'
import { IDocument } from 'features/documents'
import { createDocumentContext } from 'features/documents/lib/context'
import { createOptions } from 'lib/createOptions'
import * as yup from 'yup'

const doc: IDocument = {
  title: 'Информационное добровольное согласие на медицинское вмешательство',
  steps: [
    {
      fields: [
        {
          __type: 'TextField',
          name: 'lastName',
          label: 'Фамилия',
          placeholder: 'Введите фамилию',
        },
        {
          __type: 'TextField',
          name: 'firstName',
          label: 'Имя',
          placeholder: 'Введите имя',
        },
        {
          __type: 'TextField',
          name: 'surName',
          label: 'Отчество',
          placeholder: 'Введите отчество',
        },
        {
          __type: 'DateField',
          name: 'dateOfBirth',
          label: 'Дата рождения',
        },
        {
          __type: 'GroupField',
          name: 'location',
          title: 'Регистрация по месту жительства, субъект РФ',
          fields: [
            {
              __type: 'CitySelectField',
              placeholder: 'Город',
              name: 'location.city',
            },
            {
              __type: 'TextField',
              name: 'location.district',
              label: 'Район',
            },
            {
              __type: 'TextField',
              name: 'location.locality',
              label: 'Населенный пункт',
            },
            {
              __type: 'TextField',
              name: 'location.street',
              label: 'Улица',
            },
            {
              __type: 'TextField',
              name: 'location.housing',
              label: 'Дом',
            },
            {
              __type: 'TextField',
              name: 'location.building',
              label: 'Строение/Корпус',
            },
            {
              __type: 'TextField',
              name: 'location.apartment',
              label: 'Квартира',
            },
          ],
        },
        {
          __type: 'CheckboxField',
          name: 'residesAtSamePlace',
          label: 'Проживает по месту регистрации',
        },
        {
          __type: 'ConditionalGroupField',
          name: 'stayLocation',
          title: 'Регистрация по месту пребывания, субъект РФ',
          when: 'residesAtSamePlace',
          isFalsy: true,
          fields: [
            {
              __type: 'CitySelectField',
              placeholder: 'Город',
              name: 'stayLocation.city',
            },
            {
              __type: 'TextField',
              name: 'stayLocation.district',
              label: 'Район',
            },
            {
              __type: 'TextField',
              name: 'stayLocation.locality',
              label: 'Населенный пункт',
            },
            {
              __type: 'TextField',
              name: 'stayLocation.street',
              label: 'Улица',
            },
            {
              __type: 'TextField',
              name: 'stayLocation.housing',
              label: 'Дом',
            },
            {
              __type: 'TextField',
              name: 'stayLocation.building',
              label: 'Строение/Корпус',
            },
            {
              __type: 'TextField',
              name: 'stayLocation.apartment',
              label: 'Квартира',
            },
          ],
        },
        {
          __type: 'CheckboxField',
          name: 'hasRepresentative',
          label: 'Есть законный представитель',
        },
        {
          __type: 'ConditionalGroupField',
          name: 'representative',
          when: 'hasRepresentative',
          is: true,
          fields: [
            {
              __type: 'TextField',
              name: 'representative.lastName',
              label: 'Фамилия',
              placeholder: 'Введите фамилию',
            },
            {
              __type: 'TextField',
              name: 'representative.firstName',
              label: 'Имя',
              placeholder: 'Введите имя',
            },
            {
              __type: 'TextField',
              name: 'representative.surName',
              label: 'Отчество',
              placeholder: 'Введите отчество',
            },
            {
              __type: 'DateField',
              name: 'representative.dateOfBirth',
              label: 'Дата рождения',
            },
            {
              __type: 'SelectField',
              name: 'representative.identityDocument.name',
              placeholder: 'Документ, удостоверяющий личность',
              options: identityDocumentOptions,
            },
            {
              __type: 'TextField',
              name: 'representative.identityDocument.serialNumber',
              label: 'Серия',
            },
            {
              __type: 'TextField',
              name: 'representative.identityDocument.number',
              label: 'Номер',
            },
            {
              __type: 'TextField',
              name: 'representative.identityDocument.issuer',
              label: 'Кем выдан',
            },
            {
              __type: 'DateField',
              name: 'representative.identityDocument.dateOfIssue',
              label: 'Дата выдачи',
            },
            {
              __type: 'TextField',
              name: 'representative.identityDocument.issuerCode',
              label: 'Код подразделения',
            },
            {
              __type: 'GroupField',
              name: 'representative.location',
              title: 'Регистрация по месту жительства, субъект РФ',
              fields: [
                {
                  __type: 'CitySelectField',
                  placeholder: 'Город',
                  name: 'representative.location.city',
                },
                {
                  __type: 'TextField',
                  name: 'representative.location.district',
                  label: 'Район',
                },
                {
                  __type: 'TextField',
                  name: 'representative.location.locality',
                  label: 'Населенный пункт',
                },
                {
                  __type: 'TextField',
                  name: 'representative.location.street',
                  label: 'Улица',
                },
                {
                  __type: 'TextField',
                  name: 'representative.location.housing',
                  label: 'Дом',
                },
                {
                  __type: 'TextField',
                  name: 'representative.location.building',
                  label: 'Строение/Корпус',
                },
                {
                  __type: 'TextField',
                  name: 'representative.location.apartment',
                  label: 'Квартира',
                },
              ],
            },
            {
              __type: 'CheckboxField',
              name: 'representative.residesAtSamePlace',
              label: 'Проживает по месту регистрации',
            },
            {
              __type: 'ConditionalGroupField',
              name: 'representative.stayLocation',
              title: 'Регистрация по месту пребывания, субъект РФ',
              when: 'representative.residesAtSamePlace',
              isFalsy: true,
              fields: [
                {
                  __type: 'CitySelectField',
                  placeholder: 'Город',
                  name: 'representative.stayLocation.city',
                },
                {
                  __type: 'TextField',
                  name: 'representative.stayLocation.district',
                  label: 'Район',
                },
                {
                  __type: 'TextField',
                  name: 'representative.stayLocation.locality',
                  label: 'Населенный пункт',
                },
                {
                  __type: 'TextField',
                  name: 'representative.stayLocation.street',
                  label: 'Улица',
                },
                {
                  __type: 'TextField',
                  name: 'representative.stayLocation.housing',
                  label: 'Дом',
                },
                {
                  __type: 'TextField',
                  name: 'representative.stayLocation.building',
                  label: 'Строение/Корпус',
                },
                {
                  __type: 'TextField',
                  name: 'representative.stayLocation.apartment',
                  label: 'Квартира',
                },
              ],
            },
          ],
        },
        {
          __type: 'TextField',
          name: 'medicalOrganization',
          label: 'Название медицинской организации',
        },
        {
          __type: 'TextField',
          name: 'medicalWorker.lastName',
          label: 'Фамилия медицинского работника',
        },
        {
          __type: 'TextField',
          name: 'medicalWorker.firstName',
          label: 'Имя медицинского работника',
        },
        {
          __type: 'TextField',
          name: 'medicalWorker.surName',
          label: 'Отчество медицинского работника',
        },
        {
          __type: 'PhoneField',
          name: 'phone',
          label: 'Телефон',
        },
        {
          __type: 'DateField',
          name: 'dateOfRegistration',
          label: 'Дата оформления',
        },
      ],
    },
  ],
  data: {
    residesAtSamePlace: true,
    hasRepresentative: false,
    representative: {
      residesAtSamePlace: true,
    },
    medicalOrganization: 'ООО Экомед',
  },
}

export default doc
