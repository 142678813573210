import { Button, styled } from '@mui/material'

export const CustomButton = styled(Button)({
  color: '#1E71EA',
  background: '#F9FAFB',
  border: 'none',
  textTransform: 'capitalize',
  fontWeight: '500',
  padding: '13px, 16px, 13px, 16px',
  height: '50px',
  width: '160px',
  size: '16px',
  '&:hover': {
    border: 'none',
    color: '#2E7BEB',
  },
})
