import { SelectResponse } from '../types'
import { baseApi } from './api'

export const specializationsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getSpecializations: build.query<SelectResponse[], void>({
      query: () => ({ url: 'specializations' }),
    }),
  }),
})

export const { useGetSpecializationsQuery } = specializationsApi
