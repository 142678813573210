import { useSession } from '../../context/session.context'
import { useMarkAsReadMutation } from '../../redux/services/notification'
import { ECONotification } from '../../redux/types'
import checkmarkIcon from './../../svgs/customCheckmarkIcon.svg'
import listBulletIcon from './../../svgs/listBulletIcon.svg'
import unCheckmarkIcon from './../../svgs/uncheckedCheckmarkIcon.svg'
import { Box, Checkbox, Menu, styled, Typography } from '@mui/material'
import { List } from 'antd'
import moment from 'moment'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'

type MenuProps = {
    open: boolean
    anchorEl: HTMLElement | null
    setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>
    notificationsData?: ECONotification[]
}

const StyledMenu = styled(Menu)({
    width: 'auto',
    height: 'auto',
    overflow: 'hidden',
    padding: '0 12px',
    marginTop: '10px',
})

export const NotificationsMenu: React.FC<MenuProps> = (props) => {
    const { open, anchorEl, setAnchorEl, notificationsData } = props
    const [_, setSession] = useSession()
    const navigate = useNavigate()
    const [markAsRead, { data, error, isLoading, isSuccess }] =
        useMarkAsReadMutation()

    const handleClose = () => {
        setAnchorEl(null)
    }

    const viewProfile = () => {
        handleClose()
        navigate('/profile')
    }
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        markAsRead()
    }

    if (!notificationsData) {
        return null
    }

    return (
        <StyledMenu
            className="app-menu"
            anchorEl={anchorEl}
            open={notificationsData?.length > 0 && open}
            onClose={handleClose}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '0 12px',
                    alignItems: 'center',
                    marginTop: '0',
                }}
            >
                <Box>
                    <Typography
                        variant="h6"
                        sx={{
                            fontFamily: 'Montserrat',
                            color: '#1A1F36',
                            fontWeight: '500',
                            fontSize: '14px',
                        }}
                    >
                        Уведомления
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography
                        className="h6"
                        sx={{
                            fontFamily: 'Montserrat',
                            color: '#1A1F36',
                            fontWeight: '400',
                            fontSize: '14px',
                        }}
                    >
                        Отметить как прочитанные
                    </Typography>
                    <Checkbox
                        onChange={onChange}
                        icon={
                            <img
                                width={'32px'}
                                src={unCheckmarkIcon}
                                alt={'checked'}
                            />
                        }
                        sx={{
                            width: '18px',
                            height: '18px',
                            marginLeft: '6px',
                        }}
                        checkedIcon={
                            <img
                                width={'32px'}
                                src={checkmarkIcon}
                                alt={'checked'}
                            />
                        }
                    />
                </Box>
            </Box>
            <Box sx={{ padding: '2px 16px' }}>
                <List
                    style={{
                        maxWidth: '440px',
                        width: '440px',
                        padding: '0 12px',
                    }}
                    itemLayout="horizontal"
                    dataSource={notificationsData}
                    renderItem={(item, index) => (
                        <List.Item>
                            <List.Item.Meta
                                avatar={<img src={listBulletIcon} alt="list" />}
                                title={<span>{item.title}</span>}
                                description={
                                    <span>
                                        {moment(item.createdAt).format(
                                            'DD.MM.YYYY в HH:mm'
                                        )}
                                    </span>
                                }
                            />
                        </List.Item>
                    )}
                />
            </Box>
        </StyledMenu>
    )
}
