import * as yup from 'yup'

export const appointmentValidationSchema = yup.object().shape({
  plannedVisitDate: yup
    .date()
    .min(new Date(), 'Пожалуйста, выберите будущую дату')
    .required('Введите дату'),
  plannedVisitTimeEnd: yup
    .date()
    .test(
      'time_test',
      'время окончания должно быть больше времени начала',
      function (value, context) {
        const { plannedVisitDate } = this.parent
        if (!value) {
          return false
        }
        return value?.getTime() > plannedVisitDate.getTime()
      }
    )
    .required('Введите дату'),
  doctorFio: yup.string().required('Введите ФИО врача'),
})
