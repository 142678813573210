import {
  AutocompleteChangeReason,
  AutocompleteValue,
  FilterOptionsState,
} from '@mui/base/useAutocomplete/useAutocomplete'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { Autocomplete, styled, SxProps, TextField, Theme } from '@mui/material'
import React from 'react'

const StyledTextField = styled(TextField)(({ theme }) => ({
  background: '#fff',
  color: '#0D0E13',
  fontSize: '14px',
  fontWeight: '400',
  letterSpacing: '0.01em',
  borderRadius: '6px',
  '& .Mui-focused': {
    border: 'none',
  },
}))

const StyledAutocomplete = styled(Autocomplete)(({ sx }) => ({
  '& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)': {
    transform: 'translate(34px, 20px) scale(1);',
  },
  '&.Mui-focused .MuiInputLabel-outlined': {
    color: '#0D0E13',
  },
  '& .MuiAutocomplete-inputRoot': {
    color: '#0D0E13',
    letterSpacing: '0.01em',
    fontWeight: '400',
    fontSize: '14px',

    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-of-type': {
      paddingLeft: 16,
      '&::placeholder': {
        padding: '9px 12px',
        background: sx ? '#E7F1FF' : '#fff',
        color: sx ? '#3675F1' : '#0D0E13',
        fontSize: '14px',
        borderRadius: '6px',
        fontWeight: '400',
        maxWidth: '180px',
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: ' #E6EDF2',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #DBE5EC',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #E6EDF2',
    },
  },
}))

export type OptionType = {
  label: string
  value: any
  _id?: string
}

type Props = {
  options: OptionType[]
  multiple?: boolean
  placeholder: string
  sx?: SxProps<Theme>
  name: string
  onBlur?: React.FocusEventHandler<Element>
  onChange: (
    event: React.SyntheticEvent,
    value: AutocompleteValue<any, any, any, any>,
    reason: AutocompleteChangeReason
  ) => void
  error: boolean
  helperText?: string
  defaultValue?: OptionType | null
  value?: OptionType | null
  freeSolo?: boolean
  disabled?: boolean
  size?: 'medium' | undefined | 'small'
  filterOptions?: (options: any[], state: FilterOptionsState<any>) => any[]
  onInputChange?: (event: any, newInputValue: any) => void
}

export const EcoSelect: React.FC<Props> = (props) => {
  const {
    options,
    multiple = false,
    placeholder,
    sx,
    name,
    onBlur,
    onChange: handleChange,
    helperText,
    error,
    value,
    defaultValue = null,
    freeSolo = false,
    disabled = false,
    size = 'medium',
    filterOptions,
    onInputChange,
  } = props

  return (
    <StyledAutocomplete
      filterOptions={filterOptions}
      freeSolo={freeSolo}
      defaultValue={defaultValue}
      fullWidth
      disablePortal
      onChange={handleChange}
      onBlur={onBlur}
      value={value}
      sx={sx}
      multiple={multiple}
      size={size}
      renderInput={(params) => {
        return (
          <StyledTextField
            error={error}
            helperText={helperText}
            name={name}
            placeholder={placeholder}
            {...params}
          />
        )
      }}
      options={options}
      popupIcon={<KeyboardArrowDownIcon sx={{ color: '#0D0E13' }} />}
      disabled={disabled}
      onInputChange={onInputChange}
    />
  )
}
