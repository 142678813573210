import { useSession } from '../../context/session.context'
import { DOCTOR } from '../../helpers/constants'
import { useGetEmployeesQuery, useGetPatientsQuery } from '../../redux/services'
import { useDeleteEventMutation } from '../../redux/services/events'
import {
  EcoEventCreateProps,
  EventType,
  Patient,
  SelectResponse,
  User,
} from '../../redux/types'
import { appointmentTimeValidationSchema } from '../../validationSchemas'
import { EcoSelect } from '../ui'
import './formikModal.styles.scss'
import { EventImpl } from '@fullcalendar/core/internal'
import { Box, Typography } from '@mui/material'
import { Button, Modal, Radio, TimePicker } from 'antd'
import days from 'dayjs'
import dayjs from 'dayjs'
import { useFormik } from 'formik'
import { getEmployeeFullName } from 'lib/getEmployeeFullName'
import React from 'react'

type Props = {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  startDate: days.Dayjs
  setAppointment: (values: {
    startStr: days.Dayjs
    endStr: days.Dayjs
    comment: string
    assignedTo: string
    patient: string
    type: EventType
  }) => void
  handleCancel: () => void
  editValues?: EcoEventCreateProps
  selectedEvent?: EventImpl
}

const format = 'HH:mm'

export const FormikModal: React.FC<Props> = (props) => {
  const {
    open,
    setOpen,
    startDate,
    setAppointment,
    handleCancel,
    editValues,
    selectedEvent,
  } = props
  const { data: employeesData } = useGetEmployeesQuery()
  const { data: patientsData } = useGetPatientsQuery()
  const passedEvent = editValues?.endStr.isBefore(dayjs(), 'date')

  const [session] = useSession()
  const [deleteEvent] = useDeleteEventMutation()
  const { userType, userId } = session

  const [initialValues, setInitialValues] = React.useState({
    startStr: days(startDate, format),
    endStr: days(startDate, format).add(30, 'minutes'),
    type: EventType.appointment,
    comment: '',
    assignedTo: '',
    patient: '',
  })
  const formik = useFormik({
    initialValues,
    onSubmit: setAppointment,
    validationSchema: appointmentTimeValidationSchema,
  })

  React.useMemo(() => {
    if (editValues) {
      formik.setFieldValue('comment', editValues.comment)
      formik.setFieldValue('patient', (editValues.patient as any)._id)
      formik.setFieldValue('assignedTo', (editValues.assignedTo as any)._id)
      formik.setFieldValue('type', editValues.type)
      formik.setFieldValue('startStr', days(editValues.startStr, format))
      formik.setFieldValue('endStr', days(editValues.endStr, format))
    }
  }, [editValues])

  const handleEventDelete = () => {
    if (selectedEvent?.extendedProps.createdBy !== userId) {
      return
    }
    deleteEvent(selectedEvent?.extendedProps._id)
    setOpen(false)
  }

  const patient: any = editValues?.patient

  return (
    <Modal
      width={700}
      centered
      onCancel={handleCancel}
      title={'ЗАПИСАТЬСЯ'}
      open={open}
      footer={[
        <Button
          onClick={handleCancel}
          form={'appointmentForm'}
          style={{
            backgroundColor: '#CA8A04',
            color: '#fff',
            border: 'none',
          }}
          htmlType={'reset'}
        >
          отменить
        </Button>,
        <>
          {editValues ? (
            <Button
              disabled={selectedEvent?.extendedProps.createdBy !== userId}
              onClick={handleEventDelete}
              form={'appointmentForm'}
              style={{
                backgroundColor: '#E74A56',
                color: '#fff',
                border: 'none',
              }}
              htmlType={'button'}
            >
              Удалить
            </Button>
          ) : null}
        </>,
        <>
          {!passedEvent && (
            <Button
              form={'appointmentForm'}
              style={{
                backgroundColor: '#16A34A',
                color: '#fff',
                border: 'none',
              }}
              htmlType={'submit'}
            >
              Сохранить
            </Button>
          )}
        </>,
      ]}
    >
      <Box
        id={'appointmentForm'}
        onSubmit={formik.handleSubmit}
        component="form"
        sx={{
          margin: '28px 0',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box className="appointmentForm__container">
          <Box className="appointmentForm__container--field">
            <Typography variant={'h6'} className="label">
              Тип запись:
            </Typography>
            <Box>
              <Radio.Group
                disabled={passedEvent === true}
                onChange={formik.handleChange}
                value={formik.values.type}
                name={'type'}
              >
                <Radio value={'appointment'}>ПРИЁМ</Radio>
                <Radio value={'procedure'}>ПРОЦЕДУРУ</Radio>
                {userType === DOCTOR && (
                  <Radio value={'unavailability'}>недоступен</Radio>
                )}
              </Radio.Group>
            </Box>
          </Box>

          <Box className="appointmentForm__container--field">
            <Typography variant={'h6'} className="label">
              Врач:
            </Typography>
            <EcoSelect
              disabled={passedEvent === true}
              defaultValue={
                editValues
                  ? {
                      _id: (editValues.assignedTo as unknown as SelectResponse)
                        ._id,
                      label: `${
                        (editValues.assignedTo as unknown as User).surName
                      } ${
                        (editValues.assignedTo as unknown as User).secondName
                      } ${(editValues.assignedTo as unknown as User).firstName}
                                          `,
                      value: (
                        editValues.assignedTo as unknown as SelectResponse
                      )._id,
                    }
                  : null
              }
              size={'small'}
              error={false}
              key="assignedTo"
              freeSolo={true}
              onChange={(event, newValue) => {
                formik.setFieldValue('assignedTo', (newValue as any)?._id)
              }}
              name="assignedTo"
              options={
                employeesData
                  ? (employeesData.map((employee) => ({
                      _id: employee._id,
                      label: getEmployeeFullName(employee),
                      value: employee._id,
                    })) as SelectResponse[])
                  : []
              }
              sx={{ width: '100%' }}
              placeholder="Выберите врач"
            />
          </Box>
          <Box className="appointmentForm__container--field">
            <Typography variant={'h6'} className="label">
              пациет:
            </Typography>
            <EcoSelect
              disabled={passedEvent === true}
              defaultValue={
                editValues
                  ? {
                      _id: (editValues.patient as unknown as Patient)._id,
                      label: `${patient.lastName} ${patient.firstName} ${
                        patient.surName
                      } - ${
                        (editValues.patient as unknown as Patient).cardNumber
                      }
                                          `,
                      value: (editValues.patient as unknown as Patient)._id,
                    }
                  : null
              }
              size={'small'}
              error={false}
              key="patient"
              freeSolo={true}
              onChange={(event, newValue) => {
                formik.setFieldValue('patient', (newValue as any)?._id)
              }}
              name="patient"
              options={
                patientsData
                  ? (patientsData.map((patient: any) => ({
                      _id: patient._id,
                      label: `${patient.lastName} ${patient.firstName} ${patient.surName} - ${patient.cardNumber}`,
                      value: patient._id,
                    })) as SelectResponse[])
                  : []
              }
              sx={{ width: '100%' }}
              placeholder="Выберите пациент"
            />
          </Box>

          <Box className="appointmentForm__container--field">
            <Typography variant={'h6'} className="label">
              время с:
            </Typography>
            <TimePicker
              disabled={passedEvent === true}
              status={formik.errors.startStr && !passedEvent ? 'error' : ''}
              value={formik.values.startStr}
              onChange={(value) => {
                formik.setFieldValue('startStr', value)
              }}
              name={'startStr'}
              placeholder={'Время с'}
              format={format}
              size={'large'}
              style={{ width: '100%' }}
            />
          </Box>
          <Box className="appointmentForm__container--field">
            <Typography variant={'h6'} className="label">
              время до:
            </Typography>
            <TimePicker
              disabled={passedEvent === true}
              status={formik.errors.endStr && !passedEvent ? 'error' : ''}
              value={formik.values.endStr}
              onChange={(value) => {
                formik.setFieldValue('endStr', value)
              }}
              name={'endStr'}
              placeholder={'Время до'}
              format={format}
              size={'large'}
              style={{ width: '100%' }}
            />
          </Box>

          <Box className="appointmentForm__container--field">
            <Typography variant={'h6'} className="label">
              заметка?:
            </Typography>
            <textarea
              disabled={passedEvent === true}
              onChange={formik.handleChange}
              name={'comment'}
              value={formik.values.comment}
              placeholder={'Добавить заметку'}
              style={{ width: '100%', padding: '12px 8px' }}
            />
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}
