import { useGetCurrentUserQuery } from '../../redux/services'
import { useGetCurrentUserNotificationsQuery } from '../../redux/services/notification'
import { NotificationsMenu, ProfileMenu } from '../menu'
import notificationIcon from './../../svgs/notificationIcon.svg'
import './profile.styles.scss'
import { Avatar, Badge, Box, Typography } from '@mui/material'
import React from 'react'

type Props = {
  userId: string
}

type userType = 'superAdmin' | 'medicalPersonnel' | 'doctor'

export const UserProfile: React.FC<Props> = (props) => {
  const { userId } = props
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [notificationAnchorEl, setNotificationAAnchorEl] =
    React.useState<null | HTMLElement>(null)
  const { data, error, isLoading } = useGetCurrentUserQuery(userId)
  const { data: notificationsData } = useGetCurrentUserNotificationsQuery()

  if (!data) {
    return null
  }

  const { firstName, secondName, profilePhoto, systemRole } = data
  const open = Boolean(anchorEl)
  const openNotificationMenu = Boolean(notificationAnchorEl)

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
    event.stopPropagation()
  }

  const viewNotifications = (event: React.MouseEvent<HTMLDivElement>) => {
    setNotificationAAnchorEl(event.currentTarget)
    event.stopPropagation()
  }

  const notificationDot = (
    <span
      style={{
        background: '#F9837C',
        height: '7px',
        width: '7px',
        borderRadius: '50%',
        transform: 'translate(-5px, 4px)',
        boxSizing: 'content-box',
        border: '2px solid #fff',
      }}
    />
  )

  return (
    <Box className="profile">
      {/*<Box
                onClick={viewNotifications}
                className="profile_notification"
                sx={{
                    marginRight: '32px',
                    '&:hover': {
                        cursor: 'pointer',
                    },
                }}
            >
                <Badge
                    badgeContent={
                        notificationsData && notificationsData.length > 0
                            ? notificationDot
                            : null
                    }
                >
                    <img src={notificationIcon} alt="" />
                </Badge>
            </Box>*/}

      <Box className="profile__information">
        <Box className="profile__information--photo">
          <Avatar
            className="avatar"
            alt="Travis Howard"
            src={`${process.env.REACT_APP_MEDIA_URL}/${profilePhoto}`}
          />
        </Box>
        <Box onClick={handleClick} className="profile__information--basic-info">
          <Typography
            sx={{ fontWeight: '500', fontSize: '14px' }}
            className="name"
          >
            {firstName} {secondName}
          </Typography>
          <Typography className="position">{systemRole?.label}</Typography>
        </Box>
        <NotificationsMenu
          notificationsData={notificationsData}
          open={openNotificationMenu}
          anchorEl={notificationAnchorEl}
          setAnchorEl={setNotificationAAnchorEl}
        />
        <ProfileMenu
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          open={open}
        />
      </Box>
    </Box>
  )
}
