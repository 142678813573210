import {
  paymentTypeOptions,
  terrainOptions,
  identityDocumentOptions,
} from './common'
import { IDocument } from 'features/documents'
import { createDocumentContext } from 'features/documents/lib/context'
import { createOptions } from 'lib/createOptions'
import * as yup from 'yup'

const doc: IDocument = {
  title: 'Сведения о пациенте',
  steps: [
    {
      fields: [
        {
          __type: 'TextField',
          name: 'lastName',
          label: 'Фамилия',
          placeholder: 'Введите фамилию',
        },
        {
          __type: 'TextField',
          name: 'firstName',
          label: 'Имя',
          placeholder: 'Введите имя',
        },
        {
          __type: 'TextField',
          name: 'surName',
          label: 'Отчество',
          placeholder: 'Введите отчество',
        },
        {
          __type: 'DateField',
          name: 'dateOfBirth',
          label: 'Дата рождения',
        },
        {
          __type: 'CountrySelectField',
          name: 'citizenship',
          placeholder: 'Гражданство',
        },
        {
          __type: 'SelectField',
          name: 'identityDocument.name',
          placeholder: 'Документ, удостоверяющий личность',
          options: identityDocumentOptions,
        },
        {
          __type: 'TextField',
          name: 'identityDocument.serialNumber',
          label: 'Серия',
        },
        {
          __type: 'TextField',
          name: 'identityDocument.number',
          label: 'Номер',
        },
        {
          __type: 'TextField',
          name: 'identityDocument.issuer',
          label: 'Кем выдан',
        },
        {
          __type: 'DateField',
          name: 'identityDocument.dateOfIssue',
          label: 'Дата выдачи',
        },
        {
          __type: 'TextField',
          name: 'identityDocument.issuerCode',
          label: 'Код подразделения',
        },
        {
          __type: 'PhoneField',
          name: 'phone',
          label: 'Телефон',
        },
        {
          __type: 'TextField',
          name: 'email',
          label: 'Адрес электронной почты',
        },
        {
          __type: 'GroupField',
          name: 'location',
          title: 'Регистрация по месту жительства, субъект РФ',
          fields: [
            {
              __type: 'CitySelectField',
              placeholder: 'Город',
              name: 'location.city',
            },
            {
              __type: 'TextField',
              name: 'location.district',
              label: 'Район',
            },
            {
              __type: 'TextField',
              name: 'location.locality',
              label: 'Населенный пункт',
            },
            {
              __type: 'TextField',
              name: 'location.street',
              label: 'Улица',
            },
            {
              __type: 'TextField',
              name: 'location.housing',
              label: 'Дом',
            },
            {
              __type: 'TextField',
              name: 'location.building',
              label: 'Строение/Корпус',
            },
            {
              __type: 'TextField',
              name: 'location.apartment',
              label: 'Квартира',
            },
          ],
        },
        {
          __type: 'CheckboxField',
          name: 'residesAtSamePlace',
          label: 'Проживает по месту регистрации',
        },
        {
          __type: 'ConditionalGroupField',
          name: 'stayLocation',
          title: 'Регистрация по месту пребывания, субъект РФ',
          when: 'residesAtSamePlace',
          isFalsy: true,
          fields: [
            {
              __type: 'CitySelectField',
              placeholder: 'Город',
              name: 'stayLocation.city',
            },
            {
              __type: 'TextField',
              name: 'stayLocation.district',
              label: 'Район',
            },
            {
              __type: 'TextField',
              name: 'stayLocation.locality',
              label: 'Населенный пункт',
            },
            {
              __type: 'TextField',
              name: 'stayLocation.street',
              label: 'Улица',
            },
            {
              __type: 'TextField',
              name: 'stayLocation.housing',
              label: 'Дом',
            },
            {
              __type: 'TextField',
              name: 'stayLocation.building',
              label: 'Строение/Корпус',
            },
            {
              __type: 'TextField',
              name: 'stayLocation.apartment',
              label: 'Квартира',
            },
          ],
        },
        {
          __type: 'SelectField',
          name: 'terrain',
          placeholder: 'Местность',
          options: terrainOptions,
        },
        {
          __type: 'SelectField',
          name: 'familyStatus',
          placeholder: 'Семейное положение...',
          options: createOptions([
            'Замужем/Женатый',
            'Не женат/не замужем',
            'Разведен/Разведена',
            'Вдовец/Вдова',
          ]),
        },
        {
          __type: 'SelectField',
          name: 'education',
          placeholder: 'Общее образование...',
          options: createOptions([
            'Дошкольное',
            'Начальное общее',
            'Основное общее',
            'Среднее общее',
            'Среднее профессиональное',
            'бакалавриат',
            'специалитет, магистратура',
            'высшей квалификации',
          ]),
        },
        {
          __type: 'TextField',
          name: 'employment',
          label: 'Занятость',
        },
        {
          __type: 'TextField',
          name: 'placeOfWork',
          label: 'Место работы/учёбы',
        },
        {
          __type: 'TextField',
          name: 'position',
          label: 'Должность',
        },
        {
          __type: 'TextField',
          name: 'disabilityGroup',
          label: 'Группа инвалидности',
        },
        {
          __type: 'SelectField',
          name: 'irradiated',
          placeholder: 'Лицо, подвергавшееся радиационному облучению...',
          options: createOptions(['да', 'нет']),
        },
        {
          __type: 'TextField',
          name: 'healthInsurance.number',
          label: 'Полис обязательного медицинского страхования',
        },
        {
          __type: 'DateField',
          name: 'healthInsurance.date',
          label: 'Дата выдачи полиса обязательного медицинского страхования',
        },
        {
          __type: 'TextField',
          name: 'healthInsurance.organization',
          label:
            'Данные о страховой медицинской организации, выбранной застрахованным лицом или определённой застрахованному лицу',
        },
        {
          __type: 'SnilsField',
          name: 'snilsNumber',
          label: 'СНИЛС',
        },
        {
          __type: 'SelectField',
          name: 'paymentType',
          placeholder: 'Основной вид оплаты',
          options: paymentTypeOptions,
        },
        {
          __type: 'TextField',
          name: 'medicalDocFamiliarization',
          label: 'Сведения об ознакомлении с медицинской дкоументацией',
        },
      ],
      rules: [
        {
          __type: 'ConditionalRule',
          when: 'residesAtSamePlace',
          is: true,
          then: {
            field: 'stayLocation',
            as: 'location',
          },
        },
        {
          __type: 'ConditionalRule',
          when: 'representative.residesAtSamePlace',
          is: true,
          then: {
            field: 'representative.stayLocation',
            as: 'representative.location',
          },
        },
      ],
    },
  ],
  data: {
    residesAtSamePlace: true,
  },
}

export default doc
