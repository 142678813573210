import { ISnilsField } from '../../types'
import { CustomSnilsField } from 'components'
import get from 'lodash/get'
import React, { FC } from 'react'
import { useFormContext, Controller } from 'react-hook-form'

export const SnilsField: FC<ISnilsField> = (props) => {
  const { control, formState } = useFormContext()

  const error = get(formState.errors, props.name)

  return (
    <Controller
      control={control}
      name={props.name}
      render={({ field }) => (
        <CustomSnilsField
          /*onBlur={formik.handleBlur}
														helperText={
																formik.touched.surName
																		? formik.errors.surName
																		: undefined
														}
														error={
																!!formik.touched.surName &&
																!!formik.errors.surName
														}
														onChange={formik.handleChange}*/
          name={field.name}
          onChange={field.onChange}
          onBlur={field.onBlur}
          value={field.value}
          error={Boolean(error)}
          helperText={error?.message as string}
          label={props.label!}
          placeholder={props.placeholder!}
          //value={formik.values.surName}
        />
      )}
    />
  )
}
