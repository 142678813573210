import { loginSchema } from '../../validationSchemas'
import { LoginTextField } from '../ui'
import { Box, Button, FormLabel, Typography } from '@mui/material'
import { Formik, FormikHelpers, FormikValues } from 'formik'
import React from 'react'
import { Link } from 'react-router-dom'

type Props = {
  handleLogin: (values: { email: string; password: string }) => Promise<void>
  setForgotPassword: React.Dispatch<React.SetStateAction<boolean>>
}

const initialValues = {
  email: '',
  password: '',
}

export const LoginFormik: React.FC<Props> = (props) => {
  const { handleLogin, setForgotPassword } = props

  const [checked, setChecked] = React.useState(false)

  const handleRadioChange = () => {
    setChecked(!checked)
  }

  const handleForgot = (event: React.MouseEvent) => {
    setForgotPassword(true)
  }

  return (
    <Formik
      enableReinitialize
      onSubmit={handleLogin}
      initialValues={initialValues}
      validationSchema={loginSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
      }) => (
        <>
          <Typography
            sx={{ fontWeight: 700, fontSize: '30px' }}
            className="form__header"
            variant="h4"
          >
            Войти в ваш аккаунт
          </Typography>
          <form onSubmit={handleSubmit} className="form__login-form">
            <Box>
              <FormLabel
                className="form__label"
                form="form__label"
                htmlFor="email"
              >
                Логин
              </FormLabel>
              <LoginTextField
                onChange={handleChange}
                error={!!touched.email && !!errors.email}
                value={values.email}
                onBlur={handleBlur}
                id="email"
                name="email"
                type="email"
                helperText={touched.email ? errors.email : undefined}
              />
            </Box>
            <Box sx={{ marginTop: '18px' }}>
              <FormLabel
                className="form__label"
                form="form__label"
                htmlFor="password"
              >
                Пароль
              </FormLabel>
              <LoginTextField
                onChange={handleChange}
                error={!!touched.password && !!errors.password}
                value={values.password}
                onBlur={handleBlur}
                id="password"
                name="password"
                type="password"
                helperText={touched.password ? errors.password : undefined}
              />
            </Box>
            <Box
              sx={{
                marginTop: '18px',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Box className="login-actions" sx={{ visibility: 'hidden' }}>
                <input
                  onClick={handleRadioChange}
                  type="radio"
                  id="remember"
                  name="remember"
                  checked={checked}
                />
                <label className="remember" htmlFor="remember">
                  Запомнить
                </label>
              </Box>
              <Box className="password-restore__link">
                <Link
                  onClick={handleForgot}
                  style={{ color: '#2C5282' }}
                  to="#"
                >
                  Забыл пароль?
                </Link>
              </Box>
            </Box>
            <Box
              sx={{
                marginTop: '28px',
              }}
            >
              <Button
                type="submit"
                sx={{
                  background: '#04C45C',
                  padding: '8px',
                  '&:hover': {
                    background: '#04C45C',
                  },
                }}
                fullWidth
                variant="contained"
              >
                Войти
              </Button>
            </Box>
          </form>
        </>
      )}
    </Formik>
  )
}
