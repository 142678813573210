import { BackButton, CustomPrimaryButton, EcoSelect } from '../../components'
import '../appointments/appointment.styles.scss'
import { Box, Typography } from '@mui/material'
import get from 'lodash/get'
import React from 'react'
import { useForm, Controller, SubmitHandler } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { SelectResponse } from 'redux/types'

const templates = [
  {
    label: 'Шаблон ВАР',
    value: 'vzAmbReab.xlsx',
  },
  {
    label: 'Шаблон ВРДС',
    value: 'vzDsReab.xlsx',
  },
  {
    label: 'Шаблон ДРДС',
    value: 'childrenDsReab.xlsx',
  },
  {
    label: 'Шаблон ДАР',
    value: 'childrenAmbReab.xlsx',
  },
  {
    label: 'Шаблон ТДС',
    value: 'vzDsTherapy.xlsx',
  },
  {
    label: 'Шаблон АРТР',
    value: 'vzTrAmbReab.xlsx',
  },
  {
    label: 'Шаблон ДСТР',
    value: 'vzTrDsReab.xlsx',
  },
  {
    label: 'Отказ от медицинского вмешательства',
    value: 'refusalOfMedicalIntervention.docx',
  },
]

export const DocumentationTemplateSelect: React.FC = () => {
  const navigate = useNavigate()
  const { patientId } = useParams()

  const { formState, control, handleSubmit, setValue } = useForm()

  const onSubmit: SubmitHandler<any> = (data) => {
    navigate(`/documents/new/${data.template}?patientId=${patientId}`)
  }

  const hasError = Boolean(Object.keys(formState.errors))

  return (
    <Box className="content">
      <BackButton />
      <Box className="appointment">
        <Box className="appointment__container">
          <Box
            className="appointment__container--content"
            sx={{
              maxHeight: hasError ? '296px' : '264px',
            }}
          >
            <Typography variant="h6">
              Формирование медицинской документации
            </Typography>
            <Box
              onSubmit={handleSubmit(onSubmit)}
              component="form"
              className="card"
              sx={{
                height: hasError ? '248px' : '216px',
              }}
            >
              <Box className="card__header">
                <Typography variant="h6">Выберите шаблон</Typography>
              </Box>
              <Box>
                <Controller
                  control={control}
                  name={'template'}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <EcoSelect
                      name={field.name}
                      value={field.value}
                      placeholder="Выберите шаблон"
                      options={templates as SelectResponse[]}
                      onChange={(e, newValue) =>
                        setValue(field.name, newValue?.value, {
                          shouldValidate: true,
                        })
                      }
                      // helperText={
                      //   get(formState.errors, field.name)?.message as string
                      // }
                      error={Boolean(get(formState.errors, field.name))}
                      helperText={
                        get(formState.errors, field.name)?.message as string
                      }
                    />
                  )}
                ></Controller>
              </Box>

              <Box>
                <CustomPrimaryButton type={'submit'}>
                  Выбрать
                </CustomPrimaryButton>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
