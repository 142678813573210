import { IDoctorSelectField, FieldProps } from '../../types'
import { SelectField } from '../SelectField'
import { getEmployeeFullName } from 'lib/getEmployeeFullName'
import React, { FC } from 'react'
import { useGetEmployeesQuery } from 'redux/services'

export const DoctorSelectField: FC<FieldProps<IDoctorSelectField>> = (
  props
) => {
  const getEmployeesQuery = useGetEmployeesQuery()
  const employeesData = getEmployeesQuery.data

  const options = employeesData
    ? employeesData.map(({ _id, surName, secondName, firstName }) => {
        const fio = getEmployeeFullName({ surName, secondName, firstName })

        return {
          _id,
          label: fio,
          value: { _id, fio },
        }
      })
    : []

  return (
    <SelectField
      {...props}
      name={props.name}
      renderValue={(value) => ({ label: value.fio, value: value })}
      options={options}
    />
  )
}
