import { SelectResponse } from '../types'
import { baseApi } from './api'

export const departmentsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getDepartments: build.query<SelectResponse[], void>({
      query: () => ({ url: 'departments' }),
    }),
  }),
})

export const { useGetDepartmentsQuery } = departmentsApi
