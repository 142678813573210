import { baseApi } from './api'

export const countriesApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    searchCountries: build.query<{ name: string; code: string }[], string>({
      query: (searchText) => ({
        url: `countries?searchText=${searchText}`,
      }),
    }),
  }),
})

export const { useSearchCountriesQuery } = countriesApi
