import { UserProfile } from '../../profile'
import { SearchField } from '../../search'
import './appbar.styles.scss'
import { AppBar, Box } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

export const Appbar: React.FC = () => {
  const userId = localStorage.getItem('userId')

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        sx={{
          background: '#fff',
          height: '71px',
          borderBottom: '1px solid #DCDCDC',
          boxShadow: 'none',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        position="static"
      >
        <Box className="container">
          <Box className="appbar">
            <Box className="appbar__logo">
              <Link className="appbar__logo--link" to="/">
                Экомед
              </Link>
            </Box>
            {/*<Box>
                            <SearchField />
                        </Box>*/}
            <Box>{userId && <UserProfile userId={userId} />}</Box>
          </Box>
        </Box>
      </AppBar>
    </Box>
  )
}
