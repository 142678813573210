import { ICheckboxField } from '../../types'
import { Checkbox, FormControlLabel } from '@mui/material'
import get from 'lodash/get'
import React, { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

export const CheckboxField: FC<ICheckboxField> = (props) => {
  const { control, formState } = useFormContext()

  const error = get(formState.errors, props.name)

  return (
    <Controller
      control={control}
      name={props.name}
      render={({ field }) => (
        <FormControlLabel
          control={<Checkbox checked={field.value} />}
          label={props.label}
          onChange={field.onChange}
        />
      )}
    />
  )
}
