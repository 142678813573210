import { ContextValue } from '../types'
import get from 'lodash/get'

const isContextValue = (value: any): value is ContextValue<any> => {
  return value?.__type === 'ContextValue'
}

const getDocumentContext = <T extends string[]>(
  ctxValue: ContextValue<T>,
  ctx: any
): any => {
  const values: any = []

  for (const path of ctxValue.paths) {
    values.push(get(ctx, path))
  }

  if (!ctxValue.reducer) {
    return values[0]
  }

  return ctxValue.reducer(values)
}

export const setDocumentContext = (data: any, ctx: any) => {
  if (data && typeof data === 'object') {
    for (const key in data) {
      const prop = data[key]

      if (isContextValue(prop)) {
        // @ts-ignore
        data[key] = getDocumentContext(prop, ctx)
      } else if (Array.isArray(prop)) {
        for (let i = 0; i < prop.length; i++) {
          const item = prop[i]

          if (isContextValue(item)) {
            prop[i] = getDocumentContext(item, ctx)
          } else {
            setDocumentContext(item, ctx)
          }
        }
      } else {
        setDocumentContext(prop, ctx)
      }
    }
  }
}

export const createDocumentContext = <T extends string[]>(
  options: Omit<ContextValue<T>, '__type'>
): ContextValue<T> => {
  return {
    __type: 'ContextValue',
    ...options,
  }
}
