import './back.styles.scss'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import { Box, Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'

export const BackButton = () => {
  const navigate = useNavigate()
  const handleClick = () => {
    navigate(-1)
  }
  return (
    <Box className="button-back">
      <Button
        onClick={handleClick}
        sx={{
          color: '#1E71EA',
          textTransform: 'capitalize',
          fontWeight: '500',
          fontSize: '14px',
          letterSpacing: '0.01em',
        }}
        startIcon={
          <ArrowBackIosIcon
            sx={{
              fontSize: '16px !important',
              color: '#1E71EA',
              fontWeight: '700',
            }}
          />
        }
      >
        Назад
      </Button>
    </Box>
  )
}
