import { IDateField } from '../../types'
import { CustomTextField } from 'components'
import { DateObject } from 'lib/dateObject'
import get from 'lodash/get'
import React, { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

export const DateField: FC<IDateField> = ({ name, label }) => {
  const { control, formState, setValue } = useFormContext()

  const error = get(formState.errors, name)

  const handleChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const [year, month, day] = event.currentTarget.value.split('-')

    if (!year || !month || !day) return

    const date: DateObject = {
      __type: 'DateObject',
      year,
      month,
      day,
    }

    setValue(name, date, { shouldValidate: true })
  }

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <CustomTextField
          /*onChange={formik.handleChange}
											onBlur={formik.handleBlur}*/
          type="date"
          //name="dob"
          label={label!}
          placeholder=""
          /*error={
													!!formik.touched.dob && !!formik.errors.dob
											}
											helperText={
													formik.touched.dob
															? formik.errors.dob
															: undefined
											}
											value={formik.values.dob}*/
          /*{...register('dateOfBirth')}*/
          name={field.name}
          onChange={handleChange}
          onBlur={field.onBlur}
          defaultValue={
            field.value &&
            `${field.value?.year}-${field.value?.month}-${field.value?.day}`
          }
          error={Boolean(error)}
          helperText={error?.message as string}
        />
      )}
    />
  )
}
