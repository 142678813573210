import { EcoEvent } from '../types'
import { baseApi } from './api'
import { TagDescription } from '@reduxjs/toolkit/query'

const apiWithEvents = baseApi.enhanceEndpoints({
  addTagTypes: ['Event'],
})

export const eventsApi = apiWithEvents.injectEndpoints({
  endpoints: (build) => ({
    getEvents: build.query<EcoEvent[], { assignedTo: string; type: string }>({
      query: ({ assignedTo, type }) => ({
        url: `events?assignedTo=${assignedTo}&type=${type}`,
      }),
      providesTags: (result) => {
        const tags: TagDescription<any> = [{ type: 'Event', id: 'LIST' }]

        if (result) {
          tags.push(
            ...result.map((event) => ({
              type: 'Event',
              id: event._id!,
            }))
          )
        }

        return tags
      },
    }),

    createEvent: build.mutation<EcoEvent, EcoEvent>({
      query: (data) => ({ url: 'events', data, method: 'POST' }),
      invalidatesTags: (result, error, arg, meta) => [
        { type: 'Event', id: 'LIST' },
      ],
    }),

    editEvent: build.mutation<
      EcoEvent,
      Partial<EcoEvent> & { eventId: string }
    >({
      query: (data) => {
        const { eventId, ...body } = data
        return {
          url: `events/${eventId}`,
          data: body,
          method: 'PUT',
        }
      },
      invalidatesTags: (result, error, arg, meta) => [
        { type: 'Event', id: arg.eventId },
      ],
    }),

    deleteEvent: build.mutation<void, string>({
      query: (eventId) => {
        return {
          url: `events/${eventId}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: (result, error, eventId, meta) => [
        { type: 'Event', id: eventId },
      ],
    }),
  }),
})

export const {
  useGetEventsQuery,
  useCreateEventMutation,
  useDeleteEventMutation,
  useEditEventMutation,
} = eventsApi
