import { IConditionalGroupField, IFormField } from '../../types'
import styles from './styles.module.scss'
import { Box, Typography } from '@mui/material'
import React, { FC, ReactNode } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

type GroupFieldProps = IConditionalGroupField & {
  renderField: <T extends IFormField>(field: T) => ReactNode
}

export const ConditionalGroupField: FC<GroupFieldProps> = ({
  title,
  renderField,
  fields,
  ...props
}) => {
  const { control } = useFormContext()

  const value = useWatch({ control, name: props.when })

  // @ts-ignore
  if (props.isFalsy) {
    if (value) return null
  } else {
    // @ts-ignore
    if (value !== props.is) {
      return null
    }
  }

  return (
    <>
      <Box className={styles['form-field']}>
        <Typography variant="h6">{title}</Typography>
      </Box>
      <Box className={styles['form-field']}>
        {fields.map((field) => (
          <Box className={styles['form-field']}>{renderField(field)}</Box>
        ))}
      </Box>
    </>
  )
}
