import { api2 } from '../../api'
import { useSession } from '../../context/session.context'
import { loginSchema } from '../../validationSchemas'
import { LoginTextField } from '../ui'
import { LoginFormik } from './loginFormik'
import { PasswordRestoreFormik } from './passwordRestoreFormik'
import {
  Box,
  Typography,
  FormLabel,
  Button,
  Snackbar,
  Alert,
  AlertColor,
} from '@mui/material'
import { Formik } from 'formik'
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

type AuthResponseType = {
  token: string
  userType: string
  userId: string
}

export const LoginForm = () => {
  const [open, setOpen] = React.useState(false)
  const [alertMessage, setAlertMessage] = React.useState('')
  const [forgotPassword, setForgotPassword] = React.useState(false)
  const [session, setSession] = useSession()
  const navigate = useNavigate()
  const [severity, setSeverity] = React.useState<AlertColor | undefined>(
    'error'
  )

  const { isAuthenticated } = session

  React.useEffect(() => {
    if (isAuthenticated) {
      navigate('/')
    }
  }, [])

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  const handleLogin = async (values: { email: string; password: string }) => {
    try {
      const { data } = await api2.post<AuthResponseType>('/auth/login', values)
      const { token, userId, userType } = data
      const session = {
        isAuthenticated: true,
        token: token,
        userType: userType,
        userId: userId,
      }

      setSession(session)
      navigate('/')
    } catch (e: any) {
      const { data } = e.response
      const { message } = data
      setSeverity('error')
      setAlertMessage(message)
      setOpen(true)
    }
  }

  const handlePasswordReset = async (values: {
    passwordRestoreEmail: string
  }) => {
    try {
      const { data } = await api2.post('/auth/restorePassword', values)
      setSeverity('info')
      setOpen(true)
      setAlertMessage(data.message)
    } catch (e: any) {
      const { data } = e.response
      const { message } = data
      setSeverity('error')
      setAlertMessage(message)
      setOpen(true)
    }
  }

  return (
    <Box className="form">
      <Snackbar
        sx={{ opacity: '0' }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      />
      <Alert
        severity={severity}
        sx={{
          left: '0',
          right: '0',
          marginBottom: '20px',
          visibility: open ? 'visible' : 'hidden',
        }}
      >
        <strong>{severity === 'error' ? 'Ошибка' : 'Успех'}</strong> -{' '}
        {alertMessage}
      </Alert>

      {forgotPassword ? (
        <PasswordRestoreFormik
          setForgotPassword={setForgotPassword}
          handlePasswordReset={handlePasswordReset}
        />
      ) : (
        <LoginFormik
          setForgotPassword={setForgotPassword}
          handleLogin={handleLogin}
        />
      )}
    </Box>
  )
}
