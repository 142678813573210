import './patient.styles.scss'
import { Box, Typography } from '@mui/material'
import { BackButton } from 'components'
import { AppointmentsPatientWidget } from 'features/appoinments'
import React from 'react'
import { useParams } from 'react-router-dom'
import { useGetPatientQuery } from 'redux/services'

export const AppointmentsPatientPage: React.FC = () => {
  const { patientId } = useParams()

  const patientQuery = useGetPatientQuery(patientId as string)

  const patient = patientQuery.data

  return (
    <Box className="content">
      <BackButton />
      <Box className="patient">
        <Box className="patient__container">
          <Box className="patient__container--title">
            <Typography variant="h6">Запись на прием</Typography>
          </Box>
          {patient && <AppointmentsPatientWidget patient={patient} />}
        </Box>
      </Box>
    </Box>
  )
}
