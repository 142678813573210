import { styled, TextField } from '@mui/material'
import React from 'react'
import { IMaskInput } from 'react-imask'

type Props = {
    placeholder: string
    type?: string
    name: string
    error: boolean
    value?: string
    onBlur?: (event: React.FocusEvent<HTMLElement>) => void
    onChange?: (event: React.ChangeEvent<HTMLElement>) => void
    helperText?: string
}

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void
    name: string
    value: string
}

const StyledCustomTextField = styled(TextField)({
    color: '#7A828A !important',
    fontSize: '12px',
    fontWeight: '500',
    letterSpacing: '-0.005em',
    border: 'none',
    height: '100%',
    borderRadius: '6px',

    backgroundColor: '#E6EDF2',
    '& > .MuiTextField-root': {
        padding: '18px, 16px !important',
    },
})

export const SearchField: React.FC<Props> = (props) => {
    const {
        placeholder,
        type = 'text',
        value,
        error,
        name,
        onChange: handleChange,
        helperText,
    } = props

    return (
        <StyledCustomTextField
            sx={{
                height: '48px',
                border: error ? '1px solid #E91414' : '1px solid transparent',
                '& .Mui-focused': {
                    border: 'none',
                },
                '& > div': {
                    height: '100%',
                },
                '& fieldset': {
                    border: 'none',
                    '& .Mui-focused': {
                        border: 'none',
                    },
                },
            }}
            onChange={handleChange}
            name={name}
            error={true}
            value={value}
            type={type}
            size="small"
            fullWidth
            placeholder={placeholder}
            helperText={helperText}
        />
    )
}
