import { styled, TextField } from '@mui/material'
import React from 'react'
import { IMaskInput } from 'react-imask'

type Props = {
    placeholder: string
    type?: string
    name: string
    error: boolean
    value?: string
    onBlur?: (event: React.FocusEvent<HTMLElement>) => void
    onChange?: (event: React.ChangeEvent<HTMLElement>) => void
}

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void
    name: string
    value: string
}

const StyledCustomTextField = styled(TextField)({
    color: '#7A828A !important',
    fontSize: '12px',
    fontWeight: '500',
    letterSpacing: '-0.005em',
    border: 'none',
    height: '100%',
    borderRadius: '6px',

    backgroundColor: '#E6EDF2',
    '& > .MuiTextField-root': {
        borderColor: 'red !important',
        padding: '18px, 16px !important',
    },
})

const TextMaskCustom = React.forwardRef<HTMLElement, CustomProps>(
    function TextMaskCustom(props, ref) {
        const { onChange, name, ...other } = props

        return (
            <IMaskInput
                name={name}
                {...other}
                mask="#### ######"
                definitions={{
                    // @ts-ignore
                    '#': /[0-9]/,
                }}
                // @ts-ignore
                inputRef={ref}
                onAccept={(value: any) => {
                    return onChange({ target: { name: props.name, value } })
                }}
                overwrite
            />
        )
    }
)

export const CustomMaskedField: React.FC<Props> = (props) => {
    const {
        placeholder,
        type = 'text',
        value,
        error,
        name,
        onChange: handleChange,
    } = props
    return (
        <StyledCustomTextField
            sx={{
                height: '48px',
                '& .Mui-focused': {
                    border: 'none',
                },
                '& > div': {
                    height: '100%',
                },
                '& fieldset': { border: 'none' },
            }}
            onChange={handleChange}
            name={name}
            error={error}
            value={value}
            type={type}
            size="small"
            fullWidth
            placeholder={placeholder}
            InputProps={{
                inputComponent: TextMaskCustom as any,
            }}
        />
    )
}
