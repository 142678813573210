import { IGroupField, IFormField } from '../../types'
import styles from './styles.module.scss'
import { Box, Typography } from '@mui/material'
import React, { FC, ReactNode } from 'react'

interface GroupFieldProps extends IGroupField {
  renderField: <T extends IFormField>(field: T) => ReactNode
}

export const GroupField: FC<GroupFieldProps> = ({
  title,
  renderField,
  fields,
}) => {
  return (
    <>
      <Box className={styles['form-field']}>
        <Typography variant="h6">{title}</Typography>
      </Box>
      <Box className={styles['form-field']}>
        {fields.map((field) => (
          <Box className={styles['form-field']}>{renderField(field)}</Box>
        ))}
      </Box>
    </>
  )
}
