import { FieldProps, ICountrySelectField } from '../../types'
import { EcoSelect } from 'components'
import { useDebounce } from 'hooks'
import get from 'lodash/get'
import React, { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useSearchCountriesQuery } from 'redux/services'
import { SelectResponse } from 'redux/types'

export const CountrySelectField: FC<FieldProps<ICountrySelectField>> = (
  props
) => {
  const { control, formState, setValue } = useFormContext()

  const [inputValueCity, setInputValueCity] = React.useState('')
  const debouncedCity = useDebounce(inputValueCity)

  const searchCountriesQuery = useSearchCountriesQuery(debouncedCity, {
    skip: !debouncedCity,
  })

  const countries = searchCountriesQuery.data

  const options = countries
    ? countries.map((city) => ({
        label: city.name,
        value: city,
      }))
    : []

  const error = get(formState.errors, props.name)

  return (
    <Controller
      control={control}
      name={props.name}
      render={({ field }) => (
        <EcoSelect
          name={field.name}
          placeholder={props.placeholder!}
          freeSolo={true}
          value={
            !field.value
              ? null
              : { label: field.value.name, value: field.value }
          }
          filterOptions={(x) => x}
          /*helperText={
formik.touched.city
		? formik.errors.city
		: undefined
}
onBlur={formik.handleBlur}*/
          options={options}
          /*onChange={(event, newValue) => {
formik.setFieldValue(
		'city',
		(newValue as any)?.value
)
}}
error={
!!formik.touched.city &&
!!formik.errors.city
}*/
          error={Boolean(error)}
          helperText={error?.message as string}
          onChange={(event, newValue) => {
            /*formik.setFieldValue(
'city',
(newValue as any)?.value
)*/
            setValue(field.name, newValue?.value, {
              shouldValidate: true,
            })
          }}
          onInputChange={(event, newInputValue) => {
            setInputValueCity(newInputValue)
          }}
          sx={{}}
        />
      )}
    />
  )
}
